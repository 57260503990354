import React from "react";
import { NavLink } from "react-router-dom";
import { formatDate } from "../../../utils/utils";

const TaskCard = (props) => {
  const truncateChars = (text) => {
    if (!text) {
      return null;
    }
    return text.length > 60 ? text.substring(0, 60) : text;
  };
  return (
    <div className="col-lg-4 grid-margin stretch-card">
      <div className={`card border-${props.sla_color}`}>
        <div className="card-body">
          <h4 className="card-title">
            <NavLink to={`/tasks/${props.id}/detail`}>
              {props.number} {props.name}
            </NavLink>
          </h4>
          <p className="card-description">{truncateChars(props.description)}</p>
          <hr />
          <div>
            <ul>
              <li>В работе: {props.processing}</li>
              <li>Приостановлена: {props.suspended}</li>
              <li>В статусе "Создана": {props.created}</li>
            </ul>
          </div>
          <hr />
          <small>
            Текущий статус: <b>{props.current_status_repr}</b>
            <br />
            Роль в задаче: <b>{props.role_repr}</b>
            <br />
            Завершить до: <b>{formatDate(props.finish)}</b>
            <br />
            Приоритет:{" "}
            <button
              className={`text-white btn btn-sm btn-${
                props.priority === "1"
                  ? "danger"
                  : props.priority === "2"
                  ? "warning"
                  : "success"
              }  p-1`}
              onClick={(e) => e.preventDefault()}
            >
              <b>{props.priority_repr}</b>
            </button>
            <br />
            Проект:{" "}
            {props.project_repr ? (
              <NavLink to={`/projects/${props.project}/detail`}>
                {props.project_repr}
              </NavLink>
            ) : (
              <b>Не указано</b>
            )}
          </small>
        </div>
        <div className="card-footer">
          <small>
            Инициатор:{" "}
            {props.userId === props.initiator.profile_id ? (
              <NavLink to={`/profile`}>{props.initiator.full_name}</NavLink>
            ) : (
              <NavLink to={`/employee/${props.initiator.profile_id}/profile`}>
                {props.initiator.full_name}
              </NavLink>
            )}
          </small>
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
