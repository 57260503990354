import React, { useEffect, useRef } from "react";
import Profile from "./Profile";
import { connect } from "react-redux";
import {
  setShowContactEditModalAC,
  setShowProfileEditModalAC,
} from "../../redux/actions/root/RootPageActions";
import { useForm } from "react-hook-form";
import {
  createUserContact,
  deleteUserContact,
  getUserAvatar,
  getUserDataTC,
  updateUserContact,
  updateUserData,
  updateUserPhoto,
} from "../../redux/thunk/root/RootPageThunk";
import Spinner from "../shared/Spinner";
import { formatDate } from "../../utils/utils";

const ProfileContainer = (props) => {
  const inputFile = useRef(null);
  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: {},
  });
  const {
    register: registerContact,
    control: controlContact,
    handleSubmit: handleSubmitContact,
    reset: resetContact,
  } = useForm({
    defaultValues: {},
  });
  const handleShowEditModal = (show) => {
    props.setShowProfileEditModalAC(show);
    if (show) {
      reset({
        first_name: props.userData.first_name,
        middle_name: props.userData.middle_name,
        last_name: props.userData.last_name,
        dob: props.userData.dob,
        phone: props.userData.phone,
        profile_is_visible: props.userData.profile_is_visible,
        phone_is_visible: props.userData.phone_is_visible,
        dob_is_visible: props.userData.dob_is_visible,
      });
    }
  };
  const handleShowContactModal = (show, id = null) => {
    if (id) {
      resetContact(props.userData.contacts.find((c) => c.id === Number(id)));
    } else {
      resetContact({});
    }
    props.setShowContactEditModalAC(show);
  };
  const handlePhotoSelectButton = (e) => {
    inputFile.current.click();
  };
  const handlePhotoChange = (e) => {
    if (e.target.files.length > 0) {
      props.updateUserPhoto(e.target.files[0]);
    }
  };
  const onSubmitEdit = (data) => {
    data.dob = formatDate(data.dob, "y-m-d", "-");
    props.updateUserData(data);
  };
  const onSubmitContact = (data) => {
    if (data?.id) {
      props.updateUserContact(data.id, data);
    } else {
      props.createUserContact(data);
    }
  };
  useEffect(() => {
    props.getUserDataTC();
  }, []);
  if (props.profileIsLoading) {
    return <Spinner />;
  }
  return (
    <Profile
      inputFile={inputFile}
      registerEdit={register}
      controlEdit={control}
      onSubmitEdit={onSubmitEdit}
      handleSubmitEdit={handleSubmit}
      registerContact={registerContact}
      controlContact={controlContact}
      onSubmitContact={onSubmitContact}
      handleSubmitContact={handleSubmitContact}
      handleShowEditModal={handleShowEditModal}
      handleShowContactModal={handleShowContactModal}
      handlePhotoSelectButton={handlePhotoSelectButton}
      handlePhotoChange={handlePhotoChange}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.rootPage.userData,
    showProfileEditModal: state.rootPage.showProfileEditModal,
    showContactEditModal: state.rootPage.showContactEditModal,
    profileIsLoading: state.rootPage.profileIsLoading,
    updatePhotoInProcess: state.rootPage.updatePhotoInProcess,
  };
};

export default connect(mapStateToProps, {
  setShowProfileEditModalAC,
  setShowContactEditModalAC,
  updateUserData,
  createUserContact,
  updateUserContact,
  updateUserPhoto,
  deleteUserContact,
  getUserDataTC,
  getUserAvatar,
})(ProfileContainer);
