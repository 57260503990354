export const actions = {
  SET_AUTHENTICATED: "SET_AUTHENTICATED",
  SET_ALERT_TOAST: "SET_ALERT_TOAST",
  SET_NOTIFICATION_TOAST: "SET_NOTIFICATION_TOAST",
  SET_NOTIFICATION_READ: "SET_NOTIFICATION_READ",
  SET_CURRENT_NOTIFICATION: "SET_CURRENT_NOTIFICATION",
  SET_GLOBAL_SEARCH_RESULTS: "SET_GLOBAL_SEARCH_RESULTS",
  SET_CURRENT_NOTIFICATION_IS_LOADING: "SET_CURRENT_NOTIFICATION_IS_LOADING",
  SET_READ_IN_PROCESS: "SET_READ_IN_PROCESS",
  SET_CURRENT_PATH: "SET_CURRENT_PATH",
  SET_USER_DATA: "SET_USER_DATA",
  SET_IS_FULL_PAGE_LAYOUT: "SET_IS_FULL_PAGE_LAYOUT",
  SET_USER_NOTIFICATIONS: "SET_USER_NOTIFICATIONS",
  SET_SHOW_PROFILE_EDIT_MODAL: "SET_SHOW_PROFILE_EDIT_MODAL",
  SET_SHOW_CONTACT_EDIT_MODAL: "SET_SHOW_CONTACT_EDIT_MODAL",
  SET_PROFILE_IS_LOADING: "SET_PROFILE_IS_LOADING",
  SET_GLOBAL_SEARCH_IN_PROCESS: "SET_GLOBAL_SEARCH_IN_PROCESS",
  SET_PHOTO_UPDATE_IN_PROCESS: "SET_PHOTO_UPDATE_IN_PROCESS",
  SET_LOGIN_IN_PROCESS: "SET_LOGIN_IN_PROCESS"
};

export const setAuthenticated = (authenticated) => {
  return {
    type: actions.SET_AUTHENTICATED,
    authenticated: authenticated,
  };
};
export const setAlertToast = (title, body, level, icon, add, id, url = null) => {
  return {
    type: actions.SET_ALERT_TOAST,
    id: id,
    add: add,
    title: title,
    body: body,
    level: level,
    icon: icon,
    url: url
  };
};
export const setNotificationToastAC = (
  title,
  body,
  level,
  icon,
  add,
  id,
  url = null
) => {
  debugger
  return {
    type: actions.SET_NOTIFICATION_TOAST,
    id: id,
    add: add,
    title: title,
    body: body,
    level: level,
    icon: icon,
    url: url,
  };
};
export const setNotificationRead = (id, read, all) => {
  return {
    type: actions.SET_NOTIFICATION_READ,
    id: id,
    read: read,
    all: all,
  };
};
export const setCurrentNotification = (data) => {
  return {
    type: actions.SET_CURRENT_NOTIFICATION,
    data: data,
  };
};
export const setCurrentNotificationIsLoading = (isLoading) => {
  return {
    type: actions.SET_CURRENT_NOTIFICATION_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setReadInProcess = (id, add, all) => {
  return {
    type: actions.SET_READ_IN_PROCESS,
    id: id,
    add: add,
    all: all,
  };
};
export const setCurrentPathAC = (path) => {
  return {
    type: actions.SET_CURRENT_PATH,
    path: path,
  };
};
export const setUserDataAC = (data) => {
  return {
    type: actions.SET_USER_DATA,
    data: data,
  };
};
export const setUpdatePhotoInProcess = (inProcess) => {
  return {
    type: actions.SET_PHOTO_UPDATE_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setIsFullPageLayoutAC = (isFullPage) => {
  return {
    type: actions.SET_IS_FULL_PAGE_LAYOUT,
    isFullPage: isFullPage,
  };
};
export const setUserNotificationsAC = (data) => {
  return {
    type: actions.SET_USER_NOTIFICATIONS,
    data: data,
  };
};
export const setShowProfileEditModalAC = (show) => {
  return {
    type: actions.SET_SHOW_PROFILE_EDIT_MODAL,
    show: show,
  };
};
export const setShowContactEditModalAC = (show) => {
  return {
    type: actions.SET_SHOW_CONTACT_EDIT_MODAL,
    show: show,
  };
};
export const setProfileIsLoading = (isLoading) => {
  return {
    type: actions.SET_PROFILE_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setGlobalSearchInProcess = (inProcess) => {
  return {
    type: actions.SET_GLOBAL_SEARCH_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setGlobalSearchResults = (data) => {
  return {
    type: actions.SET_GLOBAL_SEARCH_RESULTS,
    data: data,
  };
};
export const setLoginInProcess = (inProcess) => {
  return {
    type: actions.SET_LOGIN_IN_PROCESS,
    inProcess: inProcess
  }
}
