import React from "react";

const AccessDenied = () => {
  return (
    <div className="text-center">
      <div className="mt-2">
        <i className="mdi mdi-alert-outline text-primary icon-lg" />
      </div>

      <div className="mt-2">
        <span>Доступ к запршиваему ресурсу ограничен</span>
      </div>
    </div>
  );
};

export default AccessDenied;
