import React, { useEffect } from "react";
import TaskManage from "./TaskManage";
import { connect } from "react-redux";
import {
  getTaskInitialData,
  getTaskParentsTC,
  getTaskProjectsTC,
  saveTaskTC,
} from "../../../redux/thunk/tasks/manage/TaskManageThunk";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Spinner from "../../shared/Spinner";
import { setCurrentPathAC } from "../../../redux/actions/root/RootPageActions";
import Error from "../../shared/Error";
import { setInitialHasErrors } from "../../../redux/actions/tasks/manage/TaskManageActions";
import moment from 'moment-timezone';

const TaskManageContainer = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      description: "",
      start: null,
      finish: null,
      project: Number(searchParams.get("project_id")) || null,
      parent_task: Number(searchParams.get("parent_id")) || null,
      priority: null
    },
  });

  useEffect(() => {
    if (params.id) {
      props.setCurrentPathAC("tasks/manage/edit");
    } else {
      props.setCurrentPathAC("tasks/manage/create");
    }
    props.getTaskProjectsTC();
    props.getTaskParentsTC();
  }, [params.id]);

  useEffect(() => {
    props.getTaskParentsTC(watch("project"));
  }, [watch("project")]);

  useEffect(() => {
    if (props.initial) {
      const data = {...props.initial}
      data.start = moment(data.start).format("YYYY-MM-DDTkk:mm")
      data.finish = moment(data.finish).format("YYYY-MM-DDTkk:mm")
      reset(data);
    }
  }, [props.initial]);

  useEffect(() => {
    if (params.id) {
      props.getTaskInitialData(params.id);
    } else {
      reset({
        id: "",
        name: "",
        description: "",
        start: null,
        finish: null,
        project: Number(searchParams.get("project_id")) || null,
        parent_task: Number(searchParams.get("parent_id")) || null,
        priority: null
      });
      props.setInitialHasErrors(false);
    }
  }, [props.currentPath]);

  const onSubmit = (data) => {
    props
      .saveTaskTC(data, params.id)
      .then((res) => navigate(`/tasks/${res.data.id}/detail`));
  };

  if (props.isLoading) {
    return <Spinner />;
  }
  if (props.hasErrors) {
    return <Error />;
  }

  return (
    <TaskManage
      register={register}
      control={control}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      watch={watch}
      errors={errors}
      taskId={params.id}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    initial: state.tasksManagePage.initial,
    projects: state.tasksManagePage.projects,
    parents: state.tasksManagePage.parents,
    taskSaveInProcess: state.tasksManagePage.taskSaveInProcess,
    isLoading: state.tasksManagePage.initialIsLoading,
    currentPath: state.rootPage.currentPath,
    hasErrors: state.tasksManagePage.hasErrors,
    permissions: state.rootPage.userData.permissions,
  };
};

export default connect(mapStateToProps, {
  getTaskInitialData,
  getTaskProjectsTC,
  getTaskParentsTC,
  saveTaskTC,
  setCurrentPathAC,
  setInitialHasErrors,
})(TaskManageContainer);
