import React from "react";

const MessageFileComponent = (props) => {
  const truncateChars = (text) => {
    if (!text) {
      return null;
    }
    return text.length > 15
      ? `${text.substring(0, 15)}...${text.substring(
          text.length - 10,
          text.length
        )}`
      : text;
  };
  return (
    <div className="col-sm-auto">
      <button
        type="button"
        className="btn btn-sm p-0 text-primary"
        onClick={() => props.handeCommentFileDownload(props.id, props.name)}
      >
        <span className="text-wrap">{truncateChars(props.name)}</span>
      </button>
    </div>
  );
};
export default MessageFileComponent;
