import React, { useRef } from "react";
import AppRoutes from "./AppRoutes";
import Navbar from "../shared/Navbar";
import Sidebar from "../shared/Sidebar";
import AlertToast from "../shared/AlertToast";
import { ToastContainer } from "react-bootstrap";

const RootPage = (props) => {
  const windowHeightRef = useRef(window.innerHeight);
  const overflowMaxHeight = `${Number(windowHeightRef?.current) * 0.999}px`;
  const navbarComponent = !props.isFullPageLayout ? (
    <Navbar
      notifications={props.userData.notifications}
      profileIsLoading={props.profileIsLoading}
      photo={props.userData.photo}
      lastName={props.userData.last_name}
      firstName={props.userData.first_name}
      logout={props.logoutTC}
    />
  ) : (
    ""
  );
  const sidebarComponent = !props.isFullPageLayout ? (
    <Sidebar
      currentPath={props.currentPath}
      routes={props.userData.routes}
    />
  ) : (
    ""
  );
  const alerts = props.alerts.map((a, index) => (
    <AlertToast key={index} onClose={props.setAlertToastAC} {...a} />
  ));
  return (
    <div className="container-scroller">
      {navbarComponent}

      <div
        className="container-fluid page-body-wrapper p-0"
        style={{ height: `${Number(windowHeightRef?.current) * 0.999}px` }}
      >
        {sidebarComponent}
        <div className="main-panel main-panel-only ">
          <ToastContainer
            position="top-end"
            className="mt-5 py-4 px-4"
          >
            {alerts.length > 0 ? alerts : null}
          </ToastContainer>
          <div
            className="content-wrapper mb-3 px-3 overflow-auto"
            style={{ maxHeight: overflowMaxHeight }}
          >
            <AppRoutes permissions={props.userData.permissions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootPage;
