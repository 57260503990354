import React, { useRef } from "react";
import EventDetailModal from "./EventDetailModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import ruLocale from "@fullcalendar/core/locales/ru";
import Select from "react-select";

const CalendarComponent = (props) => {
  const calRef = useRef(null);
  const typesOptions = [
    { value: "meeting", label: "Совещание" },
    { value: "vacation", label: "Отпуск" },
    { value: "education", label: "Обучение" },
    { value: "business_trip", label: "Командировка" },
    { value: "task", label: "Задача" },
    { value: "sick_leave", label: "Больничный" },
    { value: "other", label: "Другое" },
  ];
  const events = props?.events?.map((e) => ({
    id: e.id,
    title: `${e.event_type_repr}, 
                ${e.name}, ${e.completed ? "Завершено" : "Активно"}`,
    start: e.start,
    end: e.finish,
    color: e.color,
  }));
  const handleNextClick = () => {
    const calApi = calRef?.current?.getApi();
    calApi.next();
    localStorage.setItem("calendar.currentDate", calApi.getDate().toString());
  };
  const handlePrevClick = () => {
    const calApi = calRef?.current?.getApi();
    calApi.prev();
    localStorage.setItem("calendar.currentDate", calApi.getDate().toString());
  };
  const handleChangeView = (view) => {
    const calApi = calRef?.current?.getApi();
    calApi.changeView(view);
    localStorage.setItem("calendar.currentView", view);
  };
  const currentDate = localStorage.getItem("calendar.currentDate")
    ? new Date(localStorage.getItem("calendar.currentDate"))
    : new Date();
  const currentView = localStorage.getItem("calendar.currentView")
    ? localStorage.getItem("calendar.currentView")
    : "dayGridMonth";
  return (
    <>
      <EventDetailModal
        show={props.eventModalShow}
        register={props?.register}
        onSubmit={props?.onSubmit}
        getValues={props?.getValues}
        control={props?.control}
        userId={props?.userId}
        modalMode={props.modalMode}
        event={props.event}
        errors={props?.errors}
        participants={props?.participants}
        modalIsLoading={props.modalIsLoading}
        eventCompleteInProcess={props.eventCompleteInProcess}
        handleSubmit={props?.handleSubmit}
        handleEventShowModal={props.handleEventShowModal}
        handleDeleteButtonClick={props.handleDeleteButtonClick}
        handleSetEventCompleted={props.handleSetEventCompleted}
      />

      <div className="mb-3">
        {!props.hideFilterField && (
          <div className="col-sm-4 mb-3">
            <Select
              value={props.eventType}
              onChange={(e) => props.handleEventTypeChange(e)}
              isMulti={true}
              placeholder="Выберите типы событий"
              isClearable={true}
              maxMenuHeight={150}
              options={typesOptions}
            />
          </div>
        )}
        <button
          className="btn btn-primary btn-sm text-white mdi mdi-skip-previous me-2 mt-2"
          onClick={handlePrevClick}
        />
        <button
          className="btn btn-primary btn-sm text-white mdi mdi-skip-next me-2 mt-2"
          onClick={handleNextClick}
        />
        {!props.hideAddButton && (
          <button
            className="btn btn-primary btn-sm text-white me-2 mt-2"
            onClick={() => props.handleEventShowModal(true)}
          >
            <i className="mdi mdi-calendar-plus me-2" />
            Новое событие
          </button>
        )}
        <button
          className="btn btn-primary btn-sm text-white me-2 mt-2"
          onClick={() => handleChangeView("dayGridMonth")}
        >
          <i className="mdi mdi-calendar-today me-2" />
          Месяц
        </button>
        <button
          className="btn btn-primary btn-sm text-white me-2 mt-2"
          onClick={() => handleChangeView("timeGridWeek")}
        >
          <i className="mdi mdi-calendar-text me-2" />
          Неделя
        </button>
        <button
          className="btn btn-primary btn-sm text-white me-2 mt-2"
          onClick={() => handleChangeView("listWeek")}
        >
          <i className="mdi mdi-format-align-justify me-2" />
          День
        </button>
      </div>

      <FullCalendar
        ref={calRef}
        initialDate={currentDate}
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin, bootstrap5Plugin]}
        initialView={currentView}
        weekends={true}
        locale={ruLocale}
        buttonText={{
          prev: "<",
          next: ">",
        }}
        customButtons={{
          addNewEventBtn: {
            text: "Новое событие",
            click: () => {
              props.handleEventShowModal(true);
            },
          },
        }}
        themeSystem="bootstrap5"
        events={events}
        headerToolbar={{ left: "title", right: "" }}
        eventClick={(info) =>
          props.handleEventShowModal(true, Number(info.event.id), "detail")
        }
      />
    </>
  );
};

export default CalendarComponent;
