import React from "react";
import CalendarComponent from "./CalendarComponent";

const CalendarPage = (props) => {
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>Календарь</b>
      </div>
      <div className="col-sm-12 ">
        <div className="card mt-2">
          <div className="card-body">
            <CalendarComponent {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalendarPage;
