import api from "../../../../utils/api/api_beta";
import {
  setTasksAC,
  setTasksIsLoadingAC,
  setWidgetUsers,
  setShowWidgetModal,
  setWidgetData,
  setWidgetIsLoading,
  setWidgetSaveInProcess
} from "../../../actions/tasks/list/TaskListActions";
import { setAlertToast } from "../../../actions/root/RootPageActions";
import { handleErrors } from "../../HandleErrors";

export const getTasksTC = () => {
  return async (dispatch, getState) => {
    const state = getState().tasksPage;
    dispatch(setTasksIsLoadingAC(true));
    try {
      const response = await api.get("api/tasks", { params: state.filters });
      dispatch(
        setTasksAC(
          response.data,
          state.filters.page_number,
          state.filters.page_size
        )
      );
      dispatch(setTasksIsLoadingAC(false));
    } catch (e) {
      dispatch(setTasksIsLoadingAC(false));
      if (e.reponse) {
        dispatch(handleErrors(e));
      }
    }
  };
};

export const getWidgetUsers = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("api/users/list");
      dispatch(setWidgetUsers(response.data));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};

export const getWidget = (pk) =>  {
  return async dispatch => {
    dispatch(setWidgetIsLoading(true))
    try {
      const response = await api.get('api/widgets/detail', {
        params: {pk: pk}
      })
      dispatch(setWidgetData(response.data))
      dispatch(setWidgetIsLoading(false))
    } catch (e) {
      dispatch(setWidgetIsLoading(false))
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  }
}

export const saveWidget = (name, modelName, icon, color, isActive, queryData, pk = null) => {
  return async (dispatch) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("model_name", modelName);
    formData.append("icon", icon);
    formData.append("color", color);
    formData.append("query", JSON.stringify(queryData));
    formData.append("is_active", isActive);
    dispatch(setWidgetSaveInProcess(true))
    try {
      if (pk) {
        await api.put("api/widgets/edit/", formData, {
          params: { pk: pk },
        });
      } else {
        await api.post("api/widgets/create/", formData);
      }
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Виджет сохранен",
          "success",
          null,
          true
        )
      );
      dispatch(getTasksTC());
      dispatch(setWidgetSaveInProcess(false))
      dispatch(setShowWidgetModal(false));
    } catch (e) {
      dispatch(setWidgetSaveInProcess(false))
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
