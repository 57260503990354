import React from "react";

const Widget = (props) => {
  return (
    <div className="col-lg-3 grid-margin stretch-card">
      <div className={`card border-${props.color}`}>
        <div className="card-body">
          <h5 className="card-title">
            <div className="d-flex justify-content-between">
              <div>
                <i className={`${props.icon} me-2`} />
                <span>
                  <b>{props.name}</b>
                </span>
              </div>
              <button
                className="btn btn-sm p-1 text-primary"
                onClick={() => props.handleWidgetEditClick(props.id)}
              >
                <i className="mdi mdi-pencil" />
              </button>
            </div>
          </h5>
          <div onClick={() => props.handleWidgetClick(props.id)}>
            <small>
              Количество: <b>{props.count}</b> {props.widgetId === props.id && "(Выбран)"}
            </small>

          </div>
        </div>
      </div>
    </div>
  );
};
export default Widget;
