import React from "react";

const ProjectManageForm = (props) => {
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      <div className="col-sm-7">
        <small>
          <div className="mt-3">
            <label>Наименование проекта</label>
            <input
              type="text"
              className={`form-control form-control-sm ${
                props.errors.name ? "is-invalid" : ""
              }`}
              {...props.register("name", { required: true })}
            />
          </div>
          <div className="mt-3">
            <label>Описание проекта</label>
            <textarea
              rows="5"
              className={`form-control form-control-sm ${
                props.errors.description ? "is-invalid" : ""
              }`}
              {...props.register("description")}
            />
          </div>
          <div className="mt-3">
            <label>Начало проекта</label>
            <input
              type="datetime-local"
              className={`form-control form-control-sm ${
                props.errors.start ? "is-invalid" : ""
              }`}
              {...props.register("start", {
                required: true,
                valueAsDate: true,
              })}
            />
          </div>
          <div className="mt-3">
            <label>Завершить до</label>
            <input
              type="datetime-local"
              className={`form-control form-control-sm ${
                props.errors.finish ? "is-invalid" : ""
              }`}
              {...props.register("finish", {
                required: true,
                valueAsDate: true,
              })}
            />
          </div>
        </small>
        <div className="mt-3">
          <button
            disabled={props.taskCreateInProcess}
            className="btn btn-primary btn-sm text-white"
          >
            {props.taskCreateInProcess ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              />
            ) : props.projectId ? (
              "Сохранить изменения"
            ) : (
              "Создать проект"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProjectManageForm;
