export const actions = {
  SET_TASKS: "SET_TASKS",
  SET_TOTAL_COUNT: "SET_TOTAL_COUNT",
  SET_TASKS_IS_LOADING: "SET_TASKS_IS_LOADING",
  SET_TASKS_PAGE_NUMBER: "SET_TASKS_PAGE_NUMBER",
  SET_TASKS_PAGE_SIZE: "SET_TASKS_PAGE_SIZE",
  SET_TASKS_FILTERS: "SET_TASKS_FILTERS",
  SET_TASKS_ORDERING: "SET_TASKS_ORDERING",
  SET_SHOW_WIDGET_MODAL: "SET_SHOW_WIDGET_MODAL",
  SET_WIDGET_MODAL_USERS: "SET_WIDGET_MODAL_USERS",
  SET_WIDGET_DATA: "SET_WIDGET_DATA",
  SET_WIDGET_IS_LOADING: "SET_WIDGET_IS_LOADING",
  SET_WIDGET_SAVE_IN_PROCESS: "SET_WIDGET_SAVE_IN_PROCESS",
};

export const setTasksAC = (data, pageNumber, pageSize) => {
  return {
    type: actions.SET_TASKS,
    tasks: data.tasks,
    count: data.count,
    widgets: data.widgets.widgets,
    pageNumber: pageNumber,
    pageSize: pageSize,
    countRepr: data.count_repr,
  };
};

export const setTasksIsLoadingAC = (isLoading) => {
  return {
    type: actions.SET_TASKS_IS_LOADING,
    isLoading: isLoading,
  };
};

export const setTasksFiltersAC = (data) => {
  return {
    type: actions.SET_TASKS_FILTERS,
    data: data,
  };
};

export const setTasksOrderingsAC = (data) => {
  return {
    type: actions.SET_TASKS_ORDERING,
    data: data,
  };
};
export const setShowWidgetModal = (show) => {
  return {
    type: actions.SET_SHOW_WIDGET_MODAL,
    show: show
  }
}
export const setWidgetUsers = (data) => {
  return {
    type: actions.SET_WIDGET_MODAL_USERS,
    data: data
  }
}
export const setWidgetData = (data) => {
  return {
    type: actions.SET_WIDGET_DATA,
    data: data
  }
}
export const setWidgetIsLoading = (isLoading) => {
  return {
    type: actions.SET_WIDGET_IS_LOADING,
    isLoading: isLoading
  }
}
export const setWidgetSaveInProcess = (inProcess) => {
  return {
    type: actions.SET_WIDGET_SAVE_IN_PROCESS,
    inProcess: inProcess
  }
}