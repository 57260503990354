import React from "react";
import TaskSolutionModal from "../solution/TaskSolutionModal";

const StatusesButtons = (props) => {
  const statuses = [
    { value: "processing", label: "В работе" },
    { value: "suspended", label: "Приостановлена" },
    { value: "await_initiator_check", label: "Передана на проверку" },
  ].map((s, index) => (
    <button
      key={index}
      type="button"
      onClick={() => props.changeTaskStatus(s.value)}
      className={`btn btn-sm ${
        s.value === props.currentStatus ? "btn-primary text-white" : ""
      }`}
    >
      {s.label}
    </button>
  ));
  return (
    <div className="overflow-auto">
      <div class="btn-group" role="group">
        {statuses}
      </div>
    </div>
  );
};

const InitiatorCheckButtons = (props) => {
  return (
    <div className="overflow-auto">
      <div class="btn-group" role="group">
        <button
          className="btn btn-primary me-2 btn-sm text-white"
          onClick={() => {
            props.handleSolutionsModalShow(true);
          }}
        >
          Завершить задачу
        </button>
        <button
          className="btn btn-primary btn-sm text-white"
          onClick={() => {
            props.changeTaskStatus("processing");
          }}
        >
          Вернуть в работу
        </button>
      </div>
    </div>
  );
};

const InitiatorButtons = (props) => {
  return (
    <div className="overflow-auto">
      <div class="btn-group" role="group">
        <button
          className="btn btn-danger btn-sm text-white"
          onClick={() => {
            props.changeTaskStatus("aborted");
          }}
        >
          Отменить задачу
        </button>
      </div>
    </div>
  );
};

const StatusManageComponent = (props) => {
  const statuses = ["finished", "aborted"];
  const initiatorId = props.initiator?.participant;
  const executorId = props.executor?.participant;

  if (statuses.includes(props.currentStatus)) {
    return (
      <div className="form-group">
        <label className="form-label">
          Задача {props.currentStatusRepr.toLowerCase()}
        </label>
      </div>
    );
  }

  if (!executorId) {
    if (initiatorId === props.userId) {
      return (
        <>
          <div className="form-group">
            <label className="form-label">
              Задача {props.currentStatusRepr.toLowerCase()}
            </label>
          </div>
          <InitiatorButtons changeTaskStatus={props.changeTaskStatus} />
        </>
      );
    } else {
      return <label className="form-label">Исполнитель не назначен</label>;
    }
  } else {
    if (executorId === props.userId && initiatorId === props.userId) {
      if (props.currentStatus !== "await_initiator_check") {
        return (
          <StatusesButtons
            currentStatus={props.currentStatus}
            changeTaskStatus={props.changeTaskStatus}
          />
        );
      } else {
        return (
          <InitiatorCheckButtons
            handleSolutionsModalShow={props.handleSolutionsModalShow}
            changeTaskStatus={props.changeTaskStatus}
          />
        );
      }
    } else if (executorId === props.userId) {
      if (props.currentStatus === "await_initiator_check") {
        return (
          <div className="form-group">
            <label className="form-label">
              Задача {props.currentStatusRepr.toLowerCase()}
            </label>
          </div>
        );
      } else {
        return (
          <StatusesButtons
            currentStatus={props.currentStatus}
            changeTaskStatus={props.changeTaskStatus}
          />
        );
      }
    } else if (initiatorId === props.userId) {
      if (props.currentStatus === "await_initiator_check") {
        return (
          <InitiatorCheckButtons
            handleSolutionsModalShow={props.handleSolutionsModalShow}
            changeTaskStatus={props.changeTaskStatus}
          />
        );
      } else {
        return (
          <>
            <div className="form-group">
              <label className="form-label">
                Задача {props.currentStatusRepr.toLowerCase()}
              </label>
            </div>
            <InitiatorButtons
              currentStatus={props.currentStatus}
              changeTaskStatus={props.changeTaskStatus}
            />
          </>
        );
      }
    } else {
      return (
        <div className="form-group">
          <label className="form-label">
            Задача {props.currentStatusRepr.toLowerCase()}
          </label>
        </div>
      );
    }
  }
};

const TaskManageTab = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Управление</h4>
        <div className="mt-3">
          <TaskSolutionModal
            comments={props.comments}
            solutionsModalShow={props.solutionsModalShow}
            handleSetTaskSolution={props.handleSetTaskSolution}
            handleSolutionsModalShow={props.handleSolutionsModalShow}
            solutionSetInProcess={props.solutionSetInProcess}
          />
          <StatusManageComponent
            executor={props.executor}
            initiator={props.initiator}
            userId={props.userId}
            acceptanceStatus={props.acceptanceStatus}
            currentStatus={props.currentStatus}
            currentStatusRepr={props.currentStatusRepr}
            changeTaskStatus={props.changeTaskStatus}
            changeAcceptanceStatus={props.changeAcceptanceStatus}
            handleSolutionsModalShow={props.handleSolutionsModalShow}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskManageTab;
