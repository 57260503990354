import api from "../../../utils/api/api_beta";
import {
  setAlertToast,
  setAuthenticated,
  setCurrentNotification,
  setCurrentNotificationIsLoading,
  setGlobalSearchInProcess,
  setGlobalSearchResults,
  setNotificationRead,
  setProfileIsLoading,
  setReadInProcess,
  setShowContactEditModalAC,
  setShowProfileEditModalAC,
  setUserDataAC,
  setUserNotificationsAC,
  setUpdatePhotoInProcess,
  setLoginInProcess,
} from "../../actions/root/RootPageActions";
import { handleErrors } from "../HandleErrors";

export const login = (data) => {
  return async (dispatch) => {
    dispatch(setLoginInProcess(true));
    try {
      const response = await api.post("api/auth/login/", data);
      localStorage.setItem("token", response.data.access_token);
      dispatch(setAuthenticated(true));
      dispatch(getUserDataTC());
      dispatch(setLoginInProcess(false));
    } catch (e) {
      dispatch(setAuthenticated(false));
      dispatch(setLoginInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    try {
      await api.post("api/auth/logout/");
      localStorage.removeItem("token");
      dispatch(setUserDataAC({}));
      dispatch(setAuthenticated(false));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const verifyToken = () => {
  return async (dispatch) => {
    try {
      await api.post("api/auth/token/verify/", {
        token: localStorage.getItem("token"),
      });
    } catch (e) {
      localStorage.removeItem("token");
      dispatch(setUserDataAC({}));
      dispatch(setAuthenticated(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getUserDataTC = (showLoad = true) => {
  return async (dispatch) => {
    try {
      if (showLoad) {
        dispatch(setProfileIsLoading(true));
      }
      const response = await api.get("api/users/get-self-data");
      dispatch(setUserDataAC(response.data));
      dispatch(setAuthenticated(true));
      if (showLoad) {
        dispatch(setProfileIsLoading(false));
      }
    } catch (e) {
      dispatch(setAuthenticated(false));
      if (showLoad) {
        dispatch(setProfileIsLoading(false));
      }
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const refreshNotificationsTC = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("api/users/get-self-notifications");
      dispatch(setUserNotificationsAC(response.data));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setNotifyRead = (pk, read) => {
  return async (dispatch) => {
    dispatch(setReadInProcess(pk, true));
    try {
      const response = await api.put(
        "api/notifications/set-read/",
        {
          is_read: read,
        },
        {
          params: { pk: pk },
        }
      );
      dispatch(setNotificationRead(response.data.id, response.data.is_read));
      dispatch(setReadInProcess(pk, false));
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          `Уведомление помечено как ${read ? "прочитанное" : "не прочитанное"}`,
          read ? "success" : "warning",
          null,
          true
        )
      );
    } catch (e) {
      dispatch(setReadInProcess(pk, false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getNotificationDetail = (pk) => {
  return async (dispatch) => {
    dispatch(setCurrentNotificationIsLoading(true));
    try {
      const response = await api.get("api/notifications/detail", {
        params: { pk: pk },
      });
      dispatch(setCurrentNotification(response.data));
      dispatch(setCurrentNotificationIsLoading(false));
    } catch (e) {
      dispatch(setCurrentNotificationIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setAllNotifyRead = (read) => {
  return async (dispatch) => {
    dispatch(setReadInProcess(null, true, true));
    try {
      const response = await api.put("api/notifications/set-all-read/");
      dispatch(setNotificationRead(null, true, true));
      dispatch(setReadInProcess(null, true, true));
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Уведомления прочитаны",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setReadInProcess(null, true, true));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const createUserContact = (data) => {
  return async (dispatch) => {
    try {
      const response = await api.post("api/users/create-contact/", data);
      dispatch(getUserDataTC());
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Контакт добавлен",
          "success",
          null,
          true
        )
      );
      dispatch(setShowContactEditModalAC(false));
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const updateUserContact = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await api.put("api/users/edit-contact/", data, {
        params: { pk: id },
      });
      dispatch(getUserDataTC());
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Изменения контакта сохранены",
          "success",
          null,
          true
        )
      );
      dispatch(setShowContactEditModalAC(false));
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const updateUserData = (data) => {
  return async (dispatch) => {
    try {
      await api.put("api/users/edit-self-data/", data);
      dispatch(getUserDataTC());
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Изменения сохранены",
          "success",
          null,
          true
        )
      );
      dispatch(setShowProfileEditModalAC(false));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const updateUserPhoto = (photo) => {
  return async (dispatch) => {
    const data = new FormData();
    data.append("photo", photo);
    dispatch(setUpdatePhotoInProcess(true));
    try {
      await api.put("api/users/edit-self-photo/", data);
      dispatch(getUserDataTC());
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Фото профиля обновлено",
          "success",
          null,
          true
        )
      );
      dispatch(setUpdatePhotoInProcess(false));
    } catch (e) {
      dispatch(setUpdatePhotoInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const deleteUserContact = (id) => {
  return async (dispatch) => {
    try {
      const response = await api.delete("api/users/delete-contact", {
        params: { pk: id },
      });
      dispatch(getUserDataTC());
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Контакт удален",
          "success",
          null,
          true
        )
      );
      dispatch(setShowContactEditModalAC(false));
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getUserAvatar = (pk) => {
  return async (dispatch) => {
    try {
      return await api.get("api/user-avatar", {
        params: { pk: pk },
        responseType: "blob",
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getGlobalSearchResults = (searchValue) => {
  return async (dispatch) => {
    dispatch(setGlobalSearchInProcess(true));
    try {
      const response = await api.get("api/global-search", {
        params: { search_value: searchValue },
      });
      dispatch(setGlobalSearchResults(response.data));
      dispatch(setGlobalSearchInProcess(false));
    } catch (e) {
      dispatch(setGlobalSearchInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
