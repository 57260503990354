import React from "react";

const Error = () => {
  return (
    <div className="text-center">
      <div className="mt-2">
        <i className="mdi mdi-alert-outline text-primary icon-lg" />
      </div>

      <div className="mt-2">
        <span>При загрузке старницы возникла ошибка</span>
      </div>
    </div>
  );
};

export default Error;
