import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const IntroductionManageForm = (props) => {
  const usersOptions = props.participants?.map((u) => ({
    value: u.id,
    label: u.full_name,
  }));
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      <div className="col-sm-7">
        <small>
          <div className="mt-3">
            <label>Заголовок</label>
            <input
              type="text"
              className={`form-control form-control-sm ${
                props.errors.title ? "is-invalid" : ""
              }`}
              {...props.register("title", { required: true })}
            />
          </div>
          <div className="mt-3">
            <label>Описание</label>
            <textarea
              rows="5"
              className={`form-control form-control-sm ${
                props.errors.description ? "is-invalid" : ""
              }`}
              {...props.register("description")}
            />
          </div>
          <div className="mt-3">
            <label>Файл</label>
            {props.introductionId ? (
              <div>{props.filename}</div>
            ) : (
                <input
                type="file"
                className={`form-control ${
                  props.errors.file ? "is-invalid" : ""
                }`}
                {...props.register("file", { required: true })}
              />
            )}
          </div>
          <div className="mt-3">
            <label>Участники</label>
            <Controller
              control={props.control}
              name={"participants"}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    isMulti={true}
                    isClearable={true}
                    value={value}
                    onChange={onChange}
                    options={usersOptions}
                  />
                );
              }}
            />
          </div>
        </small>
      </div>
      <div className="mt-3">
        <button
          disabled={props.saveInProcess}
          className="btn btn-primary btn-sm text-white"
        >
          {props.saveInProcess ? (
            <div
              className="spinner-border spinner-border-sm text-white"
              role="status"
            />
          ) : !props.introductionId ? (
            "Создать опрос"
          ) : (
            "Сохранить изменения"
          )}
        </button>
      </div>
      
    </form>
  );
};
export default IntroductionManageForm;
