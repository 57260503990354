import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);
const StatisticCard = (props) => {
  const labels = props.doughnut_chart_data
    ? props.doughnut_chart_data.labels
    : [];
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    width: "40%",
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: "",
      },
    },
  };
  const doughnutChartData = {
    labels,
    datasets: [
      {
        label: "",
        data: props.doughnut_chart_data.data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(75, 192, 222, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(75, 192, 222, 1)",
        ],
      },
    ],
    borderWidth: 1,
  };
  return (
    <div className="mt-4">
      <div>
        <span>
          <b>{props.task}</b>
        </span>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="card border-0">
            <div className="card-title">
              График процентного соотношения затраченного времени
            </div>
            <div className="card-body overflow-auto">
              <Doughnut
                data={doughnutChartData}
                options={doughnutChartOptions}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card border-0">
            <div className="card-title">Статистика затраченного времени</div>
            <div className="card-body">
              <li>
                Время в работе: <b>{props.time_data.processing}</b>
              </li>
              <li>
                Время приостановки: <b>{props.time_data.suspend}</b>
              </li>
              <li>
                Время в статусе "Создана": <b>{props.time_data.created}</b>
              </li>
              <li>
                Время жизни задачи: <b>{props.time_data.lifetime}</b>
              </li>
              <li>
                Время на решение задачи: <b>{props.time_data.duration}</b>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TaskStatisticTab = (props) => {
  const rootTaskStatistic = props.statistic
    ? props.statistic
        .filter((t) => t.is_root)
        .map((t, index) => <StatisticCard key={index} {...t} />)
    : [];
  const descendantTasksStatistic = props.statistic
    ? props.statistic
        .filter((t) => !t.is_root)
        .map((t, index) => <StatisticCard key={index} {...t} />)
    : [];
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Статистика</h4>

        <span>
          <b>Статистика текущей задачи</b>
        </span>
        <hr />
        {rootTaskStatistic.length > 0 ? (
          rootTaskStatistic
        ) : (
          <div className="py-2">
            <i className="mdi mdi-alert-outline text-primary me-2" />
            <span>Задачи отсутствуют</span>
          </div>
        )}
        <span>
          <b>Статистика дочерних задач</b>
        </span>
        <hr />
        {descendantTasksStatistic.length > 0 ? (
          descendantTasksStatistic
        ) : (
          <div className="py-2">
            <i className="mdi mdi-alert-outline text-primary me-2" />
            <span>Дочерние задачи отсутствуют</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskStatisticTab;
