import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Employee from "./Employee";
import { getEmployee } from "../../redux/thunk/employees/EmployeeThunk";
import { useParams } from "react-router-dom";
import Spinner from "../shared/Spinner";
import { getEventDetail } from "../../redux/thunk/events/EventThunk";
import { getUserAvatar } from "../../redux/thunk/root/RootPageThunk";

const EmployeeContainer = (props) => {
  const [eventModalShow, setEventModalShow] = useState(false);
  const params = useParams();
  const handleEventShowModal = (show, id = null) => {
    if (show) {
      props.getEventDetail(id);
    }
    setEventModalShow(show);
  };
  useEffect(() => {
    props.getEmployee(params.id);
  }, [params.id]);
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <Employee
      eventModalShow={eventModalShow}
      handleEventShowModal={handleEventShowModal}
      {...props}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    employee: state.employeePage.employee,
    isLoading: state.employeePage.isLoading,
    event: state.calendarPage.event,
    modalIsLoading: state.calendarPage.modalIsLoading,
  };
};
export default connect(mapStateToProps, {
  getEventDetail,
  getEmployee,
  getUserAvatar,
})(EmployeeContainer);
