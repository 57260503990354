import { actions } from "../../../actions/projects/list/ProjectListActions";

const initialState = {
  projects: [],
  project: {},
  filters: {
    page_size: 10,
    page_number: 1,
  },
  count: 0,
  pageNumber: 1,
  pageSize: 10,
  countRepr: "0 проектов",
  isLoading: false,
};

export const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PROJECTS:
      return {
        ...state,
        projects: action.data.projects,
        count: action.data.count,
        countRepr: action.data.countRepr,
      };
    case actions.SET_PROJECT:
      return {
        ...state,
        project: action.data,
      };
    case actions.SET_PROJECTS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_PROJECTS_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    default:
      return state;
  }
};
