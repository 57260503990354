import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo-stk.png";
import logoSmall from "../../assets/images/logo-stk-small.png";
import useScreenType from "react-screentype-hook";

const SingleUrlRoute = (props) => {
  return (
    <li
      className={props.isPathActive(props.url) ? "nav-item active" : "nav-item"}
    >
      <NavLink
        className="nav-link"
        to={props.url}
        onClick={
          props.isMobile
            ? () =>
                document
                  .querySelector(".sidebar-offcanvas")
                  .classList.toggle("active")
            : null
        }
      >
        <i className={`${props.icon_name} icon-sm mx-2`}></i>
        <span className="menu-title">
          <span>{props.name}</span>
        </span>
      </NavLink>
    </li>
  );
};

const DropdownRoute = (props) => {
  const descendants = props.descendants.map((d) => (
    <li className="nav-item" key={d.id}>
      <NavLink
        className={
          props.isPathActive(props.url) ? "nav-link active" : "nav-link"
        }
        to={d.url}
        onClick={
          props.isMobile
            ? () =>
                document
                  .querySelector(".sidebar-offcanvas")
                  .classList.toggle("active")
            : null
        }
      >
        {d.name}
      </NavLink>
    </li>
  ));

  return (
    <li
      className={props.isPathActive(props.url) ? "nav-item active" : "nav-item"}
    >
      <div
        className={
          props.state[props.id] ? "nav-link menu-expanded" : "nav-link"
        }
        onClick={() => props.toggleMenuState(props.id)}
        data-toggle="collapse"
      >
        <i className={`${props.icon_name} icon-sm mx-2`}></i>
        <span className="menu-title">{props.name}</span>
        <i className="menu-arrow"></i>
      </div>
      <Collapse in={props.state[props.id]}>
        <ul className="nav flex-column sub-menu">{descendants}</ul>
      </Collapse>
    </li>
  );
};

const Sidebar = (props) => {
  const screenType = useScreenType();
  const [state, setState] = useState({});
  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  const isPathActive = (path) => {
    return props.currentPath.startsWith(path);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [props.routes]);

  const routes = props.routes?.map((r) =>
    r.route_type === "single_url" ? (
      <SingleUrlRoute
        {...r}
        key={r.id}
        isPathActive={isPathActive}
        toggleMenuState={toggleMenuState}
        isMobile={screenType.isMobile}
      />
    ) : (
      <DropdownRoute
        {...r}
        key={r.id}
        isPathActive={isPathActive}
        state={state}
        toggleMenuState={toggleMenuState}
        isMobile={screenType.isMobile}
      />
    )
  );

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="text-center sidebar-brand-wrapper d-flex align-items-center mt-3">
        <a className="sidebar-brand brand-logo">
          <img src={logo} alt="logo" />
        </a>
        <div className="mx-3">
          <img
            src={logoSmall}
            className="sidebar-brand brand-logo-mini"
            width="35"
            height="40"
            alt="logo"
          />
        </div>
      </div>
      <ul className="nav mt-3">{routes}</ul>
    </nav>
  );
};

export default Sidebar;
