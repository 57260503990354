export const formatDate = (text, format = "d.m.y H:M") => {
  const d = new Date(text);
  const f = [...format];
  const dateObj = {
    d: d.getDate().toString().length < 2 ? `0${d.getDate()}` : d.getDate(),
    m:
      (d.getMonth() + 1).toString().length < 2
        ? `0${d.getMonth() + 1}`
        : d.getMonth() + 1,
    y: d.getFullYear(),
    H: d.getHours().toString().length < 2 ? `0${d.getHours()}` : d.getHours(),
    M:
      d.getMinutes().toString().length < 2
        ? `0${d.getMinutes()}`
        : d.getMinutes(),
    S:
      d.getSeconds().toString().length < 2
        ? `0${d.getSeconds()}`
        : d.getSeconds(),
  };
  let resultDate = "";
  f.forEach((el) => {
    dateObj.hasOwnProperty(el)
      ? (resultDate += dateObj[el])
      : (resultDate += el);
  });
  return resultDate;
};
export const blobToUrl = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    return reader.result;
  };
};
