import React, { useEffect } from "react";
import Department from "./Department";
import { connect } from "react-redux";
import {
  getDepartments,
  getUsersInDepartment,
  getVacationScheduleFile,
} from "../../redux/thunk/departments/DepartmentThunk";
import Spinner from "../shared/Spinner";
import fileDownload from "js-file-download";

const DepartmentContainer = (props) => {
  useEffect(() => {
    props.getDepartments();
  }, []);
  const handleVacationScheduleFileDownload = () => {
    const filename = `План отпусков сотрудников ${
      props.detail.name
    } на ${new Date().getFullYear()}.xlsx`;
    props.getVacationScheduleFile(props.detail.id).then((res) => fileDownload(res.data, filename));
  };
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <Department
      handleVacationScheduleFileDownload={handleVacationScheduleFileDownload}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    detail: state.departmentsPage.detail,
    treeData: state.departmentsPage.treeData,
    usersVacations: state.departmentsPage.usersVacations,
    users: state.departmentsPage.users,
    isLoading: state.departmentsPage.isLoading,
    usersIsLoading: state.departmentsPage.usersIsLoading,
    userId: state.rootPage.userData.id,
  };
};

export default connect(mapStateToProps, {
  getDepartments,
  getUsersInDepartment,
  getVacationScheduleFile,
})(DepartmentContainer);
