import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logoSmallBlack from "../../assets/images/logo-stk-small-black.png";

const Navbar = (props) => {
  const navigate = useNavigate();
  const truncateChars = (text) => {
    if (!text) {
      return null;
    }
    return text.length > 35 ? `${text.substring(0, 35)}...` : text;
  };
  const notifications = props.notifications
    ? props.notifications
        .filter((n) => !n.is_read)
        .map((n, index) =>
          !n.is_read ? (
            <div key={index}>
              <Dropdown.Item
                className="dropdown-item preview-item d-flex align-items-center"
                onClick={(evt) => {
                  evt.preventDefault();
                  navigate(`notifications/${n.id}/detail`);
                }}
              >
                <div className="preview-thumbnail">
                  <i
                    className={`${
                      n.icon_name ? n.icon_name : "mdi-bell"
                    } text-primary`}
                  />
                </div>
                <div className="preview-item-content py-2">
                  <h6 className="preview-subject font-weight-normal text-dark mb-1">
                    <span>
                      <small>{truncateChars(n.title)}</small>
                    </span>
                  </h6>
                  <p className="font-weight-light small-text mb-0">
                    <span>{n.created_at}</span>
                  </p>
                </div>
              </Dropdown.Item>
              <div className="dropdown-divider"></div>
            </div>
          ) : null
        )
    : [];
  const handleGlobalSearchInput = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      navigate(`/global-search?search_value=${e.target.value}`);
    }
  };
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="#"
          onClick={(evt) => evt.preventDefault()}
        >
          <div className="mx-3">
            <img src={logoSmallBlack} width="35" height="40" alt="logo" />
          </div>
        </a>
        <button
          className="navbar-toggler navbar-toggler align-self-center btn-sm"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <form className="ml-auto search-form d-none d-md-block" action="#">
          <div className="form-group">
            <div className="input-group">
              <input
                type="text"
                className="form-control form-control"
                aria-describedby="basic-addon2"
                aria-label="global-search"
                placeholder="Задачи, проекты, пользователи..."
                onKeyDown={handleGlobalSearchInput}
              />
            </div>
          </div>
        </form>
        <ul className="navbar-nav navbar-nav-right">
          {props.profileIsLoading ? (
            <div className="spinner-wrapper">
              <div
                className="spinner-border text-primary spinner-border-sm"
                role="status"
              />
            </div>
          ) : (
            <>
              <li className="nav-item  nav-profile border-0 pl-4">
                <Dropdown>
                  <div className="dropdown-divider"></div>
                  <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                    <i className="mdi mdi-bell-outline"></i>
                    {notifications.length > 0 ? (
                      <span className="count bg-success">
                        <small>
                          {notifications.length > 99
                            ? "99+"
                            : notifications.length}
                        </small>
                      </span>
                    ) : null}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="navbar-dropdown preview-list">
                    <Dropdown.Item
                      className="dropdown-item py-3 d-flex align-items-center"
                      onClick={(evt) => {
                        evt.preventDefault();
                        navigate("/notifications");
                      }}
                    >
                      <p className="mb-0 font-weight-medium float-left">
                        {notifications.length > 0
                          ? `У вас ${notifications.length} уведомлений`
                          : "Уведомления отсутствуют"}
                      </p>
                      <span className="badge badge-pill text-black float-right">
                        Все уведомления
                      </span>
                    </Dropdown.Item>
                    {notifications.slice(0, 5)}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="nav-item nav-profile border-0">
                <Dropdown>
                  <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                    <small className="text-muted mt-2 me-2">
                      {props.firstName} {props.lastName}
                    </small>
                    <img
                      className="img-xs rounded-circle border"
                      src={props?.photo} //{`data:image/jpeg;base64,${props?.photo}`}
                      alt="Profile"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                    <Dropdown.Item
                      className="dropdown-item p-0 preview-item d-flex align-items-center border-bottom"
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                    ></Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item preview-item d-flex align-items-left border-0 mt-2"
                      href="/profile"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/profile");
                      }}
                    >
                      <i className="mdi mdi-account me-2" />
                      <span>Профиль</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item preview-item d-flex align-items-left border-0"
                      onClick={() => props.logout()}
                    >
                      <i className="mdi mdi-logout me-2" />
                      <span>Выход из учетной записи</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </>
          )}
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={() =>
            document
              .querySelector(".sidebar-offcanvas")
              .classList.toggle("active")
          }
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
