export const actions = {
  SET_INTRODUCTIONS: "SET_INTRODUCTIONS",
  SET_INTRODUCTIONS_COUNT: "SET_INTRODUCTIONS_COUNT",
  SET_INTRODUCTIONS_IS_LOADING: "SET_INTRODUCTIONS_IS_LOADING",
  SET_INTRODUCTIONS_PAGE_NUMBER: "SET_INTRODUCTIONS_PAGE_NUMBER",
  SET_INTRODUCTIONS_PAGE_SIZE: "SET_INTRODUCTIONS_PAGE_SIZE",
  SET_INTRODUCTIONS_FILTERS: "SET_INTRODUCTIONS_FILTERS",
  SET_INTRODUCTIONS_ORDERING: "SET_INTRODUCTIONS_ORDERING",
};

export const setIntroductions = (data, pageNumber, pageSize) => {
  return {
    type: actions.SET_INTRODUCTIONS,
    introductions: data.introductions,
    count: data.count,
    pageNumber: pageNumber,
    pageSize: pageSize,
  };
};

export const setIntroductionsIsLoading = (isLoading) => {
  return {
    type: actions.SET_INTRODUCTIONS_IS_LOADING,
    isLoading: isLoading,
  };
};

export const setIntroductionsFilters = (data) => {
  return {
    type: actions.SET_INTRODUCTIONS_FILTERS,
    data: data,
  };
};

export const setIntroductionsOrderings = (data) => {
  return {
    type: actions.SET_INTRODUCTIONS_ORDERING,
    data: data,
  };
};
