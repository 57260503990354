import React from "react";

const ContactCard = (props) => {
  const icons = {
    telegram: "mdi mdi-telegram",
    express: "mdi mdi-contact-mail",
    work_mail: "mdi mdi-email",
    self_mail: "mdi mdi-email",
    work_phone: "mdi mdi-deskphone",
    self_phone: "mdi mdi-cellphone-android",
    extension_phone: "mdi mdi-phone",
  };
  return (
    <div className="col-sm-6 grid-margin stretch-card mt-1">
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div>
              <i className={`me-2 text-primary ${icons[props.type]}`} />{" "}
              {props.type_repr}
            </div>
            <div>
              <button
                type="button"
                className="btn mdi mdi-pencil text-primary ml-4 p-0 me-2"
                onClick={() => props.handleShowContactModal(true, props.id)}
              />
              <button
                type="button"
                className="btn mdi mdi-delete text-danger p-0"
                onClick={() => props.deleteUserContact(props.id)}
              />
            </div>
          </div>
        </div>
        <div className="card-body py-2">
          <b>{props.contact}</b>
        </div>
        <div className="card-footer">
          {props.use_for_notify ? (
            <>
              <i className="mdi mdi-bell me-2 text-primary" />
              Используется для уведомлений
            </>
          ) : (
            "Не используется для уведомлений"
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
