import React from "react";
import { Toast } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const AlertToast = (props) => {
  const getMessageText = (text) => {
    if (props.body[0] instanceof Object) {
      if (props.body[0].hasOwnProperty("message")) {
        return props.body["0"].message;
      }
      return "Неизвестная ошибка";
    }
    return text;
  };

  return (
    <Toast
      show={true}
      bg={props.level ? props.level.toLowerCase() : null}
      onClose={() => props.onClose("", "", "", "", false, props.id)}
      className="border shadow-lg"
    >
      <Toast.Header>
        {props.icon ? <i className={`${props.icon} me-3`} /> : null}
        <strong className="me-auto">{props.title}</strong>
        {/*<small>11 mins ago</small>*/}
      </Toast.Header>
      <Toast.Body>
        {getMessageText(props.body)}
        {!props.level ? (
          <div className="d-flex justify-content-start mt-3">
            {props.url ? (
              <NavLink
                to={props.url}
                className="btn btn-primary btn-sm text-white me-2"
              >
                Просмотр
              </NavLink>
            ) : null}
            <NavLink
              to="/notifications"
              className="btn btn-primary btn-sm text-white"
            > 
              Перейти к уведомлениям
            </NavLink>
          </div>
        ) : null}
      </Toast.Body>
    </Toast>
  );
};

export default AlertToast;
