import { actions } from "../../actions/vacations/VacationActions";

const initialState = {
  vacations: [],
  vacation: {},
  vacationDaysRemain: 0,
  hasErrors: false,
  isLoading: false,
  vacationSaveInProcess: false,
};

export const vacationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_VACATIONS:
      return {
        ...state,
        vacations: action.data,
        vacationDaysRemain: action.days,
      };
    case actions.SET_VACATION:
      return {
        ...state,
        vacation: action.data,
      };
    case actions.SET_VACATIONS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_VACATIONS_HAS_ERRORS:
      return {
        ...state,
        hasErrors: action.hasErrors,
      };
    case actions.SET_VACATION_SAVE_IN_PROCESS:
      return {
        ...state,
        vacationSaveInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
