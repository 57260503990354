import React from "react";
import ProjectManageForm from "../form/ProjectManageForm";

const ProjectManage = (props) => {
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>{props.projectId ? "Радактирование проекта" : "Новый проект"}</b>
      </div>
      <div className="col-sm-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Основная информация о проекте</h4>
            <ProjectManageForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectManage;
