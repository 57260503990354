import React, { useEffect } from "react";
import GlobalSearch from "./GlobalSearch";
import Spinner from "../shared/Spinner";
import { useSearchParams } from "react-router-dom";
import { getGlobalSearchResults } from "../../redux/thunk/root/RootPageThunk";
import { connect } from "react-redux";

const GlobalSearchContainer = (props) => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    props.getGlobalSearchResults(searchParams.get("search_value"));
  }, [searchParams]);
  if (props.inProcess) {
    return <Spinner />;
  }
  return <GlobalSearch {...props} />;
};
export const mapStateToProps = (state) => {
  return {
    results: state.rootPage.globalSearchResults,
    inProcess: state.rootPage.globalSearchInProcess,
  };
};
export default connect(mapStateToProps, {
  getGlobalSearchResults,
})(GlobalSearchContainer);
