import api from "../../../utils/api/api_beta";
import {
  setCalendarEventAC,
  setCalendarEventsAC,
  setCalendarIsLoading,
  setEvent,
  setEventCompletedInProcess,
  setEventCompleted as completeEvent,
  setEventModalIsLoading,
  setEventSaveInProcess,
  setParticipants,
  updateEvent,
} from "../../actions/calendar/CalendarActions";
import { setAlertToast } from "../../actions/root/RootPageActions";
import { handleErrors } from "../HandleErrors";

export const getEvents = (eventType = null) => {
  return async (dispatch) => {
    try {
      dispatch(setCalendarIsLoading(true));
      const response = await api.get("api/events", {
        params: { event_type: eventType ? eventType : null },
      });
      dispatch(setCalendarEventsAC(response.data.events));
      dispatch(setCalendarIsLoading(false));
    } catch (e) {
      dispatch(setCalendarIsLoading(false));
    }
  };
};
export const getEventDetail = (id) => {
  return async (dispatch) => {
    try {
      dispatch(setEventModalIsLoading(true));
      const response = await api.get("api/events/detail", {
        params: { pk: id },
      });
      response.data.users = response.data.users.map((u) => ({
        value: u.id,
        label: u.short_name,
      }));
      dispatch(setCalendarEventAC(response.data));
      dispatch(setEventModalIsLoading(false));
    } catch (e) {
      dispatch(setEventModalIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getParticipants = (id = null) => {
  return async (dispatch) => {
    try {
      const response = await api.get("api/events/participants", {
        params: { event: id && id },
      });
      dispatch(setParticipants(response.data));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveEvent = (data, pk = null) => {
  return async (dispatch) => {
    try {
      dispatch(setEventSaveInProcess(true));
      let response;
      let alertText = "Событие создано";
      if (pk) {
        response = await api.put("api/events/edit/", data, {
          params: { pk: pk },
        });
        alertText = "Изменения сохранены";
        dispatch(updateEvent(response.data));
      } else {
        response = await api.post("api/events/create/", data);
        dispatch(setEvent(response.data, true));
      }
      dispatch(setEventSaveInProcess(false));
      dispatch(
        setAlertToast("Успешное выполнение", alertText, "success", null, true)
      );
      return response;
    } catch (e) {
      dispatch(setEventSaveInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setEventCompleted = (pk, completed) => {
  return async (dispatch) => {
    dispatch(setEventCompletedInProcess(true));
    try {
      const response = await api.put(
        "api/events/set-completed/",
        {
          completed: completed,
        },
        {
          params: { pk: pk },
        }
      );
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Событие завершено",
          "success",
          null,
          true
        )
      );
      console.log(response.data);
      dispatch(setEventCompletedInProcess(false));
      dispatch(completeEvent(pk, response.data.completed));
    } catch (e) {
      dispatch(setEventCompletedInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const deleteEvent = (id) => {
  return async (dispatch) => {
    try {
      await api.delete("api/events/delete/", {
        params: { pk: id },
      });
      dispatch(setEvent({ id: id }, false));
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Событие удалено",
          "warning",
          null,
          true
        )
      );
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
