export const actions = {
  SET_CALENDAR_EVENTS: "SET_CALENDAR_EVENTS",
  SET_CALENDAR_EVENT: "SET_CALENDAR_EVENT",
  SET_CALENDAR_IS_LOADING: "SET_CALENDAR_IS_LOADING",
  SET_EVENT: "SET_EVENT",
  UPDATE_EVENT: "UPDATE_EVENT",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_EVENT_MODAL_IS_LOADING: "SET_EVENT_MODAL_IS_LOADING",
  SET_EVENT_SAVE_IN_PROCESS: "SET_EVENT_SAVE_IN_PROCESS",
  SET_EVENT_COMPLETED: "SET_EVENT_COMPLETED",
  SET_EVENT_COMPLETED_IN_PROCESS: "SET_EVENT_COMPLETED_IN_PROCESS",
};

export const setCalendarEventsAC = (data) => {
  return {
    type: actions.SET_CALENDAR_EVENTS,
    data: data,
  };
};
export const setCalendarEventAC = (data) => {
  return {
    type: actions.SET_CALENDAR_EVENT,
    data: data,
  };
};
export const setCalendarIsLoading = (isLoading) => {
  return {
    type: actions.SET_CALENDAR_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setEvent = (data, add) => {
  return {
    type: actions.SET_EVENT,
    data: data,
    add: add,
  };
};
export const updateEvent = (data) => {
  return {
    type: actions.UPDATE_EVENT,
    data: data,
  };
};
export const setParticipants = (data) => {
  return {
    type: actions.SET_PARTICIPANTS,
    data: data,
  };
};
export const setEventModalIsLoading = (isLoading) => {
  return {
    type: actions.SET_EVENT_MODAL_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setEventSaveInProcess = (inProcess) => {
  return {
    type: actions.SET_EVENT_SAVE_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setEventCompleted = (id, completed) => {
  return {
    type: actions.SET_EVENT_COMPLETED,
    id: id,
    completed: completed,
  };
};
export const setEventCompletedInProcess = (inProcess) => {
  return {
    type: actions.SET_EVENT_COMPLETED_IN_PROCESS,
    inProcess: inProcess,
  };
};
