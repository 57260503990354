import axios from "axios";
import { baseApiUrl } from "../../config";

const BASE_URL = baseApiUrl;

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    if (
      err.response.status === 401 &&
      err.config &&
      err.config.url !== "api/auth/token/refresh/"
    ) {
      const originalRequest = err.config;
      try {
        const response = await api.post(
          "api/auth/token/refresh/",
          {},
          {
            withCredentials: true,
          }
        );
        localStorage.setItem("token", response.data.access);
        return api.request(originalRequest);
      } catch (e) {
        localStorage.removeItem("token");
      }
    }
    return Promise.reject(err);
  }
);

export default api;
