import "./App.scss";
import RootPageContainer from "./components/root/RootPageContainer";
import { connect } from "react-redux";
import { setAlertToast } from "./redux/actions/root/RootPageActions";
import { getToken } from "./utils/auth";
import React from "react";
import { login } from "./redux/thunk/root/RootPageThunk";
import LoginContainer from "./components/login/LoginContainer";

const App = (props) => {
  return getToken() ? <RootPageContainer /> : <LoginContainer />;
};

const mapStateToProps = (state) => {
  return {
    alerts: state.rootPage.alertToasts,
    authenticated: state.rootPage.authenticated,
  };
};

export default connect(mapStateToProps, {
  setAlertToastAC: setAlertToast,
  loginTC: login,
})(App);
