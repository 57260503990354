import { setIntroductions, setIntroductionsIsLoading } from "../../../actions/introductions/list/IntroductionsActions";
import api from "../../../../utils/api/api_beta";
import { handleErrors } from "../../HandleErrors";

export const getIntroductions = () => {
  return async (dispatch, getState) => {
    const state = getState().introductionsPage;
    dispatch(setIntroductionsIsLoading(true));
    try {
      const response = await api.get("api/introductions", {
        params: state.filters,
      });
      dispatch(
        setIntroductions(
          response.data,
          state.filters.page_number,
          state.filters.page_size
        )
      );
      dispatch(setIntroductionsIsLoading(false));
    } catch (e) {
      dispatch(setIntroductionsIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
