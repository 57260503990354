import React from "react";
import Modal from "react-bootstrap/Modal";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Spinner from "../shared/Spinner";
import { formatDate } from "../../utils/utils";

const EventEditForm = (props) => {
  const colorsOptions = [
    { value: "#2196f3", label: "Синий" },
    { value: "#dde4eb", label: "Серый" },
    { value: "#ffaf00", label: "Желтый" },
    { value: "#ff6258", label: "Красный" },
    { value: "#19d895", label: "Зеленый" },
  ].map((c, index) => (
    <option key={index} value={c.value}>
      {c.label}
    </option>
  ));
  const typesOptions = [
    { value: "meeting", label: "Совещание" },
    { value: "vacation", label: "Отпуск" },
    { value: "education", label: "Обучение" },
    { value: "business_trip", label: "Командировка" },
    { value: "task", label: "Задача" },
    { value: "sick_leave", label: "Больничный" },
    { value: "other", label: "Другое" },
  ].map((e, index) => (
    <option key={index} value={e.value}>
      {e.label}
    </option>
  ));
  const usersOptions = props.participants?.map((u) => ({
    value: u.id,
    label: u.full_name,
  }));
  const eventId = props.getValues("id");
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      <div>
        <label>Наименование события</label>
        <input
          type="text"
          className={`form-control form-control-sm ${
            props.errors.name ? "is-invalid" : ""
          }`}
          {...props.register("name", { required: true })}
        />
      </div>
      <div className="mt-3">
        <label>Описание события</label>
        <textarea
          rows="5"
          className={`form-control form-control-sm ${
            props.errors.description ? "is-invalid" : ""
          }`}
          {...props.register("description")}
        />
      </div>
      <div className="mt-3">
        <label>Время начала события</label>
        <input
          type="datetime-local"
          className={`form-control form-control-sm ${
            props.errors.start ? "is-invalid" : ""
          }`}
          {...props.register("start", { required: true, valueAsDate: true })}
        />
      </div>
      <div className="mt-3">
        <label>Время завершения события</label>
        <input
          type="datetime-local"
          className={`form-control form-control-sm ${
            props.errors.finish ? "is-invalid" : ""
          }`}
          {...props.register("finish", { required: true, valueAsDate: true })}
        />
      </div>
      <div className="mt-3">
        <label>Участники события</label>
        <Controller
          control={props.control}
          name={"users"}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                isMulti={true}
                value={value}
                onChange={onChange}
                options={usersOptions}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        <label>Цвет значка</label>
        <select
          className={`form-control form-control-sm ${
            props.errors.color ? "is-invalid" : ""
          }`}
          {...props.register("color", { required: true })}
        >
          {colorsOptions}
        </select>
      </div>
      <div className="mt-3">
        <label>Тип события</label>
        <select
          className={`form-control form-control-sm ${
            props.errors.event_type ? "is-invalid" : ""
          }`}
          {...props.register("event_type", { required: true })}
        >
          {typesOptions}
        </select>
      </div>

      <div className="d-flex justify-content-end mt-2">
        <button className="btn btn-success btn-sm me-2 text-white">
          Сохранить изменения
        </button>
        {eventId ? (
          <button
            className="btn btn-danger btn-sm text-white"
            type="button"
            onClick={() => props.handleDeleteButtonClick(eventId)}
          >
            Удалить событие
          </button>
        ) : null}
      </div>
    </form>
  );
};

const EventDetailForm = (props) => {
  const eventParticipants = props.users?.map((u) => (
    <li key={u.value}>
      <b>{u.label}</b>
    </li>
  ));
  const typesOptions = [
    { value: "meeting", label: "Совещание" },
    { value: "vacation", label: "Отпуск" },
    { value: "education", label: "Обучение" },
    { value: "business_trip", label: "Командировка" },
    { value: "other", label: "Другое" },
  ];
  return (
    <>
      <div className="row">
        <div className="col-md-4">Наименование события</div>
        <div className="col-md-8">
          <b>{props.name}</b>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-md-4">Описание события</div>
        <div className="col-md-8">
          <b>{props.description}</b>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-md-4">Дата начала события</div>
        <div className="col-md-8">
          <b>{formatDate(props.start)}</b>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-md-4">Дата завершения события</div>
        <div className="col-md-8">
          <b>{formatDate(props.finish)}</b>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-md-4">Тип события</div>
        <div className="col-md-8">
          <b>{typesOptions.find((t) => t.value === props.event_type)?.label}</b>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col-md-4">Участники события</div>
        <div className="col-md-8">
          <ul>{eventParticipants}</ul>
        </div>
      </div>
      {props.completed ? (
        <div className="alert alert-danger mt-3">
          <i className="mdi mdi-alert-outline text-danger me-2" />
          <span>Событие завершено</span>
        </div>
      ) : null}
    </>
  );
};

const EventDetailModal = (props) => {
  const onHide = () => {
    props.handleEventShowModal(false);
  };
  return (
    <Modal
      show={props.show}
      centered={true}
      size="md"
      scrollable={true}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Событие</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.modalIsLoading ? (
          <Spinner />
        ) : (
          <>
            {props?.event?.owner?.id === props.userId &&
            props.modalMode === "detail" &&
            !props.event.completed && props.event.editable ? (
              <>
                <button
                  className="btn btn-primary btn-sm text-white mb-3 me-2"
                  onClick={() =>
                    props.handleEventShowModal(true, props.event.id, "edit")
                  }
                >
                  Редактировать
                </button>
                <button
                  className="btn btn-success btn-sm text-white mb-3"
                  onClick={() =>
                    props.handleSetEventCompleted(props.event.id, true)
                  }
                >
                  {props.eventCompleteInProcess ? (
                    <div
                      className="spinner-border spinner-border-sm text-white"
                      role="status"
                    />
                  ) : (
                    "Завершить событие"
                  )}
                </button>
              </>
            ) : null}
            {["create", "edit"].includes(props.modalMode) && props.show ? (
              <EventEditForm
                register={props.register}
                control={props.control}
                onSubmit={props.onSubmit}
                errors={props.errors}
                participants={props.participants}
                getValues={props.getValues}
                handleSubmit={props.handleSubmit}
                handleDeleteButtonClick={props.handleDeleteButtonClick}
              />
            ) : (
              <EventDetailForm {...props.event} />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EventDetailModal;
