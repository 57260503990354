import React, { useEffect } from "react";
import RootPage from "./RootPage";
import { connect } from "react-redux";
import {
  setAlertToast,
  setShowProfileEditModalAC,
} from "../../redux/actions/root/RootPageActions";
import useWebSocket from "react-use-websocket";
import {
  getUserAvatar,
  getUserDataTC,
  login,
  logout,
  refreshNotificationsTC,
  updateUserData,
} from "../../redux/thunk/root/RootPageThunk";
import { getEvents } from "../../redux/thunk/events/EventThunk";
import {
  getTaskComments,
  getTaskCurrentStatus,
} from "../../redux/thunk/tasks/detail/TaskDetailThunk";
import { wsUrl, wsReconnectAttempts, wsReconnectDelay } from "../../config";
import Spinner from "../shared/Spinner";

const RootPageContainer = (props) => {
  const { lastMessage } = useWebSocket(wsUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: wsReconnectDelay,
    reconnectAttempts: wsReconnectAttempts,
    share: true,
  });
  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const message = JSON.parse(data.message);
      props.setAlertToastAC(
        message.title,
        message.text,
        "",
        message.icon_name,
        true,
        null,
        message.url
      );
      props.refreshNotificationsTC();
    }
  }, [lastMessage]);

  useEffect(() => {
    props.getUserDataTC();
  }, []);

  if (Object.keys(props.userData).length === 0) {
    return <Spinner text="Загрузка системы..." />;
  }

  return <RootPage {...props} />;
};

const mapStateToProps = (state) => {
  return {
    alerts: state.rootPage.alertToasts,
    notifications: state.rootPage.notificationToasts,
    userData: state.rootPage.userData,
    currentPath: state.rootPage.currentPath,
    isFullPageLayout: state.rootPage.isFullPageLayout,
    profileIsLoading: state.rootPage.profileIsLoading,
    authenticated: state.rootPage.authenticated
  };
};

export default connect(mapStateToProps, {
  setAlertToastAC: setAlertToast,
  loginTC: login,
  logoutTC: logout,
  setShowProfileEditModalAC,
  getUserDataTC,
  getUserAvatar,
  refreshNotificationsTC,
  updateUserData,
  getEvents,
  getTaskComments,
  getTaskCurrentStatus,
})(RootPageContainer);
