import React, { useState } from "react";
import { formatDate } from "../../utils/utils";
import CalendarComponent from "../calendar/CalendarComponent";
import { NavLink } from "react-router-dom";

const EmployeeTaskCard = (props) => {
  return (
    <div className="col-lg-4 grid-margin stretch-card">
      <div className="card">
        <div className="card-body py-2">
          <div>
            <small>
              <NavLink to={`/tasks/${props.id}/detail`}>
                {props.number} {props.name}
              </NavLink>
            </small>
          </div>
          <div className="mt-2">
            <small>Приоритет: </small>
            <button
              className={`text-white btn btn-sm btn-${
                props.priority === "1"
                  ? "danger"
                  : props.priority === "2"
                  ? "warning"
                  : "success"
              }  p-1`}
              onClick={(e) => e.preventDefault()}
            >
              <b>{props.priority_repr}</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const EmployeeInfoTab = (props) => {
  const tasks = props.employee?.tasks?.map((t) => (
    <EmployeeTaskCard key={t.id} {...t} />
  ));
  const skills = props.employee?.skills?.map((s) => s.name).join(", ");
  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-lg-6 mb-3 mt-2">
          <div className="card">
            <div className="card-body text-center">
              <img
                src={props.employee.photo}
                alt="..."
                className="rounded-circle img-fluid"
                style={{ width: "150px", height: "150px" }}
              />
              <p className="text-muted mt-3 mb-1">
                {props.employee.position_repr}
              </p>
              <p className="text-muted mb-4">
                {props.employee.structural_unit_repr}
              </p>
            </div>
            <div className="card-body">
              <h6 className="mb-4">Персональная информация</h6>
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Полное имя</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">{props.employee.full_name}</div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Дата рождения</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">
                    {props.employee.dob
                      ? formatDate(props.employee.dob, "d.m.y")
                      : "Не указано"}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Номер телефона</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">
                    {props.employee.phone ? props.employee.phone : "Не указано"}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Направления</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">
                    {skills?.length > 0 ? skills : "Не указано"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-3 mt-2">
          <div className="card">
            <div className="card-body">
              <ul>
                <li>
                  Задач в работе:{" "}
                  {props.employee?.statistic?.processing_tasks_count}
                </li>
                <li>
                  Задач приостановлено:{" "}
                  {props.employee?.statistic?.suspended_tasks_count}
                </li>
              </ul>
            </div>
          </div>

          <div className="card mt-2">
            <div className="card-body">
              <div className="row overflow-auto">{tasks}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const EmployeeCalendarTab = (props) => {
  return (
    <div className="card mt-2">
      <div className="card-body">
        <CalendarComponent
          eventModalShow={props.eventModalShow}
          handleEventShowModal={props.handleEventShowModal}
          hideAddButton={true}
          hideFilterField={true}
          modalIsLoading={props.modalIsLoading}
          event={props.event}
          events={props.events}
        />
      </div>
    </div>
  );
};
const Employee = (props) => {
  const [tab, setTab] = useState("info");
  const tabSwitcherOptions = [
    { value: "info", label: "Персональная информация" },
    { value: "calendar", label: "Календарь" },
  ].map((t, index) => (
    <button
      key={index}
      onClick={() => setTab(t.value)}
      className={`btn btn-sm ${tab === t.value && "btn-primary text-white"}`}
    >
      {t.label}
    </button>
  ));

  const getSelectedTab = () => {
    switch (tab) {
      case "info":
        return <EmployeeInfoTab employee={props.employee} />;
      case "calendar":
        return (
          <EmployeeCalendarTab
            eventModalShow={props.eventModalShow}
            handleEventShowModal={props.handleEventShowModal}
            hideAddButton={true}
            modalIsLoading={props.modalIsLoading}
            event={props.event}
            events={props.employee.events}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>Профиль {props.employee.full_name}</b>
      </div>
      <div className="page-header-toolbar overflow-auto">
        <div class="btn-group" role="group">
          {tabSwitcherOptions}
        </div>
      </div>
      {getSelectedTab()}
    </div>
  );
};

export default Employee;
