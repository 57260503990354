import React, { useState } from "react";
import Spinner from "../shared/Spinner";
import { NavLink } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { formatDate } from "../../utils/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TreeNode = ({ node, getUsersInDepartment, setStructName }) => {
  const { children, name, id } = node;
  const [showChildren, setShowChildren] = useState(false);
  const handleNodeClick = () => {
    setShowChildren(!showChildren);
    setStructName(name);
    if (!showChildren) {
      getUsersInDepartment(id);
    }
  };
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <i
          onClick={handleNodeClick}
          className={`text-primary mdi ${
            showChildren ? "mdi-minus" : "mdi-plus"
          }`}
        />
        <i className="text-primary mdi mdi-account-multiple me-2" />
        <button className="btn btn-link btn-sm p-0" onClick={handleNodeClick}>
          <span>{name}</span>
        </button>
      </div>
      <ul>
        <h5>
          {showChildren && (
            <Tree
              treeData={children}
              getUsersInDepartment={getUsersInDepartment}
              setStructName={setStructName}
            />
          )}
        </h5>
      </ul>
    </>
  );
};
const Tree = ({ treeData, getUsersInDepartment, setStructName }) => {
  if (!treeData) {
    return;
  }
  return (
    <ul>
      {treeData.map((node) => (
        <TreeNode
          node={node}
          key={node.id}
          getUsersInDepartment={getUsersInDepartment}
          setStructName={setStructName}
        />
      ))}
    </ul>
  );
};
const UserCard = ({ userId, user }) => {
  return (
    <div className="d-flex mt-3 py-2 border-bottom card-body">
      <img
        className="img-sm rounded-circle me-3"
        src={user.photo} //{`data:image/jpeg;base64,${user.photo}`}
        alt="profile"
      />
      <div className="wrapper me-2">
        <div className="font-weight-light">
          <NavLink
            to={
              userId === user.id ? "/profile" : `/employee/${user.id}/profile`
            }
          >
            {user.full_name}
          </NavLink>
        </div>
        <small className="text-muted">
          {user.connected ? "Сейчас на сайте" : "Офлайн"}
        </small>
      </div>
    </div>
  );
};
const LineChart = ({ labels, data, charType }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
    },
  };
  const chartData = {
    labels,
    datasets: [
      {
        label: "Количество задач",
        data: data,
        borderColor: "rgba(53, 162, 235, 0.5)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  switch (charType) {
    case "line":
      return <Line options={options} data={chartData} />;
    case "bar":
      return <Bar options={options} data={chartData} />;
    default:
      return null;
  }
};
const VacationCard = ({ vacation }) => {
  return (
    <div className="col-sm-4 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <b>
            {vacation.user.full_name}, {vacation.user.structural_unit_repr}
          </b>
          <div className="mt-2">
            <small>{vacation.user.position_repr}</small>
          </div>
          <div className="mt-2">
            <small>
              {formatDate(vacation.start, "d.m.y")} -{" "}
              {formatDate(vacation.finish, "d.m.y")}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};
const Department = (props) => {
  const vacations = props.usersVacations
    ? props.usersVacations.map((v) => <VacationCard key={v.id} vacation={v} />)
    : [];
  const [structName, setStructName] = useState("");
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>Подразделение</b>
      </div>
      <div className="col-lg-12 mb-3">
        <div className="page-header-toolbar mb-3">
          <div className="toolbar-item me-2 p-0">
            <button
              className="btn btn-primary btn-sm text-white"
              onClick={props.handleVacationScheduleFileDownload}
            >
              Экспорт графика отпусков
            </button>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h6 className="mb-4">Информация о подразделении</h6>
            <div className="mt-2">
              Наименование подразделения: <b>{props.detail.name}</b>
            </div>
            <div className="mt-2">
              Тип подразделения: <b>{props.detail.type_repr}</b>
            </div>
            <hr />
            <div className="mt-2">
              Среднее время завершения задач:{" "}
              <b>
                {props.detail.statistic
                  ? props.detail.statistic.task_finish_avg
                  : "Не указано"}
              </b>
            </div>
            <div className="mt-2">
              Всего задач на подразделение:{" "}
              <b>
                {props.detail.statistic
                  ? props.detail.statistic.tasks_count
                  : "Не указано"}
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-5 grid-margin stretch-card mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="mb-4">Иерархия подразделений</h6>
            <Tree
              treeData={props.treeData}
              getUsersInDepartment={props.getUsersInDepartment}
              setStructName={setStructName}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-7 grid-margin stretch-card mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="mb-4">
              Сотрудники подразделения <b>{structName}</b>
            </h6>
            {props.usersIsLoading ? (
              <Spinner />
            ) : props.users.length > 0 ? (
              <div
                className="row stretch-card overflow-auto"
                style={{ maxHeight: "300px" }}
              >
                {props.users.map((user) => (
                  <UserCard userId={props.userId} user={user} key={user.id} />
                ))}
              </div>
            ) : (
              <div className="px-2 mb-3">
                <i className="mdi mdi-alert-outline me-2 text-primary" />
                <span>В выбранном подразделении сотрудники отсутствуют</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h6 className="mb-4">Ближайший отпуск сотрудников</h6>
            <div
              className="row overflow-auto mt-3"
              style={{ maxHeight: "300px" }}
            >
              {vacations.length > 0 ? (
                vacations
              ) : (
                <div className="px-2 mb-3">
                  <i className="mdi mdi-alert-outline me-2 text-primary" />
                  <span>Ближайший отпуск сотрудников отсутствует</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 mb-3 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h6 className="mb-4">Количество задач по месяцам</h6>
            {props.detail.statistic ? (
              <LineChart
                labels={props.detail.statistic.line_chart_data_tasks.labels}
                data={props.detail.statistic.line_chart_data_tasks.data}
                charType="line"
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-lg-6 mb-3 grid-margin stretch-card">
        <div className="card">
          <div className="card-body overflow-auto">
            <h6 className="mb-4">Нагрузка на сотрудников</h6>
            {props.detail.statistic ? (
              <LineChart
                labels={props.detail.statistic.line_chart_data_users.labels}
                data={props.detail.statistic.line_chart_data_users.data}
                charType="bar"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Department;
