import { actions } from "../../actions/employees/EmployeesActions";
const initialState = {
  employee: {},
  isLoading: false,
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_EMPLOYEE:
      return {
        ...state,
        employee: action.data,
      };
    case actions.SET_EMPLOYEE_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
};
