import React, { useEffect } from "react";
import Notification from "./Notification";
import { connect } from "react-redux";
import {
  getUserDataTC,
  setAllNotifyRead,
  setNotifyRead,
} from "../../../redux/thunk/root/RootPageThunk";

const NotificationContainer = (props) => {
  useEffect(() => {
    props.getUserDataTC(false);
  }, []);
  return <Notification {...props} />;
};
const mapStateToProps = (state) => {
  return {
    notifications: state.rootPage.userData.notifications,
    awaitSetRead: state.rootPage.awaitSetRead,
  };
};

export default connect(mapStateToProps, {
  getUserDataTC,
  setNotifyRead,
  setAllNotifyRead,
})(NotificationContainer);
