import api from "../../../../utils/api/api_beta";
import {
  setIntroductionInitial,
  setIntroductionInitialIsLoading,
  setIntroductionParticipants,
  setIntroductionSaveInProcess,
} from "../../../actions/introductions/manage/IntroductionManageActions";
import { setAlertToast } from "../../../actions/root/RootPageActions";
import { handleErrors } from "../../HandleErrors";

export const getIntroductionInitial = (pk) => {
  return async (dispatch) => {
    dispatch(setIntroductionInitialIsLoading(true));
    try {
      const response = await api.get("api/introductions/initial-data", {
        params: { pk: pk },
      });
      response.data.participants = response.data.participants.map((p) => ({
        value: p.participant.id,
        label: p.participant.short_name,
      }));
      dispatch(setIntroductionInitial(response.data));
      dispatch(setIntroductionInitialIsLoading(false));
    } catch (e) {
      dispatch(setIntroductionInitialIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getParticipants = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("api/introductions/participants");
      dispatch(setIntroductionParticipants(response.data));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveIntroduction = (data, pk = null) => {
  return async (dispatch) => {
    dispatch(setIntroductionSaveInProcess(true));
    try {
      let response;
      let alertText;
      if (pk) {
        response = await api.put("api/introductions/edit/", data, {
          params: { pk: pk },
        });
        alertText = "Изменения сохранены";
      } else {
        response = await api.post("api/introductions/create/", data);
        alertText = "Опрос создан";
      }
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          alertText,
          "success",
          null,
          true
        )
      );
      dispatch(setIntroductionSaveInProcess(false));
      return response;
    } catch (e) {
      dispatch(setIntroductionSaveInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
