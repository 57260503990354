import { actions } from "../../actions/root/RootPageActions";

const initialState = {
  currentPath: "",
  alertToasts: [],
  notificationToasts: [],
  awaitSetRead: [],
  userData: {},
  globalSearchResults: {},
  currentNotification: {},
  notificationIsLoading: false,
  isFullPageLayout: false,
  authenticated: false,
  showProfileEditModal: false,
  showContactEditModal: false,
  profileIsLoading: false,
  globalSearchInProcess: false,
  updatePhotoInProcess: false,
  loginInProcess: false,
};

export const rootPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case actions.SET_ALERT_TOAST:
      return {
        ...state,
        alertToasts: action.add
          ? [
              ...state.alertToasts,
              {
                id: state.alertToasts.length + 1,
                title: action.title,
                body: action.body,
                level: action.level,
                icon: action.icon,
                url: action.url,
              },
            ]
          : state.alertToasts.filter((toast) => toast.id !== action.id),
      };
    case actions.SET_NOTIFICATION_TOAST:
      return {
        ...state,
        notificationToasts: action.add
          ? state.notificationToasts.push({
              id: state.notificationToasts.length + 1,
              title: action.title,
              body: action.body,
              level: action.level,
              icon: action.icon,
              url: action.url,
            })
          : state.notificationToasts.filter((toast) => toast.id !== action.id),
      };
    case actions.SET_NOTIFICATION_READ:
      return {
        ...state,
        currentNotification:
          Object.keys(state.currentNotification).length > 0
            ? {
                ...state.currentNotification,
                is_read: action.read,
              }
            : { ...state.currentNotification },
        userData: {
          ...state.userData,
          notifications: !action.all
            ? state.userData.notifications.map((n) =>
                n.id === action.id ? { ...n, is_read: action.read } : n
              )
            : state.userData.notifications.map((n) => ({
                ...n,
                is_read: action.read,
              })),
        },
      };
    case actions.SET_CURRENT_NOTIFICATION_IS_LOADING:
      return {
        ...state,
        notificationIsLoading: action.isLoading,
      };
    case actions.SET_CURRENT_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.data,
      };
    case actions.SET_READ_IN_PROCESS:
      return {
        ...state,
        awaitSetRead: !action.all
          ? action.add
            ? [...state.awaitSetRead, action.id]
            : state.awaitSetRead.filter((id) => id !== action.id)
          : action.add
          ? state.userData.notifications.map((n) => [
              ...state.awaitSetRead,
              n.id,
            ])
          : state.userData.notifications.map((n) =>
              state.awaitSetRead.filter((id) => id !== n.id)
            ),
      };
    case actions.SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };
    case actions.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.path,
      };
    case actions.SET_IS_FULL_PAGE_LAYOUT:
      return {
        ...state,
        isFullPageLayout: action.isFullPageLayout,
      };
    case actions.SET_USER_NOTIFICATIONS:
      return {
        ...state,
        userData: {
          ...state.userData,
          notifications: action.data,
        },
      };
    case actions.SET_SHOW_PROFILE_EDIT_MODAL:
      return {
        ...state,
        showProfileEditModal: action.show,
      };
    case actions.SET_SHOW_CONTACT_EDIT_MODAL:
      return {
        ...state,
        showContactEditModal: action.show,
      };
    case actions.SET_PROFILE_IS_LOADING:
      return {
        ...state,
        profileIsLoading: action.isLoading,
      };
    case actions.SET_GLOBAL_SEARCH_RESULTS:
      return {
        ...state,
        globalSearchResults: action.data,
      };
    case actions.SET_GLOBAL_SEARCH_IN_PROCESS:
      return {
        ...state,
        globalSearchInProcess: action.inProcess,
      };
    case actions.SET_PHOTO_UPDATE_IN_PROCESS:
      return {
        ...state,
        updatePhotoInProcess: action.inProcess,
      };
    case actions.SET_LOGIN_IN_PROCESS:
      return {
        ...state,
        loginInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
