import React from "react";
import TaskManageForm from "../form/TaskManageForm";

const TaskManage = (props) => {
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>{props.taskId ? "Радактирование задачи" : "Новая задача"}</b>
      </div>
      <div className="col-sm-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Основаная информация о задаче</h4>
            <TaskManageForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskManage;
