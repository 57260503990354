import React from "react";
import ReactHtmlParser from "react-html-parser";

const TaskHistoryTab = (props) => {
  const events = props.events.map((e) => (
    <div className="card mt-2" key={e.id}>
      <div className="card-body">
        <small>{ReactHtmlParser(e.diff_text)}</small>
      </div>
        <div className="card-footer">
        <small>Пользователь: {e.user.full_name}</small>
        </div>
    </div>
  ));
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title ">История изменений задачи</h4>
        <div className="mt-3">{events}</div>
      </div>
    </div>
  );
};
export default TaskHistoryTab;
