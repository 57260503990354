import React, { useRef } from "react";
import TaskParticipantModal from "./TaskParticipantModal";
import { NavLink } from "react-router-dom";

const TaskParticipantCard = (props) => {
  return (
    <div className="col-sm-4 stretch-card mb-3">
      <div className="card">
        <div className="d-flex mt-3 py-2 card-body mb-3">
          <NavLink
            to={
              props.userId === props.participant
                ? "/profile"
                : `/employee/${props.participant}/profile`
            }
            className="me-3"
          >
            <img
              className="img-sm rounded-circle"
              src={props.photo} //{`data:image/jpeg;base64,${props.photo}`}
              alt="profile"
            />
          </NavLink>
          <div className="wrapper me-2">
            <div className="font-weight-light">
              <small>{props.full_name}, {props.structural_unit}</small>
              <div className="text-muted">
                <small>{props.participant_role_repr}</small>
              </div>
            </div>
          </div>
        </div>
        {props.participant_role !== "initiator" ? (
          props.initiatorId === props.userId &&
          !["finished", "aborted"].includes(props.currentStatus) ? (
            <div className="card-footer d-flex justify-content-end">
              <button
                className="btn btn-sm btn-danger text-white"
                onClick={() =>
                  props.handleDeleteParticipantClick(
                    props.id,
                    props.participant_role === "executor" &&
                      props.participant_role
                  )
                }
              >
                {props.participantDeleteWaitList.some(
                  (id) => id === props.id
                ) ? (
                  <div
                    className="spinner-border text-white spinner-border-sm text-primary"
                    role="status"
                  />
                ) : (
                  <>
                    <i className="mdi mdi-delete" />
                  </>
                )}
              </button>
            </div>
          ) : null
        ) : null}
      </div>
    </div>
  );
};

const TaskParticipantTab = (props) => {
  const windowHeightRef = useRef(window.innerHeight);
  const overflowMaxHeight = `${Number(windowHeightRef?.current) * 0.63}px`;
  const participants = props.participants.map((p, index) => (
    <TaskParticipantCard
      key={index}
      userId={props.userId}
      initiatorId={props.initiatorId}
      currentStatus={props.currentStatus}
      participantDeleteWaitList={props.participantDeleteWaitList}
      handleDeleteParticipantClick={props.handleDeleteParticipantClick}
      {...p}
    />
  ));
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Участники задачи</h4>
        <div className="mt-3">
          {props.initiatorId ? (
            props.initiatorId === props.userId &&
            !["finished", "aborted"].includes(props.currentStatus) ? (
              <button
                type="button"
                onClick={() => props.handleParticipantModalShow(true)}
                className="btn btn-primary btn-sm text-white"
              >
                <i className="mdi mdi-plus me-2" />
                Добавить
              </button>
            ) : null
          ) : null}
          <TaskParticipantModal
            taskId={props.taskId}
            show={props.participantModalShow}
            userId={props.userId}
            executorId={props.executorId}
            modalIsLoading={props.modalIsLoading}
            availableParticipants={props.availableParticipants}
            participantAddWaitList={props.participantAddWaitList}
            handleSearchChange={props.handleSearchChange}
            handleParticipantModalShow={props.handleParticipantModalShow}
            handleParticipantAddClick={props.handleParticipantAddClick}
            handleChangeParticipantRole={props.handleChangeParticipantRole}
          />
          <div
            className="overflow-auto row mt-4"
            style={{ maxHeight: overflowMaxHeight }}
          >
            {participants}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskParticipantTab;
