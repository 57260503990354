export const actions = {
  SET_EMPLOYEE: "SET_EMPLOYEE",
  SET_EMPLOYEE_IS_LOADING: "SET_EMPLOYEE_IS_LOADING",
};

export const setEmployee = (data) => ({
  type: actions.SET_EMPLOYEE,
  data: data,
});
export const setEmployeeIsLoading = (isLoading) => ({
  type: actions.SET_EMPLOYEE_IS_LOADING,
  isLoading: isLoading,
});
