import React from "react";

const Paginator = (props) => {
  const pagesNumbers = [];
  const totalPagesCount = Math.ceil(props.totalCount / props.pageSize);
  const minPageNumber = props.pageNumber >= 5 ? props.pageNumber - 4 : 1;
  const maxPageNumber =
    props.pageNumber + 4 <= totalPagesCount
      ? props.pageNumber + 4
      : totalPagesCount;

  for (let i = minPageNumber; i <= maxPageNumber; i++) {
    pagesNumbers.push(i);
  }
  const buttons = pagesNumbers.map((n, index) => (
    <button
      type="button"
      className={`btn btn-sm ${
        props.pageNumber === n ? "btn-primary text-white" : ""
      }`}
      key={n}
      onClick={() => props.onClick(n)}
    >
      {n}
    </button>
  ));

  return <div>{buttons.length > 1 ? buttons : null}</div>;
};

export default Paginator;
