export const actions = {
  SET_DEPARTMENTS: "SET_DEPARTMENTS",
  SET_DEPARTMENT_USERS: "SET_DEPARTMENT_USERS",
  SET_DEPARTMENTS_IS_LOADING: "SET_DEPARTMENTS_IS_LOADING",
  SET_DEPARTMENT_USERS_IS_LOADING: "SET_DEPARTMENT_USERS_IS_LOADING",
};

export const setDepartments = (data) => ({
  type: actions.SET_DEPARTMENTS,
  data: data,
});
export const setDepartmentsIsLoading = (isLoading) => ({
  type: actions.SET_DEPARTMENTS_IS_LOADING,
  isLoading: isLoading,
});
export const setDepartmentUsers = (data) => ({
  type: actions.SET_DEPARTMENT_USERS,
  data: data,
});
export const setDepartmentUsersIsLoading = (isLoading) => ({
  type: actions.SET_DEPARTMENT_USERS_IS_LOADING,
  isLoading: isLoading,
});
