import React from "react";
import ProfileEditModal from "./ProfileEditModal";
import ContactEditModal from "./ContactEditModal";
import ContactCard from "./ContactCard";
import EventCard from "./EventCard";
import { NavLink } from "react-router-dom";
import { formatDate } from "../../utils/utils";

const Profile = (props) => {
  const contacts = props.userData.contacts
    ? props.userData.contacts.map((c, index) => (
        <ContactCard
          key={index}
          deleteUserContact={props.deleteUserContact}
          handleShowContactModal={props.handleShowContactModal}
          {...c}
        />
      ))
    : [];
  const events = props.userData.events
    ? props.userData.events.map((e) => <EventCard key={e.id} {...e} />)
    : [];
  const skills = props.userData.skills
    ? props.userData.skills
        .map((s) => {
          return s.name;
        })
        .join(", ")
    : "Направления не указаны";

  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>Профиль</b>
      </div>
      <ProfileEditModal
        show={props.showProfileEditModal}
        register={props.registerEdit}
        control={props.controlEdit}
        onSubmit={props.onSubmitEdit}
        handleSumbit={props.handleSubmitEdit}
        handleClose={props.handleShowEditModal}
      />

      <ContactEditModal
        show={props.showContactEditModal}
        register={props.registerContact}
        control={props.controlContact}
        onSubmit={props.onSubmitContact}
        handleSubmit={props.handleSubmitContact}
        handleClose={props.handleShowContactModal}
      />

      <div className="col-lg-5 mb-3">
        <div className="card">
          <div className="card-body text-center">
            <img
              src={props.userData.photo} //{`data:image/jpeg;base64,${props.userData.photo}`}
              alt="..."
              className="rounded-circle img-fluid"
              style={{ width: "150px", height: "150px" }}
            />
            <p className="text-muted mt-3 mb-1">
              {props.userData.position_repr}
            </p>
            <p className="text-muted mb-4">
              {props.userData.structural_unit_repr}
            </p>
            <div className="d-flex justify-content-center mb-2">
              <button
                className="btn btn-primary btn-sm text-white me-2"
                onClick={() => props.handleShowEditModal(true)}
              >
                Редактировать профиль
              </button>
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={props.handlePhotoSelectButton}
              >
                {props.updatePhotoInProcess ? (
                  <div className="spinner-wrapper">
                    <div
                      className="spinner-border spinner-border-sm text-white"
                      role="status"
                    />
                  </div>
                ) : (
                  "Изменить фотографию"
                )}
              </button>
              <input
                type="file"
                onChange={props.handlePhotoChange}
                ref={props.inputFile}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="card-body">
            <h6 className="mb-4">
              <b>Персональная информация</b>
            </h6>
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Полное имя</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">
                  {props.userData.first_name} {props.userData.middle_name}{" "}
                  {props.userData.last_name}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Дата рождения</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">
                  {props.userData.dob
                    ? formatDate(props.userData.dob, "d.m.y")
                    : "Не указано"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Номер телефона</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">
                  {props.userData.phone ? props.userData.phone : "Не указано"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Направления</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">{skills}</div>
              </div>
            </div>
            <hr />
            <h6 className="mt-4 mb-4">
              <b>Настройки приватности</b>
            </h6>
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Общедоступный профиль</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">
                  {props.userData.profile_is_visible ? "Да" : "Нет"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Номер телефона виден всем</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">
                  {props.userData.phone_is_visible ? "Да" : "Нет"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  <small>Дата рождения видна всем</small>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text">
                  {props.userData.dob_is_visible ? "Да" : "Нет"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-7">
        <div className="card mb-4">
          <div className="card-body">
            <h6 className="mb-3">Контактная информация</h6>
            <button
              className="btn btn-primary btn-sm text-white mb-2"
              onClick={() => props.handleShowContactModal(true)}
            >
              Добавить контакт
            </button>
            <div className="overflow-auto row" style={{ maxHeight: "250px" }}>
              {contacts.length > 0 ? (
                contacts
              ) : (
                <div className="py-2">
                  <i className="mdi mdi-alert-outline text-primary me-2" />
                  <span>Контктная информация отсутствует</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h6 className="mb-3">События</h6>
            <NavLink
              to="/calendar"
              className="btn btn-primary btn-sm text-white mb-2"
            >
              Перейти к календарю
            </NavLink>
            <div className="overflow-auto row" style={{ maxHeight: "250px" }}>
              {events.length > 0 ? (
                events
              ) : (
                <div className="py-2">
                  <i className="mdi mdi-alert-outline text-primary me-2" />
                  <span>Запланированные события отсутствуют</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h6 className="mb-4">Документы</h6>
            <div className="overflow-auto row" style={{ maxHeight: "250px" }}>
              <div className="py-2">
                <i className="mdi mdi-alert-outline text-primary me-2" />
                <span>Для вашей учетной записи не обнаружено документов</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
