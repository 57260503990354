import React from "react";

const EditingCommentMark = (props) => {
  return (
    <div className="alert alert-warning">
      <i className="mdi mdi-alert danger mx-2"></i>
      <span>Редактирование комментария</span>
      <button
        type="button"
        className="btn btn-sm mdi mdi-close"
        onClick={props.handleCommentEditCancelClick}
      />
    </div>
  );
};

export default EditingCommentMark;
