export const actions = {
  SET_TASK_DETAIL: "SET_TASK_DETAIL",
  SET_TASK_IS_LOADING: "SET_TASK_IS_LOADING",
  SET_TASK_HAS_ERRORS: "SET_TASK_HAS_ERRORS",
  SET_TASK_CURRENT_STATUS: "SET_TASK_CURRENT_STATUS",
  SET_TASK_ACCEPTANCE_STATUS: "SET_TASK_ACCEPTANCE_STATUS",
  SET_TASK_SOLUTION_IN_PROCESS: "SET_TASK_SOLUTION_IN_PROCESS",

  SET_PARTICIPANT_WAIT_ID: "SET_PARTICIPANT_WAIT_ID",
  SET_PARTICIPANT_MODAL_LOADING: "SET_PARTICIPANT_MODAL_LOADING",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_PARTICIPANT: "SET_PARTICIPANT",
  SET_EXECUTOR: "SET_EXECUTOR",
  DELETE_TASK_PARTICIPANT: "DELETE_TASK_PARTICIPANT",
  DELETE_TASK_PARTICIPANT_FROM_AVAILABLE:
    "DELETE_TASK_PARTICIPANT_FROM_AVAILABLE",

  SET_POST_COMMENT_IN_PROCESS: "SET_POST_COMMENT_IN_PROCESS",
  SET_COMMENT_LOADING: "SET_COMMENT_LOADING",
  SET_COMMENTS: "SET_COMMENTS",
  SET_COMMENT: "SET_COMMENT",
  SET_COMMENT_TEXT: "SET_COMMENT_TEXT",
  SET_COMMENT_AS_SOLUTION: "SET_COMMENT_AS_SOLUTION",
  ADD_COMMENT_FILE: "ADD_COMMENT_FILE",
  DELETE_COMMENT_FILE: "DELETE_COMMENT_FILE",
  DELETE_COMMENT: "DELETE_COMMENT",
};

export const setTaskHasErrors = (hasErrors) => {
  return {
    type: actions.SET_TASK_HAS_ERRORS,
    hasErrors: hasErrors,
  };
};
export const setTaskDetail = (data) => {
  return {
    type: actions.SET_TASK_DETAIL,
    data: data,
  };
};
export const setTaskIsLoading = (isLoading) => {
  return {
    type: actions.SET_TASK_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setTaskStatus = (status, repr) => {
  return {
    type: actions.SET_TASK_CURRENT_STATUS,
    status: status,
    repr: repr,
  };
};
export const setTaskAcceptanceStatus = (status) => {
  return {
    type: actions.SET_TASK_ACCEPTANCE_STATUS,
    status: status,
  };
};
export const setTaskSolutionInProcess = (inProcess) => {
  return {
    type: actions.SET_TASK_SOLUTION_IN_PROCESS,
    inProcess: inProcess
  }
}
export const setParticipants = (data, target) => {
  return {
    type: actions.SET_PARTICIPANTS,
    data: data,
    target: target,
  };
};
export const setParticipant = (data) => {
  return {
    type: actions.SET_PARTICIPANT,
    data: data,
  };
};
export const setExecutor = (add, data) => {
  return {
    type: actions.SET_EXECUTOR,
    add: add,
    data: data,
  };
};
export const setParticipantWaitId = (id, add, target) => {
  return {
    type: actions.SET_PARTICIPANT_WAIT_ID,
    id: id,
    add: add,
    target: target,
  };
};
export const setParticipantModalIsLoading = (isLoading) => {
  return {
    type: actions.SET_PARTICIPANT_MODAL_LOADING,
    isLoading: isLoading,
  };
};
export const deleteParticipant = (id, target) => {
  return {
    type: actions.DELETE_TASK_PARTICIPANT,
    id: id,
    target: target,
  };
};
export const setComments = (data) => {
  return {
    type: actions.SET_COMMENTS,
    data: data,
  };
};
export const setComment = (data) => {
  return {
    type: actions.SET_COMMENT,
    data: data,
  };
};
export const deleteComment = (id, text) => {
  return {
    type: actions.DELETE_COMMENT,
    id: id,
    text: text,
  };
};
export const setCommentText = (text) => {
  return {
    type: actions.SET_COMMENT_TEXT,
    text: text,
  };
};
export const setCommentAsSolution = (id, isSolution) => {
  return {
    type: actions.SET_COMMENT_AS_SOLUTION,
    id: id,
    isSolution: isSolution,
  };
};
export const addCommentFile = (file) => {
  return {
    type: actions.ADD_COMMENT_FILE,
    file: file,
  };
};
export const deleteCommentFile = (id, target) => {
  return {
    type: actions.DELETE_COMMENT_FILE,
    id: id,
    target: target,
  };
};
export const setCommentPostInProcess = (inProcess) => {
  return {
    type: actions.SET_POST_COMMENT_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setCommentLoading = (isLoading) => {
  return {
    type: actions.SET_COMMENT_LOADING,
    isLoading: isLoading,
  };
};
