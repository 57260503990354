import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Spinner from "../../shared/Spinner";
import { useEffect } from "react";

const WidgetManageModal = (props) => {
  const [rows, setRows] = useState([
    {
      field_name: "name",
      lookup_expr: "startswith",
      value: "",
      exclude: false,
    },
  ]);
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("mdi mdi-account");
  const [color, setColor] = useState("primary");
  const [isActive, setIsActive] = useState(true);

  const iconOptions = [
    {
      value: "mdi mdi-account",
      label: (
        <>
          <i className="mdi mdi-account" />
        </>
      ),
    },
    {
      value: "mdi mdi-alert",
      label: (
        <>
          <i className="mdi mdi-alert" />
        </>
      ),
    },
    {
      value: "mdi mdi mdi-eye",
      label: (
        <>
          <i className="mdi mdi-eye" />
        </>
      ),
    },
  ];

  const colorOptions = [
    {
      value: "primary",
      label: (
        <button className="btn btn-sm btn-primary text-white p-1">Синий</button>
      ),
    },
    {
      value: "success",
      label: (
        <button className="btn btn-sm btn-success text-white p-1">
          Зеленый
        </button>
      ),
    },
    {
      value: "info",
      label: (
        <button className="btn btn-sm btn-info text-white p-1">
          Фиолетовый
        </button>
      ),
    },
    {
      value: "warning",
      label: (
        <button className="btn btn-sm btn-warning text-white p-1">
          Желтый
        </button>
      ),
    },
    {
      value: "danger",
      label: (
        <button className="btn btn-sm btn-danger text-white p-1">
          Красный
        </button>
      ),
    },
  ];

  const fieldOptions = [
    { value: "name", label: "Наименование задачи" },
    { value: "description", label: "Описание задачи" },
    { value: "current_status", label: "Текущий статус задачи" },
    { value: "priority", label: "Приоритет задачи" },
    { value: "taskparticipant__participant", label: "Участник задачи" },
    {
      value: "taskparticipant__participant_role",
      label: "Роль участника задачи",
    },
  ].map((f, index) => (
    <option key={index} value={f.value}>
      {f.label}
    </option>
  ));

  const currentStatusOptions = [
    { value: "processing", label: "В работе" },
    { value: "suspended", label: "Приостановлена" },
    { value: "created", label: "Создана" },
    { value: "await_initiator_check", label: "Ожидается проверка инициатором" },
    { value: "finished", label: "Завершена" },
    { value: "aborted", label: "Отменена" },
  ];

  const participantRoleOptions = [
    { value: "initiator", label: "Инциатор" },
    { value: "executor", label: "Исполнитель" },
    { value: "coexecutor", label: "Соисполнитель" },
    { value: "auditor", label: "Аудитор" },
  ];

  const priorityOptions = [
    { value: "1", label: "Критический" },
    { value: "2", label: "Высокий" },
    { value: "3", label: "Средний" },
    { value: "4", label: "Низкий" },
  ];

  const userOptions = props.users.map((u) => ({
    value: u.id,
    label: u.full_name,
  }));

  const textFieldExprOptions = [
    {
      value: "",
      label: "",
    },
    { value: "startswith", label: "Начинается с" },
    { value: "endstwith", label: "Заканчивается на" },
    { value: "icontains", label: "Содержит" },
    { value: "exact", label: "Точное совпадение" },
  ].map((o, index) => (
    <option key={index} value={o.value}>
      {o.label}
    </option>
  ));

  const selectFieldExprOptions = [
    { value: "", label: "" },
    { value: "exact", label: "Точное совпадение" },
  ].map((o, index) => (
    <option key={index} value={o.value}>
      {o.label}
    </option>
  ));

  const predicateChoices = {
    name: textFieldExprOptions,
    description: textFieldExprOptions,
    current_status: selectFieldExprOptions,
    priority: selectFieldExprOptions,
    taskparticipant__participant: selectFieldExprOptions,
    taskparticipant__participant_role: selectFieldExprOptions,
  };

  const getControl = (index) => {
    const valueChoices = {
      name: (
        <input
          type="text"
          onChange={(e) => handleFieldChange(index, "value", e.target.value)}
          className="form-control form-control-sm"
          value={getValue(index, "value")}
        />
      ),
      description: (
        <input
          type="text"
          onChange={(e) => handleFieldChange(index, "value", e.target.value)}
          className="form-control form-control-sm"
          value={getValue(index, "value")}
        />
      ),

      current_status: (
        <Select
          isMulti={getValue(index, "lookup_expr") === "in"}
          isClearable={true}
          name="value"
          onChange={(e) => handleFieldChange(index, "value", e, true)}
          value={currentStatusOptions.find(
            (o) => o.value === getValue(index, "value")
          )}
          options={currentStatusOptions}
        />
      ),
      priority: (
        <Select
          isMulti={getValue(index, "lookup_expr") === "in"}
          isClearable={true}
          name="value"
          onChange={(e) => handleFieldChange(index, "value", e, true)}
          value={priorityOptions.find(
            (o) => o.value === getValue(index, "value")
          )}
          options={priorityOptions}
        />
      ),
      taskparticipant__participant: (
        <Select
          isMulti={getValue(index, "lookup_expr") === "in"}
          isClearable={true}
          name="value"
          onChange={(e) => handleFieldChange(index, "value", e, true)}
          value={userOptions.find((o) => o.value === getValue(index, "value"))}
          options={userOptions}
        />
      ),
      taskparticipant__participant_role: (
        <Select
          isMulti={getValue(index, "lookup_expr") === "in"}
          isClearable={true}
          name="value"
          onChange={(e) => handleFieldChange(index, "value", e, true)}
          value={participantRoleOptions.find(
            (o) => o.value === getValue(index, "value")
          )}
          options={participantRoleOptions}
        />
      ),
    };
    return valueChoices[getValue(index, "field_name")];
  };
  const getValue = (index, name) => {
    return rows[index][name];
  };
  const handleFieldChange = (index, name, value, isSelect = false) => {
    let data = [...rows];
    if (isSelect) {
      data[index][name] = Array.isArray(value)
        ? value.map((v) => v.value)
        : value
        ? value.value
        : null;
    } else {
      data[index][name] = value;
    }
    if (name === "field_name") {
      data[index].lookup_expr = "";
      data[index].value = "";
    }
    setRows(data);
  };

  // Управление строками
  const handleAddNewRow = () => {
    setRows([
      ...rows,
      {
        field_name: "name",
        lookup_expr: "startswith",
        value: "",
        exclude: false,
      },
    ]);
  };
  const handleDeleteRow = (index) => {
    let data = [...rows];
    data.splice(index, 1);
    setRows(data);
  };
  useEffect(() => {
    if (Object.keys(props.data).length > 0) {
      setName(props.data.name);
      setIcon(props.data.icon);
      setColor(props.data.color);
      setIsActive(props.data.is_active);
      setRows(props.data.query);
    } else {
      setName("");
      setIcon("mdi mdi-account");
      setColor("primary");
      setIsActive(true);
      setRows([
        {
          field_name: "name",
          lookup_expr: "startswith",
          value: "",
          exclude: false,
        },
      ]);
    }
  }, [props.data]);
  return (
    <Modal
      show={props.show}
      centered={true}
      size="lg"
      scrollable={true}
      onHide={props.onHide}
      style={{ minHeight: "450px" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {Object.keys(props.data).length > 0
            ? "Редактирование виджета"
            : "Новый виджет"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.widgetIsLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Основная информация о виджете</h5>
                <div className="row">
                  <div className="col-md-3">
                    <label>
                      <small>Наименование виджета</small>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>
                      <small>Значок</small>
                    </label>
                    <Select
                      onChange={(e) => setIcon(e ? e.value : "")}
                      value={iconOptions.find((i) => i.value === icon)}
                      options={iconOptions}
                      isClearable
                    />
                  </div>
                  <div className="col-md-3">
                    <label>
                      <small>Цвет</small>
                    </label>
                    <Select
                      onChange={(e) => setColor(e ? e.value : "")}
                      value={colorOptions.find((c) => c.value === color)}
                      options={colorOptions}
                      isClearable
                    />
                  </div>
                  <div className="col-md-3">
                    <label>
                      <small>Отображать на странице</small>
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={(e) => setIsActive(e.target.checked)}
                      checked={isActive}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-2">
              <div className="card-body">
                <h5 className="card-title">Выражение виджета</h5>
                <button
                  className="btn btn-sm btn-primary text-white mb-3"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddNewRow();
                  }}
                >
                  Добавить строку
                </button>
                {rows.map((r, index) => (
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5 className="card-title">
                        <small>
                          <b>Условие {index + 1}</b>
                        </small>
                      </h5>
                      <div className="row">
                        <div className="col-md-3">
                          <label>
                            <small>Поле фильтрации</small>
                          </label>
                          <hr />
                          <select
                            className="form-control form-control-sm"
                            name="field_name"
                            value={r.field_name}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "field_name",
                                e.target.value
                              )
                            }
                          >
                            {fieldOptions}
                          </select>
                        </div>
                        <div className="col-md-3">
                          <label>
                            <small>Условие поиска</small>
                          </label>
                          <hr />
                          <select
                            className="form-control form-control-sm"
                            name="lookup_expr"
                            value={r.lookup_expr}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "lookup_expr",
                                e.target.value
                              )
                            }
                          >
                            {predicateChoices[getValue(index, "field_name")]}
                          </select>
                        </div>
                        <div className="col-md-2">
                          <label>
                            <small>Условие не равно</small>
                          </label>
                          <hr />
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="exclude"
                            checked={r.exclude}
                            value={r.exclude}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "exclude",
                                e.target.checked
                              )
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <label>
                            <small>Значение</small>
                          </label>
                          <hr />
                          {getControl(index)}
                        </div>
                        <div className="col-md-1">
                          <label>
                            <small></small>
                          </label>
                          <hr />
                          <button
                            className="btn btn-sm btn-danger text-white mt-1"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteRow(index);
                            }}
                          >
                            <i className="mdi mdi-delete" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!props.wdigetIsLoading && (
          <button
            className="btn btn-success btn-sm text-white"
            disabled={props.widgetSaveInProcess}
            onClick={(e) => {
              e.preventDefault();
              props.saveWidget(
                name,
                "task",
                icon,
                color,
                isActive,
                rows,
                props.data?.id
              );
            }}
          >
            {props.widgetSaveInProcess ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              />
            ) : (
              "Сохранить изменения"
            )}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default WidgetManageModal;
