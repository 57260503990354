export const actions = {
  SET_TASK_INITIAL: "SET_TASK_INITIAL",
  SET_INITIAL_IS_LOADING: "SET_INITIAL_IS_LOADING",
  SET_INITIAL_HAS_ERRORS: "SET_INITIAL_HAS_ERRORS",
  SET_TASK_PROJECTS: "SET_TASK_PROJECTS",
  SET_TASK_PARENTS: "SET_TASK_PARENTS",
  SET_TASK_PARTICIPANTS: "SET_TASK_PARTICIPANTS",
  SET_TASK_CREATE_IN_PROCESS: "SET_TASK_CREATE_IN_PROCESS",
};

export const setTaskInitialData = (data) => {
  return {
    type: actions.SET_TASK_INITIAL,
    data: data,
  };
};
export const setTaskInitialIsLoading = (isLoading) => {
  return {
    type: actions.SET_INITIAL_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setInitialHasErrors = (hasErrors) => {
  return {
    type: actions.SET_INITIAL_HAS_ERRORS,
    hasErrors: hasErrors,
  };
};
export const setTaskProjectsAC = (data) => {
  return {
    type: actions.SET_TASK_PROJECTS,
    data: data,
  };
};
export const setTaskParentsAC = (data) => {
  return {
    type: actions.SET_TASK_PARENTS,
    data: data,
  };
};
export const setTaskParticipantsAC = (data) => {
  return {
    type: actions.SET_TASK_PARTICIPANTS,
    data: data,
  };
};
export const setTaskCreateInProcessAC = (inProcess) => {
  return {
    type: actions.SET_TASK_CREATE_IN_PROCESS,
    inProcess: inProcess,
  };
};
