import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

const NotificationCard = (props) => {
  return (
    <div className="col-sm-4 grid-margin stretch-card mt-1">
      <div className="card">
        <div className="card-body py-2">
          <div className="d-flex justify-content-between">
            <div>
              <i className={`${props.icon_name} me-2 text-primary icon-sm`} />
              <small>{props.title}</small>
            </div>
          </div>
        </div>
        <div className="card-footer">
          <NavLink
            to={`/notifications/${props.id}/detail`}
            className="btn btn-primary btn-sm text-white me-2 mt-1"
          >
            Перейти к уведомлению
          </NavLink>
          <button
            type="button"
            className={`btn ${
              props.is_read ? "btn-danger" : "btn-success"
            } btn-sm text-white mt-1`}
            disabled={props.awaitSetRead.some((id) => id === props.id)}
            onClick={() => props.setNotifyRead(props.id, !props.is_read)}
          >
            {props.awaitSetRead.some((id) => id === props.id) ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              />
            ) : (
              <i
                className={
                  props.is_read ? "mdi mdi-close" : "mdi mdi-check-all"
                }
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const Notification = (props) => {
  const [tab, setTab] = useState("unread");
  const getNotifications = (condition) => {
    const filtered = props.notifications
      ? condition
        ? props.notifications.filter(
            (obj) => obj[condition.field] === condition.value
          )
        : props.notifications
      : [];
    return filtered.map((n) => (
      <NotificationCard
        {...n}
        key={n.id}
        awaitSetRead={props.awaitSetRead}
        setNotifyRead={props.setNotifyRead}
      />
    ));
  };
  const tabSwitcherOptions = [
    { value: "unread", label: "Непрочитанные уведомления" },
    { value: "all", label: "Все уведомления" },
    {
      value: "task_new_comment_alert",
      label: "Уведомления комментариев задач",
    },
    { value: "task_status_alert", label: "Уведомления изменения статусов" },
  ].map((t, index) => (
    <button
      key={index}
      type="button"
      className={`btn btn-sm ${
        t.value === tab ? "btn-primary text-white" : ""
      }`}
      onClick={() => setTab(t.value)}
    >
      {t.label}
    </button>
  ));
  const getCurrentTab = () => {
    switch (tab) {
      case "unread":
        return getNotifications({
          field: "is_read",
          value: false,
        });
      case "all":
        return getNotifications();
      default:
        return getNotifications({
          field: "type",
          value: tab,
        });
    }
  };
  const tabContent = getCurrentTab();
  return (
    <>
      <div className="row page-title-header">
        <div className="page-header">
          <b>Уведомления</b>
        </div>
        <div className="row">
          <div className="page-header-toolbar overflow-auto">
            <div className="btn-group" role="group">
              {tabSwitcherOptions}
            </div>
          </div>
          <div className="page-header-toolbar overflow-auto mt-4">
            <div className="toolbar-item me-2 p-0">
              <div className="btn-group" role="group">
                <button
                  type="button"
                  className="btn btn-warning btn-sm text-white me-2"
                  onClick={() => props.setAllNotifyRead(true)}
                >
                  Прочитать все уведомления
                </button>
                <b>Уведомлений: {tabContent.length}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row overflow-auto mt-3">
        {tabContent.length > 0 ? (
          tabContent
        ) : (
          <span>Уведомления отсутствуют</span>
        )}
      </div>
    </>
  );
};

export default Notification;
