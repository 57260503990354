import React, { useEffect } from "react";
import TaskList from "./TaskList";
import { connect } from "react-redux";
import Spinner from "../../shared/Spinner";
import {
  getTasksTC,
  getWidget,
  getWidgetUsers,
  saveWidget,
} from "../../../redux/thunk/tasks/list/TaskListThunk";
import {
  setTasksFiltersAC,
  setTasksOrderingsAC,
  setShowWidgetModal,
  setWidgetData,
} from "../../../redux/actions/tasks/list/TaskListActions";

const TaskContainer = (props) => {
  const onPageChange = (number) => {
    props.setTasksFiltersAC({
      ...props.filters,
      page_number: number,
    });
    props.getTasksTC();
  };
  const onPageSizeChange = (e) => {
    props.setTasksFiltersAC({
      ...props.filters,
      page_size: Number(e.target.value),
    });
    props.getTasksTC();
  };
  //Функция возможно не нужна, уточнить необходимость поиска
  const onSearchChange = (e) => {
    props.setTasksFiltersAC({
      ...props.filters,
      page_size: Number(e.target.value),
    });
    if (e.key === "Enter") {
      props.getTasksTC();
    }
  };
  const onRoleFilterClick = (e) => {
    props.setTasksFiltersAC({
      ...props.filters,
      role: e.target.value === "all" ? null : e.target.value,
    });
    props.getTasksTC();
  };
  const handleWidgetClick = (pk) => {
    props.setTasksFiltersAC({
      page_size: props.filters.page_size,
      page_number: 1,
      widget_id: pk,
    });
    props.getTasksTC();
  }
  const handleFiltersReset = () => {
    props.setTasksFiltersAC({
      page_size: 10,
      page_number: 1,
    });
    props.getTasksTC();
  }
  const onOrderClick = (e) => {
    props.setTasksFiltersAC({
      ...props.filters,
      order_by: e.target.value === "all" ? null : e.target.value,
    });
    props.getTasksTC();
  };
  const handleWidgetEditClick = (pk) => {
    props.getWidget(pk);
    props.setShowWidgetModal(true);
  };
  useEffect(() => {
    props.getTasksTC();
    props.getWidgetUsers();
  }, []);
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <TaskList
      onRoleFilterClick={onRoleFilterClick}
      onSearchChange={onSearchChange}
      onOrderClick={onOrderClick}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      handleWidgetEditClick={handleWidgetEditClick}
      handleWidgetClick={handleWidgetClick}
      handleFiltersReset={handleFiltersReset}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.rootPage.userData.id,
    tasks: state.tasksPage.tasks,
    widgets: state.tasksPage.widgets,
    users: state.tasksPage.users,
    showWidgetModal: state.tasksPage.showWidgetModal,
    isLoading: state.tasksPage.isLoading,
    widgetIsLoading: state.tasksPage.widgetIsLoading,
    widgetSaveInProcess: state.tasksPage.widgetSaveInProcess,
    errors: state.tasksPage.errors,
    widgetData: state.tasksPage.widgetData,
    pageNumber: state.tasksPage.pageNumber,
    pageSize: state.tasksPage.pageSize,
    filters: state.tasksPage.filters,
    orderBy: state.tasksPage.orderBy,
    count: state.tasksPage.count,
    countRepr: state.tasksPage.countRepr,
  };
};
export default connect(mapStateToProps, {
  setTasksFiltersAC,
  setTasksOrderingsAC,
  setShowWidgetModal,
  setWidgetData,
  getTasksTC,
  getWidget,
  getWidgetUsers,
  saveWidget,
})(TaskContainer);
