import React from "react";
import Login from "./Login";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { login } from "../../redux/thunk/root/RootPageThunk";
import { setAlertToast } from "../../redux/actions/root/RootPageActions";
import { useLocation, useNavigate } from "react-router-dom";

const LoginContainer = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: {} });
  const navigate = useNavigate();
  const location = useLocation();
  const onSubmit = (data) => {
    props
      .login(data)
      .then((res) =>
        navigate(
          `${location.pathname}${location.search ? location.search : ""}`
        )
      );
  };
  return (
    <Login
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    alerts: state.rootPage.alertToasts,
    authenticated: state.rootPage.authenticated,
    loginInProcess: state.rootPage.loginInProcess,
  };
};

export default connect(mapStateToProps, {
  setAlertToast,
  login,
})(LoginContainer);
