import React from "react";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";

const VacationCard = (props) => {
  return (
    <div className="col-lg-4 grid-margin stretch-card">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            {moment(props.start).local().format("DD.MM.YYYY")} -{" "}
            {moment(props.finish).local().format("DD.MM.YYYY")}
          </h4>
          <small>
            <p>
              Продолжительность: <b>{props.length_repr}</b>
            </p>
          </small>
          {props.completed ? (
            <small>
              <p>Отпуск завершен</p>
            </small>
          ) : null}
        </div>
        {!props.completed ? (
          <div className="card-footer">
            <NavLink
              to={`/vacations/${props.id}/edit`}
              className="btn btn-primary btn-sm text-white"
            >
              Редактировать
            </NavLink>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const Vacation = (props) => {
  const vacations = props.vacations
    ? props.vacations.map((v) => <VacationCard key={v.id} {...v} />)
    : [];
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>Отпуск</b>
      </div>
      <div className="col-md-12 mb-3">
        <div className="page-header-toolbar">
          <div className="toolbar-item me-2 p-0">
            <NavLink
              to="/vacations/create"
              className="btn btn-primary btn-sm text-white"
            >
              Новая запись отпуска
            </NavLink>
          </div>
          <div className="toolbar-item me-2 p-0">
            <b>
              Осталось {props.vacationDaysRemain} отпуска в{" "}
              {new Date().getFullYear()} году
            </b>
          </div>
        </div>
      </div>

      <div className="row overflow-auto">
        {vacations}
      </div>
    </div>
  );
};

export default Vacation;
