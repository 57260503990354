import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ProjectCard from "./ProjectCard";
import Paginator from "../../shared/Paginator";

const ProjectList = (props) => {
  const [tab, setTab] = useState("active");
  const tabSwitcherOptions = [
    { value: "active", label: "Активные задачи" },
    { value: "finished", label: "Завершенные задачи" },
  ].map((t, index) => (
    <button
      className={`btn btn-sm${
        tab === t.value ? " btn-primary text-white" : ""
      }`}
      key={index}
      onClick={() => setTab(t.value)}
    >
      {t.label}
    </button>
  ));
  const orderOptions = [
    { value: "all", label: "Без сортировки" },
    { value: "-created_at", label: "Сначала новые" },
    { value: "created_at", label: "Сначала старые" },
  ].map((order, index) => (
    <option key={index} value={order.value}>
      {order.label}
    </option>
  ));
  const pageSizeOptions = [5, 10, 15, 25, 30, 35].map((size, index) => (
    <option key={index} value={size}>
      {size}
    </option>
  ));
  const projects = props.projects
    .filter((p) => p.all_tasks_completed != (tab === "active"))
    .map((p) => <ProjectCard key={p.id} {...p} />);
  return (
    <div>
      <div className="row page-title-header">
        <div className="page-header">
          <b>Проекты</b>
        </div>
        <div className="col-md-12">
          <div className="page-header-toolbar">
            <div className="toolbar-item me-2 p-0">
              <select
                onChange={props.handleOrderingChange}
                value={
                  props.filters.hasOwnProperty("order_by")
                    ? props.filters.order_by
                    : null
                }
                className="form-control form-control-sm"
              >
                {orderOptions}
              </select>
            </div>
            <div className="toolbar-item me-2 p-0">
              <select
                onChange={props.handlePageSizeChange}
                value={props.filters.page_size}
                className="form-control form-control-sm"
              >
                {pageSizeOptions}
              </select>
            </div>
            <div className="toolbar-item me-2 p-0">
              <span>{props.countRepr}</span>
            </div>
            <div className="toolbar-item me-2 p-0">
              <NavLink
                to="/projects/create"
                className="btn btn-primary btn-sm text-white"
              >
                Новый проект
              </NavLink>
            </div>
            <div className="toolbar-item me-2 p-0">
              <Paginator
                pageNumber={props.pageNumber}
                pageSize={props.pageSize}
                totalCount={props.count}
                onClick={props.handlePageNumberChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4 mb-3">
        <div class="btn-group" role="group">
          {tabSwitcherOptions}
        </div>
      </div>
      <div className="row overflow-auto">
        {projects.length > 0 ? (
          projects
        ) : (
          <div className="px-4 mb-3">
            <i className="mdi mdi-alert-outline me-2 text-primary" />
            <span>По заданным критериям проектов не найдено</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
