import React, { useEffect, useState } from "react";
import TaskDetail from "./TaskDetail";
import { connect } from "react-redux";
import {
  addParticipant,
  changeAcceptanceStatus,
  changeTaskStatus,
  deleteComment,
  deleteParticipant,
  getAvailableParticipants,
  getTaskComment,
  getTaskCommentFile,
  getTaskComments,
  getTaskCurrentStatus,
  getTaskDetail,
  sendComment,
  setTaskSolution,
} from "../../../redux/thunk/tasks/detail/TaskDetailThunk";
import { useParams, useSearchParams } from "react-router-dom";
import {
  addCommentFile,
  deleteCommentFile,
  setComment,
  setCommentText,
  setParticipantWaitId,
} from "../../../redux/actions/tasks/detail/TaskDetailActions";
import Spinner from "../../shared/Spinner";
import { setCurrentPathAC } from "../../../redux/actions/root/RootPageActions";
import useWebSocket from "react-use-websocket";
import { wsReconnectAttempts, wsReconnectDelay, wsUrl } from "../../../config";
import Error from "../../shared/Error";
import fileDownload from "js-file-download";
import { getUserAvatar } from "../../../redux/thunk/root/RootPageThunk";
import useScreenType from "react-screentype-hook";

const TaskDetailContainer = (props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const [participantModalShow, setParticipantModalShow] = useState(false);
  const [solutionsModalShow, setSolutionsModalShow] = useState(false);
  const screenType = useScreenType();
  const { lastMessage } = useWebSocket(wsUrl, {
    shouldReconnect: (closeEvent) => true,
    reconnectInterval: wsReconnectDelay,
    reconnectAttempts: wsReconnectAttempts,
    share: true,
  });

  // Хандлер события нажатия кнопки отмены редактирования
  // комментария
  const handleCommentEditCancelClick = () => {
    props.setComment({
      id: null,
      text: null,
      files: [],
      filesToAdd: [],
      filesToDelete: [],
      edit: false,
    });
  };
  const handleFileAttached = (e) => {
    props.addCommentFile(e.target.files[0]);
  };
  const handleFileDeleteClick = (id, target) => {
    props.deleteCommentFile(id, target);
  };
  const handleCommentEditClick = (id) => {
    props.getTaskComment(id, true);
  };
  const handeCommentFileDownload = (id, filename) => {
    props
      .getTaskCommentFile(id)
      .then((res) => fileDownload(res.data, filename));
  };
  const handleParticipantModalShow = (show) => {
    if (show) {
      props.getAvailableParticipants();
    }
    setParticipantModalShow(show);
  };
  const handleSolutionsModalShow = (show) => {
    if (show) {
      props.getTaskComments();
    }
    setSolutionsModalShow(show);
  };
  const handleSetTaskSolution = (solution, comments) => {
    props.setTaskSolution(solution, comments).then(res => {
      if (res && res.status === 200) {
        setSolutionsModalShow(false)
      } 
    })
  }
  const handleParticipantAddClick = (id, role) => {
    props.addParticipant(id, role);
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      const message = JSON.parse(data.message);
      if (message.notification_type === "task_new_comment_alert") {
        props.getTaskComments();
      }
      if (message.notification_type === "task_status_alert") {
        props.getTaskCurrentStatus();
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    props.getTaskDetail(params.id);
    props.setCurrentPathAC("tasks/detail");
  }, [params.id]);

  if (
    (props.isLoading || Object.keys(props.task).length === 0) &&
    !props.hasErrors
  ) {
    return <Spinner />;
  }

  if (props.hasErrors) {
    return <Error />;
  }

  return (
    <TaskDetail
      tab={searchParams.get("tab")}
      isMobile={screenType.isMobile}
      participantModalShow={participantModalShow}
      handleParticipantModalShow={handleParticipantModalShow}
      handleParticipantAddClick={handleParticipantAddClick}
      solutionsModalShow={solutionsModalShow}
      handleSolutionsModalShow={handleSolutionsModalShow}
      handleSetTaskSolution={handleSetTaskSolution}
      handleFileAttached={handleFileAttached}
      handleFileDeleteClick={handleFileDeleteClick}
      handleCommentEditClick={handleCommentEditClick}
      handleCommentEditCancelClick={handleCommentEditCancelClick}
      handeCommentFileDownload={handeCommentFileDownload}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    task: state.tasksDetailPage.task,
    isLoading: state.tasksDetailPage.isLoading,
    modalIsLoading: state.tasksDetailPage.modalIsLoading,
    commentIsLoading: state.tasksDetailPage.commentIsLoading,
    hasErrors: state.tasksDetailPage.hasErrors,
    solutionSetInProcess: state.tasksDetailPage.solutionSetInProcess,
    availableParticipants: state.tasksDetailPage.availableParticipants,
    participantAddWaitList: state.tasksDetailPage.participantAddWaitList,
    participantDeleteWaitList: state.tasksDetailPage.participantDeleteWaitList,
    postCommentInProcess: state.tasksDetailPage.postCommentInProcess,
    currentComment: state.tasksDetailPage.currentComment,
    projects: state.tasksDetailPage.projects,
    parents: state.tasksDetailPage.parents,
    userData: state.rootPage.userData,
  };
};
const mapDispatchToProps = {
  setCurrentPathAC,
  getTaskDetail,
  getTaskCurrentStatus,
  changeTaskStatus,
  changeAcceptanceStatus,
  getTaskComment,
  getTaskComments,
  getTaskCommentFile,
  sendComment,
  deleteComment,
  getAvailableParticipants,
  addParticipant,
  deleteParticipant,
  setParticipantWaitId,
  setComment,
  setCommentText,
  setTaskSolution,
  addCommentFile,
  deleteCommentFile,
  getUserAvatar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskDetailContainer);
