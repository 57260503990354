import thunkMiddleware from "redux-thunk";

import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import { rootPageReducer } from "./reducers/root/RootPageReducer";
import { tasksListReducer } from "./reducers/tasks/list/TaskListReducer";
import { taskDetailReducer } from "./reducers/tasks/detail/TaskDetailReducer";
import { taskManageReducer } from "./reducers/tasks/manage/TaskManageReducer";
import { calendarReducer } from "./reducers/calendar/CalendarReducer";
import { projectListReducer } from "./reducers/projects/list/ProjectListReducer";
import { projectDetailReducer } from "./reducers/projects/detail/ProjectDetailReducer";
import { departmentReducer } from "./reducers/departments/DepartmentReducer";
import { employeeReducer } from "./reducers/employees/EmployeeReducer";
import { vacationReducer } from "./reducers/vacations/VacationReducer";
import { introductionListReducer } from "./reducers/introductions/list/IntroductionListReducer";
import { introductionDetailReducer } from "./reducers/introductions/detail/IntroductionDetailReducer";
import { introductionManageReducer } from "./reducers/introductions/manage/IntroductionManageReducer";

const reducers = combineReducers({
  rootPage: rootPageReducer,
  tasksPage: tasksListReducer,
  tasksDetailPage: taskDetailReducer,
  tasksManagePage: taskManageReducer,
  projectsPage: projectListReducer,
  projectsDetailPage: projectDetailReducer,
  calendarPage: calendarReducer,
  departmentsPage: departmentReducer,
  employeePage: employeeReducer,
  vacationPage: vacationReducer,
  introductionsPage: introductionListReducer,
  introductionsDetailPage: introductionDetailReducer,
  introductionsManagePage: introductionManageReducer,
});
const store = createStore(reducers, applyMiddleware(thunkMiddleware));
export default store;
