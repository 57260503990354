import React, { useEffect } from "react";
import ProjectDetail from "./ProjectDetail";
import { connect } from "react-redux";
import Spinner from "../../shared/Spinner";
import { getProject } from "../../../redux/thunk/projects/detail/ProjectDetailThunk";
import { useParams, useSearchParams } from "react-router-dom";
import {
  setCurrentTab,
  setShowProjectEditModal,
} from "../../../redux/actions/projects/detail/ProjectDetailActions";

const ProjectDetailContainer = (props) => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    props.getProject(params.id);
  }, []);

  if (props.isLoading) {
    return <Spinner />;
  }

  return <ProjectDetail tab={searchParams.get("tab")} {...props} />;
};

const mapStateToProps = (state) => {
  return {
    project: state.projectsDetailPage.project,
    isLoading: state.projectsDetailPage.isLoading,
    currentTab: state.projectsDetailPage.currentTab,
    userId: state.rootPage.userData.id,
    editModalShow: state.projectsDetailPage.editModalShow,
  };
};

export default connect(mapStateToProps, {
  getProject,
  setCurrentTab,
  setShowProjectEditModal,
})(ProjectDetailContainer);
