export const actions = {
  SET_INTRODUCTION_INITIAL: "SET_INTRODUCTION_INITIAL",
  SET_INTRODUCTION_INITIAL_IS_LOADING: "SET_INTRODUCTION_INITIAL_IS_LOADING",
  SET_INTRODUCTION_PARTICIPANTS: "SET_INTRODUCTION_PARTICIPANTS",
  SET_INTRODUCTION_HAS_ERRORS: "SET_INTRODUCTION_HAS_ERRORS",
  SET_INTRODUCTION_SAVE_IN_PROCESS: "SET_INTRODUCTION_SAVE_IN_PROCESS",
};
export const setIntroductionInitial = (data) => {
  return {
    type: actions.SET_INTRODUCTION_INITIAL,
    data: data,
  };
};
export const setIntroductionInitialIsLoading = (isLoading) => {
  return {
    type: actions.SET_INTRODUCTION_INITIAL_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setIntroductionParticipants = (data) => {
  return {
    type: actions.SET_INTRODUCTION_PARTICIPANTS,
    data: data,
  };
};
export const setIntroductionHasErrors = (hasErrors) => {
  return {
    type: actions.SET_INTRODUCTION_HAS_ERRORS,
    hasErrors: hasErrors,
  };
};
export const setIntroductionSaveInProcess = (inProcess) => {
  return {
    type: actions.SET_INTRODUCTION_SAVE_IN_PROCESS,
    inProcess: inProcess,
  };
};
