import React from "react";
import Modal from "react-bootstrap/Modal";

const ContactEditModal = (props) => {
  const onHide = () => {
    props.handleClose(false);
  };
  const optionsTypes = [
    { value: "telegram", label: "Telegram" },
    { value: "express", label: "Express Messenger" },
    { value: "work_mail", label: "Рабочая почта" },
    { value: "self_mail", label: "Личная почта" },
    { value: "work_phone", label: "Рабочий номер телефона" },
    { value: "self_phone", label: "Личный номер телефона" },
    { value: "extension_phone", label: "Добавочный номер" },
  ].map((c, index) => (
    <option key={index} value={c.value}>
      {c.label}
    </option>
  ));
  return (
    <Modal
      show={props.show}
      centered={true}
      size="md"
      scrollable={true}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Контакт</Modal.Title>
      </Modal.Header>
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <Modal.Body>
          <div>
            <label>Тип контакта</label>
            <select
              className="form-control form-control-sm"
              {...props.register("type", { required: true })}
            >
              {optionsTypes}
            </select>
          </div>
          <div className="mt-3">
            <label>Значение</label>
            <input
              type="text"
              className="form-control form-control-sm"
              {...props.register("contact", { required: true })}
            />
          </div>
          <div className="mt-3">
            <input
              type="checkbox"
              className="form-check-input mx-1"
              {...props.register("use_for_notify")}
            />
            <label>Использовать для уведомлений</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success btn-sm">
            Сохранить изменения
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ContactEditModal;
