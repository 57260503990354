import React from "react";
import ReactHtmlParser from "react-html-parser";
import MessageFileComponent from "../comment/MessageFileComponent";

const SolutionCommentComponent = (props) => {
  const files = props.files
    ? props.files.map((f) => (
        <MessageFileComponent
          key={f.id}
          handeCommentFileDownload={props.handeCommentFileDownload}
          {...f}
        />
      ))
    : [];
  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between">
        <p className="fw-bold mb-0">
          <small>{props.author_user.short_name}</small>
        </p>
      </div>
      <div className="card-body">
          <p>{ReactHtmlParser(props.text)}</p>
          {files.length > 0 ? (
            <div className="row">
              <hr></hr>
              {files}
            </div>
          ) : null}
      </div>
    </div>
  );
};

const TaskSolutionTab = (props) => {
  const comments = props.comments
    .filter((c) => c.solution === true)
    .map((c) => (
      <SolutionCommentComponent
        key={c.id}
        handeCommentFileDownload={props.handeCommentFileDownload}
        {...c}
      />
    ));
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Решения задачи</h4>
        <div className="mt-3">{props.solution}</div>
        <hr />
        <div className="mt-2">
          <span>
            <b>Прикрепленные сообщения</b>
          </span>
        </div>
        <div className="mt-3 overflow-auto">
          {comments.length > 0 ? (
            comments
          ) : (
            <div className="px-2 mb-3">
              <i className="mdi mdi-alert-outline me-2 text-primary" />
              <span>Решения задачи не указаны</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskSolutionTab;
