import React, { useState } from "react";
import ProjectInfoTab from "./tabs/info/ProjectInfoTab";
import ProjectTasksTab from "./tabs/task/ProjectTasksTab";

const ProjectDetail = (props) => {
  const [tab, setTab] = useState(props.tab ? props.tab : "info");
  const tabSwitcherOptions = [
    { value: "info", label: "Детальная информация" },
    { value: "tasks", label: "Задачи" },
  ].map((t, index) => (
    <button
      className={`btn btn-sm ${
        t.value === tab ? "btn-primary text-white" : ""
      }`}
      onClick={() => setTab(t.value)}
      key={index}
    >
      {t.label}
    </button>
  ));
  const getSelectedTab = () => {
    switch (tab) {
      case "info":
        return (
          <ProjectInfoTab userId={props.userId} currentTab={tab} {...props} />
        );
      case "tasks":
        return (
          <ProjectTasksTab
            userId={props.userId}
            initiatorId={props.project.initiator.id}
            tasks={props.project.tasks}
            projectId={props.project.id}
          />
        );
      case "statistic":
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="row page-title-header">
        <div className="page-header">
          <b>{props.project.name}</b>
        </div>
        <div className="col-md-12">
          <div className="page-header-toolbar overflow-auto">
            <div className="btn-group" role="group">
              {tabSwitcherOptions}
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12 ">{getSelectedTab()}</div>
    </>
  );
};

export default ProjectDetail;
