import React, { useEffect } from "react";
import IntroductionDetail from "./IntroductionDetail";
import { connect } from "react-redux";
import {
  getIntroductionDetail,
  setIntroductionRead,
  getIntroductionResultFile,
} from "../../../redux/thunk/introductions/detail/IntroductionDetailThunk";
import { useParams } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import fileDownload from "js-file-download";

const IntroductionDetailContainer = (props) => {
  const params = useParams();
  useEffect(() => {
    props.getIntroductionDetail(params.id);
  }, [params.id]);
  const handleFileDownload = () => {
    const filename = `${props.detail.title}.xlsx`;
    props
      .getIntroductionResultFile(props.detail.id)
      .then((res) => fileDownload(res.data, filename));
  };
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <IntroductionDetail handleFileDownload={handleFileDownload} {...props} />
  );
};
const mapStateToProps = (state) => {
  return {
    detail: state.introductionsDetailPage.detail,
    isLoading: state.introductionsDetailPage.isLoading,
    fileOperationInProcess:
      state.introductionsDetailPage.fileOperationInProcess,
    userId: state.rootPage.userData.id,
  };
};
export default connect(mapStateToProps, {
  getIntroductionDetail,
  setIntroductionRead,
  getIntroductionResultFile,
})(IntroductionDetailContainer);
