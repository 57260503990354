import React from "react";
import TaskCard from "../../../../task/list/TaskCard";
import { NavLink } from "react-router-dom";

const ProjectTasksTab = (props) => {
  const tasks = props.tasks.map((task, index) => (
    <TaskCard {...task} key={index} />
  ));
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Задачи проекта</h4>
        <div className="mt-3">
          {props.userId === props.initiatorId ? (
            <NavLink
              to={`/tasks/create?project_id=${props.projectId}`}
              className="btn btn-primary btn-sm text-white mb-3"
            >
              Новая задача
            </NavLink>
          ) : null}

          <div className="row overflow-auto" style={{ maxHeight: "500px" }}>
            {tasks.length > 0 ? (
              tasks
            ) : (
              <span>По заданным критериям задач не найдено</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTasksTab;
