import React from "react";
import { NavLink } from "react-router-dom";
import Paginator from "../../shared/Paginator";

const IntroductionCard = (props) => {
  return (
    <div className="col-lg-4 grid-margin stretch-card">
      <div className="card border">
        <div className="card-body">
          <h4 className="card-title">
            <NavLink to={`/introductions/${props.id}/detail`}>
              {props.title}
            </NavLink>
          </h4>
          <p className="card-description">{props.description}</p>
        </div>
        <div className="card-footer">
          {props.is_read ? (
            <>
              <i className="mdi mdi-check-all me-2 text-primary" />
              <small>Ознакомлен</small>
            </>
          ) : (
            <>
              <i className="mdi mdi-alert-outline me-2 text-primary" />
              <small>Не ознакомлен</small>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
const Introduction = (props) => {
  const introductions = props.introductions.map((i) => (
    <IntroductionCard key={i.id} {...i} />
  ));
  return (
    <>
      <div className="row page-title-header">
        <div className="page-header">
          <b>Ознакомление с документами</b>
        </div>
        <div className="col-md-12">
          <div className="page-header-toolbar">
            <div className="toolbar-item me-2 p-0">
              <NavLink
                to="/introductions/create"
                className="btn btn-sm btn-primary text-white"
              >
                Новый опрос
              </NavLink>
            </div>
            <div className="toolbar-item me-2 p-0">
              <Paginator
                pageNumber={props.pageNumber}
                pageSize={props.pageSize}
                totalCount={props.count}
                onClick={props.onPageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row overflow-auto">
        {introductions.length > 0 ? (
          introductions
        ) : (
          <div className="px-4 mb-3">
            <i className="mdi mdi-alert-outline me-2 text-primary" />
            <span>Активные опросы отсутствуют</span>
          </div>
        )}
      </div>
    </>
  );
};
export default Introduction;
