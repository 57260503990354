import React from "react";
import { formatDate } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

const EventCard = (props) => {
  const navigate = useNavigate();
  return (
    <div className="col-sm-6 grid-margin stretch-card mt-1">
      <div className="card">
        <div className="card-body py-2">
          <span className="card-title fs-6">
            <div className="d-flex justify-content-md-start align-items-baseline">
              <div className="text-wrapper">
                <p className="profile-name">
                  <b>{props.name}</b>
                </p>
                <p className="text-muted">
                  {formatDate(props.start)} - {formatDate(props.finish)}
                </p>
              </div>
            </div>
          </span>
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-sm btn-primary text-white"
              onClick={() => {
                navigate(`/calendar?show_modal=true&id=${props.id}`);
              }}
            >
              Перейти к событию
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
