import React from "react";
import EditedMark from "../../../../shared/EditedMark";
import ReactHtmlParser from "react-html-parser";
import MessageFileComponent from "./MessageFileComponent";
import moment from "moment-timezone";

const MessageParticipant = (props) => {
  return (
    <li className="d-flex justify-content-end mb-4">
      <div
        className={`card w-${
          props.isMobile ? "100" : "50"
        } border-warning shadow rounded-2 me-3`}
      >
        <div className="card-header d-flex justify-content-between p-3 rounded-bottom rounded-2">
          <p className="fw-bold mb-0">
            <small>{props.name}</small>
          </p>
        </div>
        <div className="card-body">
          <p className={props.deleted ? "text-muted" : ""}>
            {ReactHtmlParser(props.text)}
          </p>
          {props.files.length > 0 ? (
            <div className="row">
              <hr></hr>
              {props.files}
            </div>
          ) : null}
        </div>
        <div className="card-footer text-muted rounded-top rounded-2">
          <div className="d-flex justify-content-between">
            <small>
              {moment(props.createdAt).local().format("DD.MM.YYYY HH:mm")}
            </small>
          </div>
          {props.edited && !props.deleted ? <EditedMark /> : null}
        </div>
      </div>
      <img
        src={props.photo} //{`data:image/jpeg;base64,${props.photo}`}
        alt="avatar"
        className="rounded-circle d-flex align-self-start shadow"
        width="45"
        height="45"
      />
    </li>
  );
};

const MessageAuthor = (props) => {
  return (
    <li className="d-flex justify-content-start mb-4">
      <img
        src={props.photo}
        alt="avatar"
        className="rounded-circle d-flex align-self-start me-3 shadow"
        width="45"
        height="45"
      />
      <div
        className={`card w-${
          props.isMobile ? "100" : "50"
        } border-primary shadow rounded-2`}
      >
        <div className="card-header d-flex justify-content-between p-3 rounded-bottom rounded-2">
          <p className="fw-bold mb-0">
            <small>{props.name}</small>
          </p>
          {!props.deleted && props.currentStatus !== "finished" ? (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={() => props.handleCommentEditClick(props.id)}
                title="Редактировать комментарий"
                className="btn mdi mdi-lead-pencil btn-sm text-primary p-0 me-2"
              />
              <button
                type="button"
                onClick={() => props.handleCommentDeleteClick(props.id)}
                title="Удалить комментарий"
                className="btn mdi mdi-delete btn-sm text-danger p-0"
              />
            </div>
          ) : null}
        </div>
        <div className="card-body">
          <p className={props.deleted ? "text-muted" : ""}>
            {ReactHtmlParser(props.text)}
          </p>
          {props.files.length > 0 ? (
            <div className="row">
              <hr></hr>
              {props.files}
            </div>
          ) : null}
        </div>
        <div className="card-footer text-muted rounded-top rounded-2">
          <div className="d-flex justify-content-between">
            <small>
              {moment(props.createdAt).local().format("DD.MM.YYYY HH:mm")}
            </small>
          </div>
          {props.edited && !props.deleted ? <EditedMark /> : null}
        </div>
      </div>
    </li>
  );
};

const TaskCommentCard = (props) => {
  const files = props.files
    ? props.files.map((f) => (
        <MessageFileComponent
          key={f.id}
          handeCommentFileDownload={props.handeCommentFileDownload}
          {...f}
        />
      ))
    : [];
  return props.userId === props.author_user.id ? (
    <MessageAuthor
      isMobile={props.isMobile}
      files={files}
      id={props.id}
      userId={props.userId}
      initiatorId={props.initiatorId}
      name={props.author_user?.short_name}
      photo={props.author_user?.photo}
      text={props.text}
      createdAt={props.created_at}
      edited={props.edited}
      deleted={props.deleted}
      solution={props.solution}
      setCommentAsSolution={props.setCommentAsSolution}
      currentStatus={props.currentStatus}
      handleCommentEditClick={props.handleCommentEditClick}
      handleCommentDeleteClick={props.handleCommentDeleteClick}
    />
  ) : (
    <MessageParticipant
      isMobile={props.isMobile}
      files={files}
      id={props.id}
      userId={props.userId}
      initiatorId={props.initiatorId}
      name={props.author_user?.short_name}
      photo={props.author_user?.photo}
      text={props.text}
      createdAt={props.created_at}
      edited={props.edited}
      deleted={props.deleted}
      solution={props.solution}
      currentStatus={props.currentStatus}
      setCommentAsSolution={props.setCommentAsSolution}
    />
  );
};

export default TaskCommentCard;
