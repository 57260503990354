import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "../../../../shared/Spinner";

const AvailableParticipantCard = (props) => {
  const [role, setRole] = useState(null);
  const rolesOptions = [
    { value: "", label: "Выберите роль участника" },
    { value: "executor", label: "Исполнитель" },
    { value: "coexecutor", label: "Соисполнитель" },
    { value: "auditor", label: "Аудитор" },
  ].map((r, index) => (
    <option key={index} value={r.value}>
      {r.label}
    </option>
  ));
  const handleSetRole = (e) => {
    setRole(e.target.value ? e.target.value : null);
  };
  return (
    <div className="col-sm-auto grid-margin stretch-card mb-3 mt-2">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <div className="card-title">
              <img
                className="img-xs rounded-circle me-2"
                src={props.photo} //{`data:image/jpeg;base64,${props.photo}`}
                alt="..."
              />
            </div>
            <div>
              <small>
                {props.full_name}, {props.structural_unit_repr}
              </small>
            </div>
          </div>
          <div className="mt-2">
            <select
              className="form-control form-control-sm"
              onChange={(e) => handleSetRole(e)}
            >
              {rolesOptions}
            </select>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end">
          <button
            type="button"
            disabled={!role}
            onClick={() => props.handleParticipantAddClick(props.id, role)}
            className="btn btn-primary btn-sm text-white"
          >
            {props.participantAddWaitList.some((id) => id === props.id) ? (
              <div
                className="spinner-border text-white spinner-border-sm"
                role="status"
              />
            ) : (
              <>
                <span className="me-2">Добавить</span>
                <i className="mdi mdi-plus" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const TaskParticipantModal = (props) => {
  const [text, setText] = useState("");
  const handleClose = () => {
    setText("");
    props.handleParticipantModalShow(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      props.handleSearchChange(text);
    }
  };
  const availableParticipants = props.availableParticipants.map((p, index) => (
    <AvailableParticipantCard
      key={index}
      taskId={props.taskId}
      participantAddWaitList={props.participantAddWaitList}
      handleParticipantAddClick={props.handleParticipantAddClick}
      handleChangeParticipantRole={props.handleChangeParticipantRole}
      {...p}
    />
  ));

  return (
    <Modal
      show={props.show}
      centered={true}
      size="md"
      scrollable={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Добавление участника</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.modalIsLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="col-md-auto">
              <label className="form-label">Поиск</label>
              <input
                type="text"
                className="form-control form-control-sm"
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="mt-4">
              {!props.executorId && (
                <button
                  onClick={() =>
                    props.handleParticipantAddClick(props.userId, "executor")
                  }
                  disabled={props.participantAddWaitList.some(
                    (id) => id === props.userId
                  )}
                  className="btn btn-warning btn-sm text-white"
                >
                  {props.participantAddWaitList.some(
                    (id) => id === props.userId
                  ) ? (
                    <div
                      className="spinner-border text-white spinner-border-sm"
                      role="status"
                    />
                  ) : (
                    "Назначить себя исполнителем"
                  )}
                </button>
              )}
            </div>
            <div className="mt-4">
              {availableParticipants.length > 0
                ? availableParticipants
                : "Доступные участники отсутствуют"}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Закрыть
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskParticipantModal;
