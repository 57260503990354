import React, { useState } from "react";
import TaskInfoTab from "./tabs/info/TaskInfoTab";
import TaskParticipantTab from "./tabs/participant/TaskParticipantTab";
import TaskCommentTab from "./tabs/comment/TaskCommentTab";
import TaskManageTab from "./tabs/manage/TaskManageTab";
import TaskStatisticTab from "./tabs/statistic/TaskStatisticTab";
import TaskSolutionTab from "./tabs/solution/TaskSolutionTab";
import TaskRelatedTab from "./tabs/related/TaskRelatedTab";
import TaskHistoryTab from "./tabs/history/TaskHistroryTab";

const TaskDetail = (props) => {
  const [tab, setTab] = useState(props.tab ? props.tab : "info");
  const tabSwitcherOptions = [
    { value: "info", label: "Детальная информация" },
    { value: "manage", label: "Управление" },
    { value: "participants", label: "Участники" },
    { value: "comments", label: "Комментарии" },
    { value: "statistic", label: "Статистика" },
    { value: "solutions", label: "Решения задачи" },
    { value: "related", label: "Связанные задачи" },
    { value: "history", label: "История изменений" },
  ].map((t, index) => (
    <button
      className={`btn btn-sm${
        tab === t.value ? " btn-primary text-white" : ""
      }`}
      key={index}
      onClick={() => setTab(t.value)}
    >
      {t.label}
    </button>
  ));

  const getSelectedTab = () => {
    switch (tab) {
      case "info":
        return (
          <TaskInfoTab
            userId={props.userData?.id}
            detail={props?.task?.detail}
          />
        );
      case "manage":
        return (
          <TaskManageTab
            userId={props.userData.id}
            executor={props.task?.detail?.executor}
            initiator={props.task?.detail?.initiator}
            currentStatus={props.task.detail.current_status}
            currentStatusRepr={props.task.detail.current_status_repr}
            changeTaskStatus={props.changeTaskStatus}
            changeAcceptanceStatus={props.changeAcceptanceStatus}
            comments={props.task.comments}
            handleSetTaskSolution={props.handleSetTaskSolution}
            solutionsModalShow={props.solutionsModalShow}
            solutionSetInProcess={props.solutionSetInProcess}
            handleSolutionsModalShow={props.handleSolutionsModalShow}
            handleStatusButtonClick={props.handleStatusButtonClick}
          />
        );
      case "participants":
        return (
          <TaskParticipantTab
            userId={props.userData.id}
            taskId={props.task.detail.id}
            initiatorId={props.task?.detail?.initiator?.participant}
            executorId={props.task?.detail?.executor?.participant}
            participants={props.task.participants}
            currentStatus={props.task?.detail?.current_status}
            availableParticipants={props.availableParticipants}
            participantAddWaitList={props.participantAddWaitList}
            participantDeleteWaitList={props.participantDeleteWaitList}
            participantModalShow={props.participantModalShow}
            modalIsLoading={props.modalIsLoading}
            handleSearchChange={props.getAvailableParticipants}
            handleParticipantModalShow={props.handleParticipantModalShow}
            handleChangeParticipantRole={props.handleChangeParticipantRole}
            handleParticipantAddClick={props.handleParticipantAddClick}
            handleDeleteParticipantClick={props.deleteParticipant}
            getUserAvatar={props.getUserAvatar}
          />
        );
      case "comments":
        return (
          <TaskCommentTab
            userId={props.userData.id}
            isMobile={props.isMobile}
            initiatorId={props.task.detail.initiator.participant}
            comments={props.task.comments}
            currentComment={props.currentComment}
            currentStatus={props.task.detail.current_status}
            sendCommentInProcess={props.sendCommentInProcess}
            commentIsLoading={props.commentIsLoading}
            postCommentInProcess={props.postCommentInProcess}
            setCommentText={props.setCommentText}
            setCommentAsSolution={props.setCommentAsSolution}
            handeCommentFileDownload={props.handeCommentFileDownload}
            handleSendComment={props.sendComment}
            handleCommentTextChanged={props.handleCommentTextChanged}
            handleFileAttached={props.handleFileAttached}
            handleCommentEditClick={props.handleCommentEditClick}
            handleCommentEditCancelClick={props.handleCommentEditCancelClick}
            handleCommentDeleteClick={props.deleteComment}
            handleFileDeleteClick={props.handleFileDeleteClick}
            getUserAvatar={props.getUserAvatar}
          />
        );
      case "statistic":
        return <TaskStatisticTab statistic={props.task.statistic} />;
      case "solutions":
        return (
          <TaskSolutionTab
            comments={props.task.comments}
            solution={props.task.detail.solution}
            handeCommentFileDownload={props.handeCommentFileDownload}
          />
        );
      case "related":
        return (
          <TaskRelatedTab
            related={props.task.detail.related}
            taskId={props.task.detail.id}
            currentStatus={props.task.detail.current_status}
            userId={props.userData.id}
            executorId={props.task?.detail?.executor?.participant}
            initiatorId={props.task?.detail?.initiator?.participant}
          />
        );
      case "history":
        return <TaskHistoryTab events={props.task.events.events} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="row page-title-header">
        <div className="page-header">
          <b>
            {props.task.hasOwnProperty("detail")
              ? `${props.task.detail.number} ${props.task.detail.name}`
              : null}
          </b>
        </div>
        <div className="col-md-12">
          <div className="page-header-toolbar overflow-auto">
            <div class="btn-group" role="group">
              {tabSwitcherOptions}
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12">{getSelectedTab()}</div>
    </>
  );
};

export default TaskDetail;
