import { actions } from "../../actions/calendar/CalendarActions";

const initialState = {
  events: [],
  event: {},
  filters: {
    page_size: 1000,
    page_number: 1,
  },
  isLoading: false,
  modalIsLoading: false,
  eventSaveInProcess: false,
  eventCompleteInProcess: false,
  eventCreateMode: false,
  currentTab: "info",
  participants: [],
};

export const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CALENDAR_EVENTS:
      return {
        ...state,
        events: action.data,
      };
    case actions.SET_CALENDAR_EVENT:
      return {
        ...state,
        event: action.data,
      };
    case actions.SET_CALENDAR_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_EVENT_MODAL_IS_LOADING:
      return {
        ...state,
        modalIsLoading: action.isLoading,
      };
    case actions.SET_EVENT:
      return {
        ...state,
        events: action.add
          ? [...state.events, action.data]
          : state.events.filter((e) => e.id !== action.data.id),
      };
    case actions.UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((e) =>
          e.id === action.data.id ? action.data : e
        ),
      };
    case actions.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.data,
      };
    case actions.SET_EVENT_SAVE_IN_PROCESS:
      return {
        ...state,
        eventSaveInProcess: action.inProcess,
      };
    case actions.SET_EVENT_COMPLETED_IN_PROCESS:
      return {
        ...state,
        eventCompleteInProcess: action.inProcess,
      };
    case actions.SET_EVENT_COMPLETED:
      return {
        ...state,
        events: state.events.map((e) =>
          e.id === action.id ? { ...e, completed: action.completed } : e
        ),
      };
    default:
      return state;
  }
};
