import React from "react";

const Spinner = ({ text = null }) => {
  return (
    <div className="text-center mt-5">
      <div className="spinner-wrapper">
        <div className="spinner-border text-primary" role="status" />
      </div>
      <div className="mt-3">{text ? text : 'Загрузка страницы...'}</div>
    </div>
  );
};

export default Spinner;
