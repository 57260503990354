import React from "react";
import AlertToast from "../shared/AlertToast";
import logo from "../../assets/images/logo-stk-black.png";
import { ToastContainer } from "react-bootstrap";

const Login = (props) => {
  const alerts = props.alerts
    ? props.alerts.map((a, index) => (
        <AlertToast key={index} onClose={props.setAlertToast} {...a} />
      ))
    : [];
  return (
    <div>
      <div className="d-flex align-items-center auth px-0 align-middle">
        <div className="row w-100 mx-0">
          <div className="col-lg-8 mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5 mt-5 col">
              <div className="brand-logo">
                <img src={logo} alt="logo" />
              </div>
              <h4>Добро пожаловать</h4>
              <h6 className="font-weight-light">
                Введите учетные данные для продолжения
              </h6>
              <ToastContainer position="top-end" className="mt-5 p-4">
                {alerts.length > 0 ? alerts : null}
              </ToastContainer>
              <form
                className="pt-3 col-md-4"
                onSubmit={props.handleSubmit(props.onSubmit)}
              >
                <label className="form-label">Имя пользователя</label>
                <div className="form-group d-flex">
                  <input
                    type="text"
                    className={`form-control form-control-sm ${
                      props.errors.username ? "is-invalid" : ""
                    }`}
                    placeholder="Имя пользователя"
                    {...props.register("username", { required: true })}
                  />
                </div>
                <label className="form-label">Пароль</label>
                <div className="form-group d-flex">
                  <input
                    type="password"
                    className={`form-control form-control-sm ${
                      props.errors.password ? "is-invalid" : ""
                    }`}
                    placeholder="Пароль"
                    {...props.register("password", { required: true })}
                  />
                </div>
                <div className="mt-3">
                  <button
                    className="btn btn-primary btn-sm text-white"
                    disabled={props.loginInProcess}
                  >
                    {props.loginInProcess ? (
                      <div
                        className="spinner-border spinner-border-sm text-white"
                        role="status"
                      />
                    ) : (
                      "Вход"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
