import React from "react";
import { formatDate } from "../../../../../utils/utils";
import { NavLink } from "react-router-dom";
import Spinner from "../../../../shared/Spinner";

const TaskInfoTab = ({ detail, userId }) => {
  if (!detail) {
    return <Spinner />;
  }
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Информация о задаче</h4>
        <div className="mt-3">
          {detail?.initiator ? (
            detail?.initiator?.participant === userId &&
            !["finished", "aborted"].includes(detail.current_status) ? (
              <NavLink
                to={`/tasks/${detail.id}/edit`}
                className="btn btn-primary btn-sm text-white mb-3"
              >
                Редактировать
              </NavLink>
            ) : null
          ) : null}
          <small>
            <div className="mt-2">
              Наименование задачи: <b>{detail?.name}</b>
            </div>
            <div className="mt-2">Описание задачи: {detail?.description}</div>
            <div className="mt-2">
              Начало задачи: <b>{formatDate(detail?.start)}</b>
            </div>
            <div className="mt-2">
              Завершить до: <b>{formatDate(detail?.finish)}</b>
            </div>
            <div className="mt-2">
              Инициатор:{" "}
              <b>
                {detail?.initiator
                  ? detail?.initiator?.full_name
                  : "Не указано"}
              </b>
            </div>
            <div className="mt-2">
              Текущий статус: <b>{detail?.current_status_repr}</b>
            </div>
            <div className="mt-2">
              Приоритет: <b>{detail?.priority_repr}</b>
            </div>
            <div className="mt-2">
              Проект:{" "}
              <b>
                {detail?.project_repr ? (
                  <NavLink to={`/projects/${detail?.project}/detail?tab=info`}>
                    {detail?.project_repr}
                  </NavLink>
                ) : (
                  "Не указано"
                )}
              </b>
            </div>
            <div className="mt-2">
              Родительская задача:{" "}
              <b>
                {detail?.parent_task_repr
                  ? detail?.parent_task_repr
                  : "Не указано"}
              </b>
            </div>
          </small>
          <h4 className="card-title mt-4 mb-4">Краткая статистика</h4>
          <hr></hr>
          <ul>
            <li>
              Время в работе: <b>{detail?.processing}</b>
            </li>
            <li>
              Время приостановки: <b>{detail?.suspended}</b>
            </li>
            <li>
              Время в статусе "Создана": <b>{detail?.created}</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TaskInfoTab;
