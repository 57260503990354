import {
  setInitialHasErrors,
  setTaskCreateInProcessAC,
  setTaskInitialData,
  setTaskInitialIsLoading,
  setTaskParentsAC,
  setTaskProjectsAC,
} from "../../../actions/tasks/manage/TaskManageActions";
import { setAlertToast } from "../../../actions/root/RootPageActions";
import api from "../../../../utils/api/api_beta";
import { handleErrors } from "../../HandleErrors";

export const getTaskInitialData = (pk) => {
  return async (dispatch) => {
    dispatch(setTaskInitialIsLoading(true));
    dispatch(setInitialHasErrors(false));
    try {
      const response = await api.get("api/tasks/initial-data", {
        params: { pk: pk },
      });
      dispatch(setTaskInitialData(response.data));
      dispatch(setTaskInitialIsLoading(false));
    } catch (e) {
      dispatch(setTaskInitialIsLoading(false));
      dispatch(setInitialHasErrors(true));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getTaskProjectsTC = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("api/projects");
      dispatch(setTaskProjectsAC(response.data.projects));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};

export const getTaskParentsTC = (id = null) => {
  return async (dispatch) => {
    const params = id ? { project_id: id } : null;
    try {
      const response = await api.get("api/tasks", {
        params: params,
      });
      dispatch(setTaskParentsAC(response.data.tasks));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveTaskTC = (data, pk = null) => {
  return async (dispatch) => {
    dispatch(setTaskCreateInProcessAC(true));
    try {
      let response;
      let alertText = "Задача создана";
      if (pk) {
        response = await api.put("api/tasks/edit/", data, {
          params: { pk: pk },
        });
        alertText = "Изменения сохранены";
      } else {
        response = await api.post("api/tasks/create/", data);
      }
      dispatch(setTaskCreateInProcessAC(false));
      dispatch(
        setAlertToast("Успешное выполнение", alertText, "success", null, true)
      );
      return response;
    } catch (e) {
      dispatch(setTaskCreateInProcessAC(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
