import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CkEditor = (props) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        language: { ui: 'ru', content: 'ru' },
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "blockQuote",
          "link",
          "numberedList",
          "bulletedList",
          "|",
          "undo",
          "redo",
        ],
      }}
      data={props.text ? props.text : ""}
      onChange={(event, editor) => {
        props.handleCommentTextChanged(editor.getData());
      }}
    />
  );
};

export default CkEditor;
