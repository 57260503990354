import React, { useEffect } from "react";
import NotificationDetail from "./NotificationDetail";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNotificationDetail,
  setNotifyRead,
} from "../../../redux/thunk/root/RootPageThunk";
import Spinner from "../../shared/Spinner";
import { setCommentText } from "../../../redux/actions/tasks/detail/TaskDetailActions";
import { sendComment } from "../../../redux/thunk/tasks/detail/TaskDetailThunk";

const NotificationDetailContainer = (props) => {
  const params = useParams();
  useEffect(() => {
    props.getNotificationDetail(params.id);
  }, [params.id]);

  if (props.isLoading) {
    return <Spinner />;
  }

  return <NotificationDetail {...props} />;
};
const mapStateToProps = (state) => {
  return {
    notification: state.rootPage.currentNotification,
    isLoading: state.rootPage.notificationIsLoading,
    awaitSetRead: state.rootPage.awaitSetRead,
    currentComment: state.tasksDetailPage.currentComment,
    sendCommentInProcess: state.tasksDetailPage.sendCommentInProcess,
  };
};

export default connect(mapStateToProps, {
  getNotificationDetail,
  setCommentText,
  sendComment,
  setNotifyRead,
})(NotificationDetailContainer);
