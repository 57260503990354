import { actions } from "../../actions/departments/DepartmentActions";

const initialState = {
  detail: {},
  treeData: [],
  usersVacations: [],
  users: [],
  isLoading: false,
  usersIsLoading: false,
  count: 0,
};

export const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_DEPARTMENTS:
      return {
        ...state,
        detail: action.data.detail,
        treeData: action.data.tree_data,
        usersVacations: action.data.near_users_vacations,
        count: action.data.count,
      };
    case actions.SET_DEPARTMENTS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_DEPARTMENT_USERS:
      return {
        ...state,
        users: action.data,
      };
    case actions.SET_DEPARTMENT_USERS_IS_LOADING:
      return {
        ...state,
        usersIsLoading: action.isLoading,
      };
    default:
      return state;
  }
};
