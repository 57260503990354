export const actions = {
  SET_PROJECT: "SET_PROJECT",
  SET_PROJECT_IS_LOADING: "SET_PROJECT_IS_LOADING",
  SET_PROJECT_CURRENT_TAB: "SET_PROJECT_CURRENT_TAB",
  SET_PROJECT_EDIT_MODAL_SHOW: "SET_PROJECT_EDIT_MODAL_SHOW",
};

export const setProject = (data) => ({ type: actions.SET_PROJECT, data: data });
export const setProjectIsLoading = (isLoading) => ({
  type: actions.SET_PROJECT_IS_LOADING,
  isLoading: isLoading,
});
export const setCurrentTab = (tabName) => ({
  type: actions.SET_PROJECT_CURRENT_TAB,
  tabName: tabName,
});
export const setShowProjectEditModal = (show) => ({
  type: actions.SET_PROJECT_EDIT_MODAL_SHOW,
  show: show,
});
