import { actions } from "../../../actions/introductions/list/IntroductionsActions";
const initialState = {
  introductions: [],
  pageSize: 10,
  pageNumber: 1,
  count: 0,
  filters: {
    page_number: 1,
    page_size: 10,
  },
  ordering: [],
  errors: {},
  isLoading: false,
};

export const introductionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_INTRODUCTIONS:
      return {
        ...state,
        introductions: action.introductions,
        count: action.count,
        pageNumber: action.pageNumber,
        pageSize: action.pageSize,
      };
    case actions.SET_INTRODUCTIONS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_INTRODUCTIONS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    case actions.SET_INTRODUCTIONS_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case actions.SET_INTRODUCTIONS_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case actions.SET_INTRODUCTIONS_ORDERING:
      return {
        ...state,
        ordering: action.data,
      };
    default:
      return state;
  }
};
