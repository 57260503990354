import api from "../../utils/api/api_beta";
import { handleErrors } from "./HandleErrors";
import {
  setVacation,
  setVacations,
  setVacationSaveInProcess,
  setVacationsHasErrors,
  setVacationsIsLoading,
} from "../actions/vacations/VacationActions";
import { formatDate } from "../../utils/utils";
import { setAlertToast } from "../actions/root/RootPageActions";

export const getVacations = () => {
  return async (dispatch) => {
    dispatch(setVacationsIsLoading(true));
    dispatch(setVacationsHasErrors(false));
    try {
      const response = await api.get("api/vacations");
      dispatch(
        setVacations(response.data.vacations, response.data.vacation_days)
      );
      dispatch(setVacationsIsLoading(false));
    } catch (e) {
      dispatch(setVacationsIsLoading(false));
      dispatch(setVacationsHasErrors(true));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getVacation = (pk) => {
  return async (dispatch) => {
    dispatch(setVacationsIsLoading(true));
    dispatch(setVacationsHasErrors(false));
    try {
      const response = await api.get("api/vacations/detail", {
        params: { pk: pk },
      });
      dispatch(setVacation(response.data));
      dispatch(setVacationsIsLoading(false));
    } catch (e) {
      dispatch(setVacationsIsLoading(false));
      dispatch(setVacationsHasErrors(true));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveVacation = (data, pk = null) => {
  return async (dispatch) => {
    dispatch(setVacationSaveInProcess(true));
    try {
      let response;
      data.start = formatDate(data.start, "y-m-d");
      data.finish = formatDate(data.finish, "y-m-d");
      if (pk) {
        response = await api.put("api/vacations/edit/", data, {
          params: { pk: pk },
        });
      } else {
        response = await api.post("api/vacations/create/", data);
      }
      dispatch(setVacationSaveInProcess(false));
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          pk ? "Изменения сохранены" : "Запись об отпуске создана",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setVacationSaveInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
