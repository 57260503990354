import { actions } from "../../../actions/tasks/list/TaskListActions";

const initialState = {
  tasks: [],
  widgets: [],
  ordering: [],
  users: [],
  pageSize: 10,
  pageNumber: 1,
  count: 0,
  countRepr: "0 задач",
  filters: {
    page_number: 1,
    page_size: 10,
  },
  errors: {},
  widgetData: {},
  isLoading: false,
  showWidgetModal: false,
  widgetIsLoading: false,
  widgetSaveInProcess: false,
};

export const tasksListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_TASKS:
      return {
        ...state,
        tasks: action.tasks,
        count: action.count,
        widgets: action.widgets,
        pageNumber: action.pageNumber,
        pageSize: action.pageSize,
        countRepr: action.countRepr,
      };
    case actions.SET_TASKS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_TASKS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    case actions.SET_TASKS_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case actions.SET_TASKS_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case actions.SET_TASKS_ORDERING:
      return {
        ...state,
        ordering: action.data,
      };
    case actions.SET_SHOW_WIDGET_MODAL:
      return {
        ...state,
        showWidgetModal: action.show,
      };
    case actions.SET_WIDGET_MODAL_USERS:
      return {
        ...state,
        users: action.data,
      };
    case actions.SET_WIDGET_DATA:
      return {
        ...state,
        widgetData: action.data,
      };
    case actions.SET_WIDGET_IS_LOADING:
      return {
        ...state,
        widgetIsLoading: action.isLoading,
      };
    case actions.SET_WIDGET_SAVE_IN_PROCESS:
      return {
        ...state,
        widgetSaveInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
