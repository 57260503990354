import { actions } from "../../../actions/introductions/manage/IntroductionManageActions";

const initialState = {
  initial: {},
  participants: [],
  hasErrors: false,
  initialIsLoading: false,
  introductionSaveInProcess: false,
};
export const introductionManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_INTRODUCTION_INITIAL:
      return {
        ...state,
        initial: action.data,
      };
    case actions.SET_INTRODUCTION_INITIAL_IS_LOADING:
      return {
        ...state,
        initialIsLoading: action.isLoading,
      };
    case actions.SET_INTRODUCTION_HAS_ERRORS:
      return {
        ...state,
        hasErrors: action.hasErrors,
      };
    case actions.SET_INTRODUCTION_PARTICIPANTS:
      return {
        ...state,
        participants: action.data,
      };
    case actions.SET_INTRODUCTION_SAVE_IN_PROCESS:
      return {
        ...state,
        introductionSaveInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
