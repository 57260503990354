import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { saveProject } from "../../../redux/thunk/projects/ProjectManageThunk";
import { getProject } from "../../../redux/thunk/projects/detail/ProjectDetailThunk";
import { useNavigate, useParams } from "react-router-dom";
import ProjectManage from "./ProjectManage";
import Spinner from "../../shared/Spinner";
import { setCurrentPathAC } from "../../../redux/actions/root/RootPageActions";
import moment from 'moment-timezone';

const ProjectManageContainer = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      description: "",
      start: null,
      finish: null,
    },
  });

  useEffect(() => {
    if (params.id) {
      props.setCurrentPathAC("projects/manage/edit");
    } else {
      props.setCurrentPathAC("projects/manage/create");
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      props.getProject(params.id);
    } else {
      reset({
        id: "",
        name: "",
        description: "",
        start: null,
        finish: null,
      });
    }
  }, [props.currentPath]);

  useEffect(() => {
    if (props.project) {
      const data = { ...props.project };
      data.start = moment(data.start).format("YYYY-MM-DDTkk:mm");
      data.finish = moment(data.finish).format("YYYY-MM-DDTkk:mm");
      reset(data);
    }
  }, [props.project]);

  const onSubmit = (data) => {
    props
      .saveProject(data, params.id)
      .then((res) => navigate(`/projects/${res.data.id}/detail`));
  };

  if (props.isLoading || Object.keys(props).length === 0) {
    return <Spinner />;
  }

  return (
    <ProjectManage
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      projectId={params.id}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    project: state.projectsDetailPage.project,
    isLoading: state.projectsDetailPage.isLoading,
    currentPath: state.rootPage.currentPath,
  };
};

export default connect(mapStateToProps, {
  getProject,
  saveProject,
  setCurrentPathAC,
})(ProjectManageContainer);
