import React from "react";
import IntroductionManageForm from "../form/IntroductionManageForm";

const IntroductionManage = (props) => {
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>{props.introductionId ? "Радактирование опроса" : "Новый опрос"}</b>
      </div>
      <div className="col-sm-12">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Основаная информация об опросе</h4>
            <IntroductionManageForm {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default IntroductionManage;
