import { actions } from "../../../actions/tasks/manage/TaskManageActions";

const initialState = {
  initial: {},
  projects: [],
  parents: [],
  hasErrors: false,
  initialIsLoading: false,
  taskSaveInProcess: false,
};

export const taskManageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_TASK_INITIAL:
      return {
        ...state,
        initial: action.data,
      };
    case actions.SET_INITIAL_IS_LOADING:
      return {
        ...state,
        initialIsLoading: action.isLoading,
      };
    case actions.SET_INITIAL_HAS_ERRORS:
      return {
        ...state,
        hasErrors: action.hasErrors,
      };
    case actions.SET_TASK_PROJECTS:
      return {
        ...state,
        projects: action.data,
      };
    case actions.SET_TASK_PARENTS:
      return {
        ...state,
        parents: action.data,
      };
    case actions.SET_TASK_CREATE_IN_PROCESS:
      return {
        ...state,
        taskSaveInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
