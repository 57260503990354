import React, { useEffect } from "react";
import VacationManage from "./VacationManage";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getVacation, saveVacation } from "../../../redux/thunk/VacationThunk";
import { setCurrentPathAC } from "../../../redux/actions/root/RootPageActions";
import { useForm } from "react-hook-form";

const VacationManageContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (params.id) {
      props.setCurrentPathAC("vacations/edit");
    } else {
      props.setCurrentPathAC("vacations/create");
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      props.getVacation(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      reset({
        start: props.vacation.start,
        finish: props.vacation.finish,
      });
    } else {
      reset({});
    }
  }, [props.vacation]);

  const onSubmit = (data) => {
    props
      .saveVacation(data, params.id)
      .then((res) => res.status === 200 && navigate("/vacations"));
  };

  return (
    <VacationManage
      vacationId={params.id}
      register={register}
      errors={errors}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    vacation: state.vacationPage.vacation,
    vacationSaveInProcess: state.vacationPage.vacationSaveInProcess,
    currentPath: state.rootPage.currentPath,
  };
};

export default connect(mapStateToProps, {
  setCurrentPathAC,
  getVacation,
  saveVacation,
})(VacationManageContainer);
