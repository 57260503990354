import React, { useEffect, useState } from "react";
import CalendarPage from "./CalendarPage";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import {
  deleteEvent,
  getEventDetail,
  getEvents,
  getParticipants,
  saveEvent,
  setEventCompleted,
} from "../../redux/thunk/events/EventThunk";
import Spinner from "../shared/Spinner";
import { setCurrentPathAC } from "../../redux/actions/root/RootPageActions";
import { useSearchParams } from "react-router-dom";
import moment from "moment-timezone";

const CalendarContainer = (props) => {
  const [eventModalShow, setEventModalShow] = useState(false);
  const [modalMode, setModalMode] = useState("detail");
  const [eventType, setEventType] = useState("all");
  const [searchParams] = useSearchParams();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    props.getEvents();
    props.setCurrentPathAC("calendar");
  }, []);

  useEffect(() => {
    const data = { ...props.event };
    data.start = moment(data.start).format("YYYY-MM-DDTkk:mm");
    data.finish = moment(data.finish).format("YYYY-MM-DDTkk:mm");
    reset(data);
  }, [props.event]);

  useEffect(() => {
    if (searchParams.get("show_modal")) {
      const eventId = searchParams.get("id");
      handleEventShowModal(true, eventId, "detail");
    }
  }, [searchParams]);

  const handleEventTypeChange = (types) => {
    setEventType(types);
    const eventTypes = types.map((t) => t.value);
    props.getEvents(eventTypes.join(","));
  };

  const handleEventShowModal = (show, id = null, mode = "create") => {
    setModalMode(mode);
    if (mode === "create") {
      reset({});
    } else {
      if (show) {
        props.getEventDetail(id);
      }
    }
    if (show) {
      props.getParticipants();
    }
    setEventModalShow(show);
  };
  const handleDeleteButtonClick = (id) => {
    props.deleteEvent(id);
    handleEventShowModal(false);
  };
  const handleSetEventCompleted = (id, completed) => {
    handleEventShowModal(false);
    props.setEventCompleted(id, completed);
  };
  const onSubmit = (data) => {
    const formData = { ...data };
    if (formData.users) {
      formData.users_id = formData.users.map((u) => u.value);
    }
    delete formData["users"];
    props
      .saveEvent(formData, formData.id)
      .then((res) => res.status === 200 && setEventModalShow(false));
  };

  if (props.isLoading) {
    return <Spinner />;
  }

  return (
    <CalendarPage
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      getValues={getValues}
      control={control}
      errors={errors}
      modalMode={modalMode}
      eventModalShow={eventModalShow}
      setEventModalShow={setEventModalShow}
      eventType={eventType}
      handleEventShowModal={handleEventShowModal}
      handleDeleteButtonClick={handleDeleteButtonClick}
      handleEventTypeChange={handleEventTypeChange}
      handleSetEventCompleted={handleSetEventCompleted}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.calendarPage.events,
    event: state.calendarPage.event,
    showEventDetailModal: state.calendarPage.showEventDetailModal,
    currentTab: state.calendarPage.currentTab,
    userId: state.rootPage.userData.id,
    permissions: state.rootPage.userData.permissions,
    participants: state.calendarPage.participants,
    isLoading: state.calendarPage.isLoading,
    modalIsLoading: state.calendarPage.modalIsLoading,
    eventCompleteInProcess: state.calendarPage.eventCompleteInProcess,
    eventCreateMode: state.calendarPage.eventCreateMode,
  };
};

export default connect(mapStateToProps, {
  setCurrentPathAC,
  getEvents,
  getEventDetail,
  getParticipants,
  saveEvent,
  setEventCompleted,
  deleteEvent,
})(CalendarContainer);
