import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactHtmlParser from "react-html-parser";

const MessageCard = (props) => {
  const files = props.files
    ? props.files.map((f) => (
        <div className="col-sm-auto mt-2" key={f.id}>
          <a target="_blank" href={f.url}>
            {f.name}
          </a>
        </div>
      ))
    : [];
  return (
    <div className="card mb-3">
      <div className="card-header d-flex justify-content-between">
        <p className="fw-bold mb-0">
          <small>{props.author_user.short_name}</small>
        </p>
      </div>
      <div className="card-body">
        <div className="card-body">
          <p>{ReactHtmlParser(props.text)}</p>
          {files.length > 0 ? (
            <div className="row">
              <hr></hr>
              {files}
            </div>
          ) : null}
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex justify-content-end">
          <button
            type="button"
            title={
              props.checkedComments.some((i) => i === props.id)
                ? "Убрать отметку решения"
                : "Пометить как решение"
            }
            onClick={() => props.handleSetCheckedComment(props.id)}
            className="btn btn-outline-primary btn-sm"
          >
            <i
              className={
                props.checkedComments.some((i) => i === props.id)
                  ? "mdi mdi-close-box"
                  : "mdi mdi-check"
              }
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const TaskSolutionModal = (props) => {
  const [tab, setTab] = useState("text");
  const [checkedComments, setCheckedComments] = useState([]);
  const [solutionText, setSolutionText] = useState("");
  const onClose = () => {
    // props.changeTaskStatus("finished");
    props.handleSolutionsModalShow(false);
  };
  const handleSetCheckedComment = (id) => {
    checkedComments.includes(id)
      ? setCheckedComments(checkedComments.filter((i) => i !== id))
      : setCheckedComments([...checkedComments, id]);
  };
  const comments = props.comments
    ? props.comments.map((c) =>
        !c.deleted ? (
          <MessageCard
            key={c.id}
            handleSolutionsModalShow={props.handleSolutionsModalShow}
            checkedComments={checkedComments}
            handleSetCheckedComment={handleSetCheckedComment}
            {...c}
          />
        ) : null
      )
    : [];

  const tabSwitcherOptions = [
    { value: "text", label: "Текстовое решение" },
    { value: "comments", label: "Сообщения" },
  ].map((t, index) => (
    <button
      className={`btn btn-sm${
        tab === t.value ? " btn-primary text-white" : ""
      }`}
      key={index}
      onClick={() => setTab(t.value)}
    >
      {t.label}
    </button>
  ));
  return (
    <Modal
      show={props.solutionsModalShow}
      centered={true}
      size="md"
      scrollable={true}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Решения задачи</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="btn-group mb-3" role="group">
          {tabSwitcherOptions}
        </div>
        <div>
          {tab === "comments" ? (
            comments
          ) : (
            <>
              <label>
                <small>Введите решение задачи:</small>
              </label>
              <textarea
                className="form-control form-control-sm"
                value={solutionText}
                onChange={(e) => setSolutionText(e.target.value)}
              />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-sm btn-success text-white"
            disabled={props.solutionSetInProcess}
            onClick={() =>
              props.handleSetTaskSolution(solutionText, checkedComments)
            }
          >
            {props.solutionSetInProcess ? (
              <div
                className="spinner-border spinner-border-sm text-white"
                role="status"
              />
            ) : (
              "Завершить задачу"
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskSolutionModal;
