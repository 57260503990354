import api from "../../../utils/api/api_beta";
import { setAlertToast } from "../../actions/root/RootPageActions";
import { handleErrors } from "../HandleErrors";

export const saveProject = (data, pk = null) => {
  return async (dispatch) => {
    try {
      let alertText = "Проект создан";
      let response;
      if (pk) {
        alertText = "Изменения сохранены";
        response = await api.put("api/projects/edit/", data, {
          params: { pk: pk },
        });
      } else {
        response = await api.post("api/projects/create/", data);
      }
      dispatch(
        setAlertToast("Успешное выполнение", alertText, "success", null, true)
      );
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
