import api from "../../../../utils/api/api_beta";
import {
  setIntroductionDetail,
  setIntroductionDetailIsLoading,
  setIntroductionRead as readIntroduction,
  setIntroductionFileOperationInProcess,
} from "../../../actions/introductions/detail/IntroductionDetailActions";
import { setAlertToast, setNotificationToastAC } from "../../../actions/root/RootPageActions";
import { handleErrors } from "../../HandleErrors";

export const getIntroductionDetail = (pk) => {
  return async (dispatch) => {
    dispatch(setIntroductionDetailIsLoading(true));
    try {
      const response = await api.get("api/introductions/detail", {
        params: { pk: pk },
      });
      dispatch(setIntroductionDetail(response.data));
      dispatch(setIntroductionDetailIsLoading(false));
    } catch (e) {
      dispatch(setIntroductionDetailIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setIntroductionRead = (introductionId, userId, isRead) => {
  return async (dispatch) => {
    try {
      const response = await api.put(
        "api/introductions/set-read",
        { is_read: isRead, participant: userId },
        {
          params: { introduction_id: introductionId, participant_id: userId },
        }
      );
      dispatch(
        readIntroduction(response.data.participant, response.data.is_read)
      );
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Документ отмечен как прочитанный",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getIntroductionResultFile = (pk) => {
  return async (dispatch) => {
    dispatch(setIntroductionFileOperationInProcess(true));
    try {
      const response = await api.get(
        "api/introductions/get-result-file",
        {
          params: { pk: pk },
          responseType: "blob",
        }
      );
      dispatch(setIntroductionFileOperationInProcess(false));
      return response;
    } catch (e) {
      dispatch(setIntroductionFileOperationInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
