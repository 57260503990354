import api from "../../../../utils/api/api_beta";
import { setAlertToast } from "../../../actions/root/RootPageActions";
import { handleErrors } from "../../HandleErrors";
import {
  setComment,
  setCommentLoading,
  setCommentPostInProcess,
  setComments,
  setParticipantModalIsLoading,
  setTaskDetail,
  setTaskHasErrors,
  setTaskIsLoading,
  setTaskStatus,
  setParticipants,
  deleteComment as commentDelete,
  setParticipantWaitId,
  setParticipant,
  deleteParticipant as participantDelete,
  setTaskAcceptanceStatus,
  setTaskSolutionInProcess,
  setExecutor,
} from "../../../actions/tasks/detail/TaskDetailActions";

export const getTaskDetail = (pk) => {
  return async (dispatch) => {
    dispatch(setTaskHasErrors(false));
    dispatch(setTaskIsLoading(true));
    try {
      const response = await api.get("api/tasks/detail", {
        params: { pk: pk },
      });
      dispatch(setTaskDetail(response.data));
      dispatch(setTaskIsLoading(false));
    } catch (e) {
      dispatch(setTaskIsLoading(false));
      dispatch(setTaskHasErrors(true));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getTaskParticipants = () => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage;
    try {
      const response = await api.get("api/tasks/participants/exists/list", {
        params: { task_id: state.task.detail.id },
      });
      dispatch(setParticipants(response.data, "taskParticipants"));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getTaskCurrentStatus = () => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage.task;
    try {
      const response = await api.get("api/tasks/get-current-status", {
        params: { pk: state.detail.id },
      });
      dispatch(
        setTaskStatus(
          response.data.current_status,
          response.data.current_status_repr
        )
      );
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getAvailableParticipants = (name = null) => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage;
    dispatch(setParticipantModalIsLoading(true));
    try {
      const response = await api.get("api/tasks/participants/available/list", {
        params: {
          task: state.task.detail.id,
          target: "task",
          name: name,
        },
      });
      dispatch(setParticipants(response.data, "availableParticipants"));
      dispatch(setParticipantModalIsLoading(false));
    } catch (e) {
      dispatch(setParticipantModalIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const addParticipant = (id, role) => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage;
    dispatch(setParticipantWaitId(id, true, "addWait"));
    try {
      const response = await api.post("api/tasks/participants/create/", {
        task: state.task.detail.id,
        participant: id,
        participant_role: role,
      });
      dispatch(setParticipant(response.data));
      dispatch(setParticipantWaitId(id, false, "addWait"));
      dispatch(
        participantDelete(
          response.data.participant,
          "fromAvailableParticipants"
        )
      );
      if (role === "executor") {
        dispatch(setExecutor(true, response.data));
      }
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Участник добавлен",
          "success",
          null,
          true
        )
      );
    } catch (e) {
      dispatch(setParticipantWaitId(id, false, "addWait"));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const deleteParticipant = (id, role = null) => {
  return async (dispatch) => {
    dispatch(setParticipantWaitId(id, true, "deleteWait"));
    try {
      await api.delete("api/tasks/participants/delete/", {
        params: { pk: id },
      });
      dispatch(setParticipantWaitId(id, false, "deleteWait"));
      dispatch(participantDelete(id, "fromTaskParticipants"));
      if (role && role === "executor") {
        dispatch(setExecutor(false));
      }
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Участник удален",
          "success",
          null,
          true
        )
      );
    } catch (e) {
      dispatch(setParticipantWaitId(id, false, "deleteWait"));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const changeTaskStatus = (status) => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage.task.detail;
    try {
      const response = await api.put(
        "api/tasks/change-current-status/",
        {
          current_status: status,
        },
        {
          params: { pk: state.id },
        }
      );
      dispatch(
        setTaskStatus(
          response.data.current_status,
          response.data.current_status_repr
        )
      );
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const changeAcceptanceStatus = (status) => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage.task;
    try {
      const response = await api.put(
        "api/tasks/change-acceptance-status/",
        {
          acceptance_status: status,
        },
        {
          params: { pk: state.detail.id },
        }
      );
      dispatch(setTaskAcceptanceStatus(response.data.acceptance_status));
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Статус задачи изменен",
          "success",
          null,
          true
        )
      );
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getTaskComments = () => {
  return async (dispatch, getState) => {
    const state = getState().tasksDetailPage;
    try {
      const response = await api.get("api/tasks/comments", {
        params: { task_id: state.task.detail.id },
      });
      dispatch(setComments(response.data));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getTaskComment = (pk, edit = false) => {
  return async (dispatch) => {
    dispatch(setCommentLoading(true));
    try {
      const response = await api.get("api/tasks/comments/detail", {
        params: { pk: pk },
      });
      let files = [];
      response.data.files.map((f) =>
        files.push({
          id: f.id,
          name: f.name,
          file: null,
        })
      );
      dispatch(
        setComment({
          id: pk,
          text: response.data.text,
          edit: edit,
          files: files,
          filesToAdd: [],
          filesToDelete: [],
        })
      );
      dispatch(setCommentLoading(false));
    } catch (e) {
      dispatch(
        setComment({
          id: null,
          text: "",
          files: [],
          filesToAdd: [],
          filesToDelete: [],
          edit: false,
        })
      );
      dispatch(setCommentLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getTaskCommentFile = (pk) => {
  return async (dispatch) => {
    try {
      return await api.get("api/file", {
        params: {
          file_type: "taskcomment",
          pk: pk,
          field_attr: "file",
        },
        responseType: "blob",
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const sendComment = (taskId = null) => {
  return async (dispatch, getState) => {
    dispatch(setCommentPostInProcess(true));
    const state = getState().tasksDetailPage;
    const comment = state.currentComment;
    const data = new FormData();

    data.append("text", comment.text ? comment.text : "");
    comment.filesToAdd.map((f) => data.append("files_to_add", f.file));

    try {
      if (comment.edit) {
        comment.filesToDelete.map((f) => data.append("files_to_delete", f));
        await api.put("api/tasks/comments/edit/", data, {
          params: { pk: comment.id },
        });
      } else {
        data.append("task", taskId ? taskId : state.task.detail.id);
        await api.post("api/tasks/comments/create/", data);
      }
      dispatch(getTaskComments());
      dispatch(setCommentPostInProcess(false));
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Комментарий отправлен",
          "success",
          null,
          true
        )
      );
      dispatch(
        setComment({
          id: null,
          text: "",
          files: [],
          filesToAdd: [],
          filesToDelete: [],
          edit: false,
        })
      );
    } catch (e) {
      dispatch(
        setComment({
          id: null,
          text: "",
          files: [],
          filesToAdd: [],
          filesToDelete: [],
          edit: false,
        })
      );
      dispatch(setCommentPostInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setTaskSolution = (solution, comments) => {
  return async (dispatch, getState) => {
    const formData = new FormData();
    const state = getState().tasksDetailPage;
    formData.append("solution", solution);
    if (comments && comments.length > 0) {
      formData.append("comments", comments);
    }
    dispatch(setTaskSolutionInProcess(true))
    try {
      const response = await api.put("api/tasks/set-task-solution", formData, {
        params: { pk: state.task.detail.id },
      });
      dispatch(
        setTaskStatus(
          response.data.current_status,
          response.data.current_status_repr
        )
      );
      dispatch(
        setAlertToast(
          "Успешное выполнение",
          "Задача завершена",
          "success",
          "",
          true
        )
      );
      dispatch(setTaskSolutionInProcess(false))
      return response;
    } catch (e) {
      dispatch(setTaskSolutionInProcess(false))
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const deleteComment = (pk) => {
  return async (dispatch) => {
    try {
      const response = await api.put(
        "api/tasks/comments/delete/",
        {
          deleted: true,
        },
        {
          params: { pk: pk },
        }
      );
      dispatch(commentDelete(response.data.id, response.data.text));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
