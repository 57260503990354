import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { formatDate } from "../../utils/utils";

const ResultTaskCard = (props) => {
  return (
    <div className="col-sm-4 grid-margin stretch-card mt-1">
      <div className="card">
        <div className="card-header">
          <small>
            {props.number} {props.name}
          </small>
        </div>
        <div className="card-body py-2">
          <div>
            <small>
              Текущий статус: <b>{props.current_status_repr}</b>
            </small>
          </div>
        </div>
        <div className="card-footer">
          <NavLink
            to={`/tasks/${props.id}/detail`}
            className="btn btn-primary btn-sm text-white me-2 mt-1"
          >
            Перейти к задаче
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const ResultEventCard = (props) => {
  return (
    <div className="col-sm-4 grid-margin stretch-card mt-1">
      <div className="card">
        <div className="card-header">
          <small>{props.name}</small>
        </div>
        <div className="card-body py-2">
          <div>
            <small>
              Тип события: <b>{props.event_type_repr}</b>
            </small>
          </div>
          <div>
            <small>
              {formatDate(props.start)} - {formatDate(props.finish)}
            </small>
          </div>
          <div>
            <small>
              Завершено: <b>{props.completed ? "Да" : "Нет"}</b>
            </small>
          </div>
        </div>
        <div className="card-footer">
          <NavLink
            to={`/calendar?show_modal=true&id=${props.id}`}
            className="btn btn-primary btn-sm text-white me-2 mt-1"
          >
            Перейти к событию
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const ResultUserCard = (props) => {
  return (
    <div className="col-sm-4 grid-margin stretch-card mt-1">
      <div className="card">
        <div className="card-header">
          <small>{props.full_name}</small>
        </div>
        <div className="card-body py-2">
          <div>
            <small>{props.is_online ? "Онлайн" : "Офлайн"}</small>
          </div>
        </div>
        <div className="card-footer">
          <NavLink
            to={`/employee/${props.id}/profile`}
            className="btn btn-primary btn-sm text-white me-2 mt-1"
          >
            Перейти к профилю
          </NavLink>
        </div>
      </div>
    </div>
  );
};
const GlobalSearch = (props) => {
  const windowHeightRef = useRef(window.innerHeight);
  const overflowMaxHeight = `${Number(windowHeightRef?.current) * 0.7}px`;
  const tasks = props.results.tasks
    ? props.results.tasks.map((t) => <ResultTaskCard key={t.id} {...t} />)
    : [];
  const events = props.results.events
    ? props.results.events.map((e) => <ResultEventCard key={e.id} {...e} />)
    : [];
  const users = props.results.users
    ? props.results.users.map((u) => <ResultUserCard key={u.id} {...u} />)
    : [];
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>Глобальный поиск</b>
      </div>
      <div className="col-md-12 mb-3">
        <div className="card">
          <div className="card-body">
            <div
              className="row overflow-auto"
              style={{ maxHeight: overflowMaxHeight }}
            >
              <span>
                <b>Задачи</b>
              </span>
              <hr />
              {tasks.length > 0 ? (
                tasks
              ) : (
                <div className="px-2 mb-3">
                  <i className="mdi mdi-alert-outline me-2 text-primary" />
                  <span>По заданным критериям задач не найдено</span>
                </div>
              )}
              <span>
                <b>События</b>
              </span>
              <hr />
              {events.length > 0 ? (
                events
              ) : (
                <div className="px-2 mb-3">
                  <i className="mdi mdi-alert-outline me-2 text-primary" />
                  <span>По заданным критериям событий не найдено</span>
                </div>
              )}
              <span>
                <b>Пользователи</b>
              </span>
              <hr />
              {users.length > 0 ? (
                users
              ) : (
                <div className="px-2 mb-3">
                  <i className="mdi mdi-alert-outline me-2 text-primary" />
                  <span>По заданным критериям пользователей не найдено</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalSearch;
