import React from "react";

const EditedMark = () => {
  return (
    <div>
      <hr></hr>
      <div className="mdi mdi-alert me-4 mb-2">
        <small> Комментарий был изменен</small>
      </div>
    </div>
  );
};

export default EditedMark;
