import React, { useEffect, useRef } from "react";
import CkEditor from "../../../../shared/CKEditor";
import TaskCommentCard from "./TaskCommentCard";
import EditingCommentMark from "../../../../shared/EditingCommentMark";
import Spinner from "../../../../shared/Spinner";

const CommentFile = ({ id, name, handleFileDeleteClick }) => {
  return (
    <div className="mb-2">
      <small>{name}</small>
      <i
        className="btn btn-sm mdi mdi-close"
        onClick={() => handleFileDeleteClick(id)}
      />
    </div>
  );
};

const CommentFilesFooter = ({ files, filesToAdd, handleFileDeleteClick }) => {
  let renderFiles = [];
  files.map((f) => renderFiles.push(f));
  filesToAdd.map((f) => renderFiles.push(f));
  return (
    <div className="mt-2">
      {files.map((f) => (
        <CommentFile
          id={f.id}
          name={f.name}
          handleFileDeleteClick={() =>
            handleFileDeleteClick(f.id, "fromExistFiles")
          }
        />
      ))}
      {filesToAdd.map((f) => (
        <CommentFile
          id={f.id}
          name={f.name}
          handleFileDeleteClick={() =>
            handleFileDeleteClick(f.id, "fromAddedFiles")
          }
        />
      ))}
    </div>
  );
};

const TaskCommentTab = (props) => {
  const inputFileRef = useRef(null);
  const bottomRef = useRef(null);
  const windowHeightRef = useRef(window.innerHeight);
  const overflowMaxHeight = `${Number(windowHeightRef?.current) * 0.56}px`;
  const comments = props.comments.map((c, index) => (
    <TaskCommentCard
      key={index}
      isMobile={props.isMobile}
      userId={props.userId}
      initiatorId={props.initiatorId}
      currentStatus={props.currentStatus}
      handeCommentFileDownload={props.handeCommentFileDownload}
      setCommentAsSolution={props.setCommentAsSolution}
      handleCommentEditClick={props.handleCommentEditClick}
      handleCommentDeleteClick={props.handleCommentDeleteClick}
      {...c}
    />
  ));

  useEffect(() => {
    if (!props.currentComment.edit) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [props.comments.length]);

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Комментарии</h4>
        <div className="mt-3">
          <div className="row mb-4">
            <div
              className="overflow-auto row"
              style={{ maxHeight: overflowMaxHeight }}
            >
              <div className="container px-2">
                <div className="col-md-12 col-lg-12 col-xl-12">
                  {comments.length > 0 ? (
                    <ul className="list-unstyled">{comments}</ul>
                  ) : (
                    <div className="px-2 mb-3">
                      <i className="mdi mdi-alert-outline me-2 text-primary" />
                      <span>Комментарии отсутствуют</span>
                    </div>
                  )}
                </div>
              </div>
              <div ref={bottomRef}></div>
            </div>
          </div>
          {props.commentIsLoading ? (
            <Spinner />
          ) : !["finished", "aborted"].includes(props.currentStatus) ? (
            <>
              <div className="mt-2 mb-2">
                {props.currentComment.edit ? (
                  <EditingCommentMark
                    handleCommentEditCancelClick={
                      props.handleCommentEditCancelClick
                    }
                  />
                ) : null}
              </div>
              <CkEditor
                handleCommentTextChanged={props.setCommentText}
                text={props.currentComment.text}
              />
              <div className="mt-2">
                <button
                  type="button"
                  onClick={() => props.handleSendComment(null)}
                  disabled={props.postCommentInProcess}
                  className="btn btn-primary btn-sm text-white"
                >
                  {props.postCommentInProcess ? (
                    <div
                      className="spinner-border spinner-border-sm text-white"
                      role="status"
                    />
                  ) : (
                    "Отправить"
                  )}
                </button>
                <button
                  type="button"
                  className="btn mdi mdi-upload mx-3 btn-outline-primary"
                  disabled={props.postCommentInProcess}
                  onClick={() => inputFileRef.current.click()}
                />
                <input
                  type="file"
                  ref={inputFileRef}
                  onChange={props.handleFileAttached}
                  style={{ display: "none" }}
                />
              </div>
              <CommentFilesFooter
                files={props.currentComment.files}
                filesToAdd={props.currentComment.filesToAdd}
                handleFileDeleteClick={props.handleFileDeleteClick}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TaskCommentTab;
