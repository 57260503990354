import React from "react";
import { formatDate } from "../../../../../utils/utils";
import { NavLink } from "react-router-dom";

const ProjectInfoTab = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Информация о проекте</h4>
        <div className="mt-3">
          {props.project.initiator ? (
            props.project.initiator.id === props.userId ? (
              <NavLink
                to={`/projects/${props.project.id}/edit`}
                className="btn btn-primary btn-sm text-white mb-3"
              >
                Редактировать
              </NavLink>
            ) : null
          ) : null}

          <small>
            <div className="mt-2">
              Наименование проекта: <b>{props.project.name}</b>
            </div>
            <div className="mt-2">
              Описание проекта: {props.project.description}
            </div>
            <div className="mt-2">
              Начало проекта: <b>{formatDate(props.project.start)}</b>
            </div>
            <div className="mt-2">
              Инициатор: <b>{formatDate(props.project.finish)}</b>
            </div>
            <div className="mt-2">
              Инициатор:{" "}
              <b>
                {props.project.initiator
                  ? props.project.initiator.full_name
                  : "Не указано"}
              </b>
            </div>
          </small>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfoTab;
