import React from "react";

const VacationManage = (props) => {
  return (
    <div className="col-sm-12">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">
            {props.vacationId ? "Редактирование отпуска" : "Новый отпуск"}
          </h4>
          <div className="col-sm-7">
            <form onSubmit={props.handleSubmit(props.onSubmit)}>
              <small>
                <div className="mt-3">
                  <label>Начало отпуска</label>
                  <input
                    type="date"
                    className={`form-control form-control-sm ${
                      props.errors.start ? "is-invalid" : ""
                    }`}
                    {...props.register("start", {
                      required: true,
                      valueAsDate: true,
                    })}
                  />
                </div>
                <div className="mt-3">
                  <label>Завершение отпуска</label>
                  <input
                    type="date"
                    className={`form-control form-control-sm ${
                      props.errors.finish ? "is-invalid" : ""
                    }`}
                    {...props.register("finish", {
                      required: true,
                      valueAsDate: true,
                    })}
                  />
                </div>
              </small>
              <div className="mt-3">
                <button className="btn btn-primary btn-sm text-white">
                  {props.vacationSaveInProcess ? (
                    <div
                      className="spinner-border spinner-border-sm text-white"
                      role="status"
                    />
                  ) : (
                    "Сохранить изменения"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacationManage;
