import api from "../../../utils/api/api_beta";
import {
  setDepartments,
  setDepartmentsIsLoading,
  setDepartmentUsers,
  setDepartmentUsersIsLoading,
} from "../../actions/departments/DepartmentActions";
import { handleErrors } from "../HandleErrors";

export const getDepartments = () => {
  return async (dispatch) => {
    dispatch(setDepartmentsIsLoading(true));
    try {
      const response = await api.get("api/departments");
      dispatch(setDepartments(response.data));
      dispatch(setDepartmentsIsLoading(false));
    } catch (e) {
      dispatch(setDepartmentsIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getUsersInDepartment = (pk) => {
  return async (dispatch) => {
    dispatch(setDepartmentUsersIsLoading(true));
    try {
      const response = await api.get("api/departments/users", {
        params: { department_id: pk },
      });
      dispatch(setDepartmentUsers(response.data));
      dispatch(setDepartmentUsersIsLoading(false));
    } catch (e) {
      dispatch(setDepartmentUsersIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getVacationScheduleFile = (pk) => {
  return async (dispatch) => {
    try{
      return await api.get('api/departments/get-vacation-file', {
        params: {pk: pk},
        responseType: 'blob'
      })
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e))
      }
    }
  }
}
