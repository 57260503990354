import api from "../../../utils/api/api_beta";
import {
  setEmployee,
  setEmployeeIsLoading,
} from "../../actions/employees/EmployeesActions";
import { handleErrors } from "../HandleErrors";

export const getEmployee = (pk) => {
  return async (dispatch) => {
    dispatch(setEmployeeIsLoading(true));
    try {
      const response = await api.get("api/employees/detail", {
        params: { pk: pk },
      });
      dispatch(setEmployee(response.data));
      dispatch(setEmployeeIsLoading(false));
      return response
    } catch (e) {
      dispatch(setEmployeeIsLoading(false));
      dispatch(handleErrors(e));
    }
  };
};
