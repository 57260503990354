import React from "react";
import { Route, Routes } from "react-router-dom";
import NewsContainer from "../news/NewsContainer";
import TaskListContainer from "../task/list/TaskListContainer";
import TaskDetailContainer from "../task/detail/TaskDetailContainer";
import TaskManageContainer from "../task/manage/TaskManageContainer";
import ProfileContainer from "../profile/ProfileContainer";
import CalendarContainer from "../calendar/CalendarContainer";
import ProjectListContainer from "../project/list/ProjectListContainer";
import ProjectDetailContainer from "../project/detail/ProjectDetailContainer";
import ProjectManageContainer from "../project/manage/ProjectManageContainer";
import DepartmentContainer from "../department/DepartmentContainer";
import LoginContainer from "../login/LoginContainer";
import EmployeeContainer from "../employee/EmployeeContainer";
import NotificationContainer from "../notification/list/NotificationContainer";
import NotificationDetailContainer from "../notification/detail/NotificationDetailContainer";
import VacationContainer from "../vacation/list/VacationContainer";
import VacationManageContainer from "../vacation/manage/VacationManageContainer";
import GlobalSearchContainer from "../global-search/GlobalSearchContainer";
import IntroductionContainer from "../introduction/list/IntroductionContainer";
import IntroductionDetailContainer from "../introduction/detail/IntroductionDetailContainer";
import IntroductionManageContainer from "../introduction/manage/IntroductionManageContainer";
import AccessDenied from "../shared/AccessDenied";

const ProtectedRoute = ({ permList, pathPerm, children }) => {
  if (!permList || !permList.includes(pathPerm)) {
    return <AccessDenied />;
  }
  return children;
};

const AppRoutes = (props) => {
  return (
    <Routes>
      <Route path="/login" element={<LoginContainer />} />
      <Route path="/" element={<NewsContainer />} />
      <Route path="/global-search" element={<GlobalSearchContainer />} />
      <Route
        path="/tasks"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="view_task">
            <TaskListContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tasks/:id/detail"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="view_task">
            <TaskDetailContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tasks/:id/edit"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="change_task">
            <TaskManageContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tasks/create"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="add_task">
            <TaskManageContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="view_project">
            <ProjectListContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/projects/create"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="add_project">
            <ProjectManageContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/projects/:id/detail"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="view_project">
            <ProjectDetailContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/projects/:id/edit"
        element={
          <ProtectedRoute
            permList={props.permissions}
            pathPerm="change_project"
          >
            <ProjectManageContainer />
          </ProtectedRoute>
        }
      />

      <Route
        path="/calendar"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="view_event">
            <CalendarContainer />
          </ProtectedRoute>
        }
      />
      <Route path="/profile" element={<ProfileContainer />} />
      <Route path="/notifications" element={<NotificationContainer />} />
      <Route
        path="/notifications/:id/detail"
        element={<NotificationDetailContainer />}
      />
      <Route
        path="/department"
        element={
          <ProtectedRoute
            permList={props.permissions}
            pathPerm="view_structuralunit"
          >
            <DepartmentContainer />
          </ProtectedRoute>
        }
      />
      <Route
        path="/employee/:id/profile"
        element={
          <ProtectedRoute permList={props.permissions} pathPerm="view_profile">
            <EmployeeContainer />
          </ProtectedRoute>
        }
      />
      <Route path="/vacations" element={<VacationContainer />} />
      <Route path="/vacations/create" element={<VacationManageContainer />} />
      <Route path="/vacations/:id/edit" element={<VacationManageContainer />} />
      <Route path="/introductions" element={<IntroductionContainer />} />
      <Route
        path="/introductions/:id/detail"
        element={<IntroductionDetailContainer />}
      />
      <Route
        path="/introductions/create"
        element={<IntroductionManageContainer />}
      />
      <Route
        path="/introductions/:id/edit"
        element={<IntroductionManageContainer />}
      />
    </Routes>
  );
};

export default AppRoutes;
