import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const TaskManageForm = (props) => {
  const projects = props.projects.map((p) => ({ value: p.id, label: p.name }));
  const parents = props.parents.map((p) => ({ value: p.id, label: p.name }));
  const priorities = [
    { value: "1", label: "Критический" },
    { value: "2", label: "Высокий" },
    { value: "3", label: "Средний" },
    { value: "4", label: "Низкий" },
  ].map((p, index) => (
    <option key={index} value={p.value}>
      {p.label}
    </option>
  ));
  return (
    <form onSubmit={props.handleSubmit(props.onSubmit)}>
      <div className="col-sm-7">
        <small>
          <div className="mt-3">
            <label>Наименование задачи</label>
            <input
              type="text"
              className={`form-control form-control-sm ${
                props.errors.name ? "is-invalid" : ""
              }`}
              {...props.register("name", { required: true })}
            />
            </div>
          <div className="mt-3">
            <label>Описание задачи</label>
            <textarea
              rows="5"
              className={`form-control form-control-sm ${
                props.errors.description ? "is-invalid" : ""
              }`}
              {...props.register("description")}
            />
          </div>
          <div className="mt-3">
            <label>Начало задачи</label>
            <input
              type="datetime-local"
              className={`form-control form-control-sm ${
                props.errors.start ? "is-invalid" : ""
              }`}
              {...props.register("start", {
                required: true,
                valueAsDate: true,
              })}
            />
          </div>
          <div className="mt-3">
            <label>Завершить до</label>
            <input
              type="datetime-local"
              className={`form-control form-control-sm ${
                props.errors.finish ? "is-invalid" : ""
              }`}
              {...props.register("finish", {
                required: true,
                valueAsDate: true,
              })}
            />
          </div>
          <div className="mt-3">
            <label>Приоритет задачи</label>
            <select
              className={`form-control form-control-sm ${
                props.errors.finish ? "is-invalid" : ""
              }`}
              {...props.register("priority", {
                required: true,
              })}
            >
              {priorities}
            </select>
          </div>
          <div className="mt-3">
            <label>Проект</label>
            <Controller
              name="project"
              control={props.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    options={projects}
                    isClearable={true}
                    defaultValue={null}
                    placeholder="Выберите проект"
                    value={projects.find((p) => p.value === value) || null}
                    onChange={(e) => onChange(e ? e.value : null)}
                  />
                );
              }}
            />
          </div>

          <div className="mt-3">
            <label>Родительская задача</label>
            <Controller
              name="parent_task"
              control={props.control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    options={parents}
                    isClearable={true}
                    defaultValue=""
                    placeholder="Выберите родительскую задачу"
                    value={parents.find((p) => p.value === value)}
                    onChange={(e) => onChange(e ? e.value : null)}
                  />
                );
              }}
            />
          </div>
        </small>
      </div>
      <div className="mt-3">
        <button
          disabled={props.taskSaveInProcess}
          className="btn btn-primary btn-sm text-white"
        >
          {props.taskSaveInProcess ? (
            <div
              className="spinner-border spinner-border-sm text-white"
              role="status"
            />
          ) : !props.taskId ? (
            "Создать задачу"
          ) : (
            "Сохранить изменения"
          )}
        </button>
      </div>
    </form>
  );
};

export default TaskManageForm;
