import React from "react";
import { NavLink } from "react-router-dom";
import { formatDate } from "../../../utils/utils";

const ProjectCard = (props) => {
  const truncateChars = (text) => {
    if (!text) {
      return null;
    }
    return text.length > 60 ? text.substring(0, 60) : text;
  };
  let tasks = [];
  if (props.tasks) {
    tasks = props.tasks.map((t) => (
      <li key={t.id}>
        <NavLink to={`/tasks/${t.id}/detail`}>
          {t.name} - {t.current_status_repr} ({t[t.current_status]})
        </NavLink>
      </li>
    ));
  }

  return (
    <div className="col-lg-4 grid-margin stretch-card">
      <div className={`card border-${props.sla_color}`}>
        <div className="card-body">
          <h4 className="card-title">
            <NavLink to={`/projects/${props.id}/detail`}>{props.name}</NavLink>
          </h4>
          <p className="card-description">{truncateChars(props.description)}</p>
          <small>
            <p>
              Завершить до: <b>{formatDate(props.finish)}</b>
            </p>
            <p>
              Все задачи проекта завершены:{" "}
              <b>{props.all_tasks_completed ? "Да" : "Нет"}</b>
            </p>
            <ul>{tasks}</ul>
          </small>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
