import api from "../../../../utils/api/api_beta";
import {
  setProject,
  setProjectIsLoading,
} from "../../../actions/projects/detail/ProjectDetailActions";
import { handleErrors } from "../../HandleErrors";

export const getProject = (id) => {
  return async (dispatch) => {
    dispatch(setProjectIsLoading(true));
    try {
      const response = await api.get("api/projects/detail", {
        params: { pk: id },
      });
      dispatch(setProject(response.data));
      dispatch(setProjectIsLoading(false));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
