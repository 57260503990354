import React, { useState } from "react";
import TaskCard from "./TaskCard";
import { NavLink } from "react-router-dom";
import Paginator from "../../shared/Paginator";
import Widget from "../widget/Widget";
import WidgetManageModal from "../widget/WidgetManageModal";

const TaskList = (props) => {
  const [tab, setTab] = useState("active");
  const [showWidgets, setShowWidgets] = useState(props.widgets.length > 0);
  // const [showWidgetModal, setShowWidgetModal] = useState(false);
  const tabSwitcherOptions = [
    { value: "active", label: "Активные задачи" },
    { value: "finished", label: "Завершенные задачи" },
  ].map((t, index) => (
    <button
      className={`btn btn-sm${
        tab === t.value ? " btn-primary text-white" : ""
      }`}
      key={index}
      onClick={() => setTab(t.value)}
    >
      {t.label}
    </button>
  ));
  // const rolesOptions = [
  //   { value: "all", label: "Все задачи" },
  //   { value: "initiator", label: "Инициатор" },
  //   { value: "executor", label: "Исполнитель" },
  //   { value: "coexecutor", label: "Соисполнитель" },
  //   { value: "auditor", label: "Аудитор" },
  // ].map((role, index) => (
  //   <option key={index} value={role.value}>
  //     {role.label}
  //   </option>
  // ));
  const orderOptions = [
    { value: "all", label: "Без сортировки" },
    { value: "-created_at", label: "Сначала новые" },
    { value: "created_at", label: "Сначала старые" },
    { value: "status", label: "По статусу (по возрастанию)" },
    { value: "-status", label: "По статусу (по убыванию)" },
  ].map((order, index) => (
    <option key={index} value={order.value}>
      {order.label}
    </option>
  ));
  const pageSizeOptions = [5, 10, 15, 25, 30, 35].map((size, index) => (
    <option key={index} value={size}>
      {size}
    </option>
  ));
  const tasks = props.tasks
    .filter((t) =>
      tab === "active"
        ? !["finished", "aborted"].includes(t.current_status)
        : ["finished", "aborted"].includes(t.current_status)
    )
    .map((task) => <TaskCard userId={props.userId} {...task} key={task.id} />);
  const widgets = props.widgets.map((w) => (
    <Widget
      key={w.id}
      handleWidgetEditClick={props.handleWidgetEditClick}
      handleWidgetClick={props.handleWidgetClick}
      widgetId={props.filters.widget_id}
      {...w}
    />
  ));
  return (
    <>
      <WidgetManageModal
        show={props.showWidgetModal}
        onHide={() => {
          props.setShowWidgetModal(false);
          props.setWidgetData({});
        }}
        users={props.users}
        data={props.widgetData}
        saveWidget={props.saveWidget}
        widgetIsLoading={props.widgetIsLoading}
        widgetSaveInProcess={props.widgetSaveInProcess}
      />

      <div className="row page-title-header">
        <div className="page-header">
          <b>Задачи</b>
        </div>
        <div className="col-md-12">
          <div className="page-header-toolbar">
            <div className="toolbar-item me-2 p-0">
              <select
                onChange={props.onOrderClick}
                value={
                  props.filters.hasOwnProperty("order_by")
                    ? props.filters.order_by
                    : null
                }
                className="form-control form-control-sm"
              >
                {orderOptions}
              </select>
            </div>
            <div className="toolbar-item me-2 p-0">
              <select
                onChange={props.onPageSizeChange}
                value={props.filters.page_size}
                className="form-control form-control-sm"
              >
                {pageSizeOptions}
              </select>
            </div>
            <div className="toolbar-item me-2 p-0">
              <button
                className="btn btn-sm btn-danger text-white"
                onClick={props.handleFiltersReset}
              >
                Сбросить фильтры
              </button>
            </div>
            <div className="toolbar-item me-2 p-0">
              <NavLink
                to="/tasks/create"
                className="btn btn-primary btn-sm text-white"
              >
                Новая задача
              </NavLink>
            </div>
            <div className="toolbar-item me-2 p-0">
              <b>
                <span>{props.countRepr}</span>
              </b>
            </div>
            <div className="toolbar-item me-2 p-0">
              <Paginator
                pageNumber={props.pageNumber}
                pageSize={props.pageSize}
                totalCount={props.count}
                onClick={props.onPageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5 mb-3">
        <div className="d-flex justify-content-start">
          <button
            className="btn btn-sm btn-primary text-white me-2"
            onClick={() => props.setShowWidgetModal(true)}
          >
            Добавить виджет
          </button>
          <button
            className="btn btn-sm btn-primary text-white"
            onClick={() => setShowWidgets(!showWidgets)}
          >
            {showWidgets ? "Скрыть виджеты" : "Отобразить виджеты"}
          </button>
        </div>
      </div>
      {showWidgets ? (
        <div className="col-md-12">
          <div className="row">
            {widgets.length > 0 ? (
              widgets
            ) : (
              <span className="text-muted mb-3">
                <small>
                  Для добавления виджетов нажмите кнопку «Добавить виджет»
                </small>
              </span>
            )}
          </div>
        </div>
      ) : null}

      <div className="col-md-4 mb-3">
        <div class="btn-group" role="group">
          {tabSwitcherOptions}
        </div>
      </div>
      <div className="row overflow-auto">
        {tasks.length > 0 ? (
          tasks
        ) : (
          <div className="px-4 mb-3">
            <i className="mdi mdi-alert-outline me-2 text-primary" />
            <span>По заданным критериям задач не найдено</span>
          </div>
        )}
      </div>
    </>
  );
};

export default TaskList;
