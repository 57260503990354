import React from "react";
import Modal from "react-bootstrap/Modal";

const ProfileEditModal = (props) => {
  const onHide = () => {
    props.handleClose(false);
  };
  return (
    <Modal
      show={props.show}
      centered={true}
      size="md"
      scrollable={true}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Редактирование профиля</Modal.Title>
      </Modal.Header>
      <form onSubmit={props.handleSumbit(props.onSubmit)}>
        <Modal.Body>
          <div>
            <label>Фамилия</label>
            <input
              type="text"
              className="form-control form-control-sm"
              {...props.register("last_name", { required: true })}
            />
          </div>
          <div className="mt-3">
            <label>Имя</label>
            <input
              type="text"
              className="form-control form-control-sm"
              {...props.register("first_name", { required: true })}
            />
          </div>
          <div className="mt-3">
            <label>Отчество</label>
            <input
              type="text"
              className="form-control form-control-sm"
              {...props.register("middle_name")}
            />
          </div>
          <div className="mt-3">
            <label>Дата рождения</label>
            <input
              type="date"
              className="form-control form-control-sm"
              {...props.register("dob", { required: true, valueAsDate: true })}
            />
          </div>
          <div className="mt-3">
            <label>Номер телефона</label>
            <input
              type="text"
              className="form-control form-control-sm"
              {...props.register("phone")}
            />
          </div>
          <div className="mt-3">
            <input
              type="checkbox"
              className="form-check-input mx-1"
              {...props.register("profile_is_visible")}
            />
            <label>Общедоступный профиль </label>
          </div>
          <div className="mt-3">
            <input
              type="checkbox"
              className="form-check-input mx-1"
              {...props.register("phone_is_visible")}
            />
            <label>Номер телефона виден всем</label>
          </div>
          <div className="mt-3">
            <input
              type="checkbox"
              className="form-check-input mx-1"
              {...props.register("dob_is_visible")}
            />
            <label>Дата рождения видна всем</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-success btn-sm">
            Сохранить изменения
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ProfileEditModal;
