import { actions } from "../../../actions/tasks/detail/TaskDetailActions";

const initialState = {
  task: {},
  isLoading: false,
  modalIsLoading: false,
  postCommentInProcess: false,
  commentIsLoading: false,
  hasErrors: false,
  solutionSetInProcess: false,
  currentComment: {
    id: null,
    text: "",
    files: [],
    filesToAdd: [],
    filesToDelete: [],
    edit: false,
  },
  participantDeleteWaitList: [],
  participantAddWaitList: [],
  availableParticipants: [],
};

export const taskDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_TASK_DETAIL:
      return {
        ...state,
        task: action.data,
      };
    case actions.SET_TASK_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_TASK_HAS_ERRORS:
      return {
        ...state,
        hasErrors: action.hasErrors,
      };
    case actions.SET_TASK_CURRENT_STATUS:
      return {
        ...state,
        task: {
          ...state.task,
          detail: {
            ...state.task.detail,
            current_status: action.status,
            current_status_repr: action.repr,
          },
        },
      };
    case actions.SET_TASK_ACCEPTANCE_STATUS:
      return {
        ...state,
        task: {
          ...state.task,
          detail: {
            ...state.task.detail,
            acceptance_status: action.status,
          },
        },
      };
    case actions.SET_TASK_SOLUTION_IN_PROCESS:
      return {
        ...state,
        solutionSetInProcess: action.inProcess,
      };
    case actions.SET_PARTICIPANT_MODAL_LOADING:
      return {
        ...state,
        modalIsLoading: action.isLoading,
      };
    case actions.SET_PARTICIPANTS:
      switch (action.target) {
        case "taskParticipants":
          return {
            ...state,
            task: {
              ...state.task,
              participants: action.data,
            },
          };
        case "availableParticipants":
          return {
            ...state,
            availableParticipants: action.data,
          };
        default:
          return { ...state };
      }
    case actions.SET_PARTICIPANT:
      return {
        ...state,
        task: {
          ...state.task,
          participants: [...state.task.participants, action.data],
        },
      };
    case actions.SET_EXECUTOR:
      return {
        ...state,
        task: {
          ...state.task,
          detail: {
            ...state.task.detail,
            executor: action.add ? action.data : {},
          },
        },
      };
    case actions.SET_PARTICIPANT_WAIT_ID:
      switch (action.target) {
        case "addWait":
          return {
            ...state,
            participantAddWaitList: action.add
              ? [...state.participantAddWaitList, action.id]
              : state.participantAddWaitList.filter((id) => id !== action.id),
          };
        case "deleteWait":
          return {
            ...state,
            participantDeleteWaitList: action.add
              ? [...state.participantDeleteWaitList, action.id]
              : state.participantDeleteWaitList.filter(
                  (id) => id !== action.id
                ),
          };
        default:
          return { ...state };
      }
    case actions.DELETE_TASK_PARTICIPANT:
      switch (action.target) {
        case "fromTaskParticipants":
          return {
            ...state,
            task: {
              ...state.task,
              participants: state.task.participants.filter(
                (p) => p.id !== action.id
              ),
            },
          };
        case "fromAvailableParticipants":
          return {
            ...state,
            availableParticipants: state.availableParticipants.filter(
              (p) => p.id !== action.id
            ),
          };
        default:
          return { ...state };
      }
    case actions.SET_COMMENT_LOADING:
      return {
        ...state,
        commentIsLoading: action.isLoading,
      };
    case actions.SET_POST_COMMENT_IN_PROCESS:
      return {
        ...state,
        postCommentInProcess: action.inProcess,
      };
    case actions.SET_COMMENTS:
      return {
        ...state,
        task: {
          ...state.task,
          comments: action.data,
        },
      };
    case actions.SET_COMMENT:
      return {
        ...state,
        currentComment: action.data,
      };
    case actions.DELETE_COMMENT:
      return {
        ...state,
        task: {
          ...state.task,
          comments: state.task.comments.map((c) =>
            c.id === action.id
              ? {
                  ...c,
                  text: action.text,
                  deleted: true,
                  files: [],
                }
              : c
          ),
        },
      };
    case actions.SET_COMMENT_TEXT:
      return {
        ...state,
        currentComment: {
          ...state.currentComment,
          text: action.text,
        },
      };
    case actions.SET_COMMENT_AS_SOLUTION:
      return {
        ...state,
        task: {
          ...state.task,
          comments: state.task.comments.map((c) =>
            c.id === action.id ? { ...c, solution: action.isSolution } : c
          ),
        },
      };
    case actions.ADD_COMMENT_FILE:
      return {
        ...state,
        currentComment: {
          ...state.currentComment,
          filesToAdd: [
            ...state.currentComment.filesToAdd,
            {
              id: state.currentComment.filesToAdd.length + 1,
              name: action.file.name,
              url: null,
              file: action.file,
              activity: "addFile",
            },
          ],
        },
      };
    case actions.DELETE_COMMENT_FILE:
      switch (action.target) {
        case "fromExistFiles":
          return {
            ...state,
            currentComment: {
              ...state.currentComment,
              files: state.currentComment.files.filter(
                (f) => f.id !== action.id
              ),
              filesToDelete: [...state.currentComment.filesToDelete, action.id],
            },
          };
        case "fromAddedFiles":
          return {
            ...state,
            currentComment: {
              ...state.currentComment,
              filesToAdd: state.currentComment.filesToAdd.filter(
                (f) => f.id !== action.id
              ),
            },
          };
        default:
          return { ...state };
      }
    default:
      return state;
  }
};
