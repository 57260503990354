import { actions } from "../../../actions/projects/detail/ProjectDetailActions";

const initialState = {
  project: {},
  isLoading: false,
  projectSaveInProcess: false,
  currentTab: "info",
};

export const projectDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_PROJECT:
      return {
        ...state,
        project: action.data,
      };
    case actions.SET_PROJECT_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_PROJECT_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.tabName,
      };
    case actions.SET_PROJECT_EDIT_MODAL_SHOW:
      return {
        ...state,
        editModalShow: action.show,
      };
    default:
      return state;
  }
};
