import React from "react";
import CkEditor from "../../shared/CKEditor";
import ReactHtmlParser from "react-html-parser";
import { NavLink } from "react-router-dom";

const NotificationDetail = (props) => {
  const getFooter = () => {
    switch (props.notification.type) {
      case "task_new_comment_alert":
        return props.notification.obj_id ? (
          <div className="mt-2">
            <NavLink
              to={`/tasks/${props.notification.obj_id}/detail`}
              className="btn btn-primary btn-sm text-white mb-3"
            >
              Перейти к задаче
            </NavLink>
            <CkEditor
              handleCommentTextChanged={props.setCommentText}
              text={props.currentComment.text}
            />
            <div className="mt-2">
              <button
                type="button"
                disabled={props.sendCommentInProcess}
                onClick={() => props.sendComment(props.notification.obj_id)}
                className="btn btn-primary btn-sm text-white"
              >
                {props.sendCommentInProcess ? (
                  <div className="spinner-border text-primary" role="status" />
                ) : (
                  "Отправить"
                )}
              </button>
            </div>
          </div>
        ) : null;
      default:
        return null;
    }
  };
  return (
    <div className="row page-title-header">
      <div className="page-header">
        <b>{props.notification.title}</b>
      </div>
      <div className="card">
        <div className="card-body">
          <small>
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">Текст уведомления</div>
              </div>
              <div className="col-sm-8">
                <div className="card-text text-muted">
                  {props.notification.text
                    ? ReactHtmlParser(props.notification.text)
                    : "Не указано"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">
                  Содержание уведомления
                </div>
              </div>
              <div className="col-sm-8">
                <div className="card-text text-muted">
                  {props.notification.content
                    ? ReactHtmlParser(props.notification.content)
                    : "Не указано"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <div className="card-text text-muted">Дата уведомления</div>
              </div>
              <div className="col-sm-8">
                <div className="card-text text-muted">
                  {props.notification.created_at
                    ? props.notification.created_at
                    : "Не указано"}
                </div>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-4">
                <button
                  type="button"
                  className="btn btn-outline-warning"
                  disabled={props.awaitSetRead.some(
                    (id) => id === props.notification.id
                  )}
                  onClick={() =>
                    props.setNotifyRead(
                      props.notification.id,
                      !props.notification.is_read
                    )
                  }
                >
                  {props.awaitSetRead.some(
                    (id) => id === props.notification.id
                  ) ? (
                    <div
                      className="spinner-border spinner-border-sm text-black"
                      role="status"
                    />
                  ) : props.notification.is_read ? (
                    "Отметить как непрочитанный"
                  ) : (
                    "Отметить как прочитанный"
                  )}
                </button>
              </div>
            </div>
          </small>
        </div>
        <div className="card-footer">{getFooter()}</div>
      </div>
    </div>
  );
};

export default NotificationDetail;
