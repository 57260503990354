import React, { useEffect } from "react";
import { connect } from "react-redux";
import Introduction from "./Introduction";
import Spinner from "../../shared/Spinner";
import { getIntroductions } from "../../../redux/thunk/introductions/list/IntroductionListThunk";
import { setIntroductionsFilters } from "../../../redux/actions/introductions/list/IntroductionsActions";

const IntroductionContainer = (props) => {
  const onPageChange = (number) => {
    props.setIntroductionsFilters({
      ...props.filters,
      page_number: number,
    });
    props.getIntroductions();
  };
  const onPageSizeChange = (e) => {
    props.setIntroductionsFilters({
      ...props.filters,
      page_size: Number(e.target.value),
    });
    props.getIntroductions();
  };
  useEffect(() => {
    props.getIntroductions();
  }, []);
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <Introduction
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      {...props}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    introductions: state.introductionsPage.introductions,
    isLoading: state.introductionsPage.isLoading,
    pageNumber: state.introductionsPage.pageNumber,
    pageSize: state.introductionsPage.pageSize,
    count: state.introductionsPage.count,
  };
};
export default connect(mapStateToProps, {
  getIntroductions,
  setIntroductionsFilters,
})(IntroductionContainer);
