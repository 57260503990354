export const actions = {
  SET_INTRODUCTION_DETAIL: "SET_INTRODUCTION_DETAIL",
  SET_INTRODUCTION_DETAIL_IS_LOADING: "SET_INTRODUCTION_DETAIL_IS_LOADING",
  SET_INTRODUCTION_READ: "SET_INTRODUCTION_READ",
  SET_INTRODUCTION_FILE_OPERATION_IN_PROCESS: "SET_INTRODUCTION_FILE_OPERATION_IN_PROCESS",
};
export const setIntroductionDetail = (data) => {
  return {
    type: actions.SET_INTRODUCTION_DETAIL,
    data: data,
  };
};
export const setIntroductionDetailIsLoading = (isLoading) => {
  return {
    type: actions.SET_INTRODUCTION_DETAIL_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setIntroductionRead = (id, isRead) => {
  return {
    type: actions.SET_INTRODUCTION_READ,
    id: id,
    isRead: isRead,
  };
};
export const setIntroductionFileOperationInProcess = (inProcess) => {
  return {
    type: actions.SET_INTRODUCTION_FILE_OPERATION_IN_PROCESS,
    inProcess: inProcess
  }
}
