import React, { useEffect } from "react";
import ProjectList from "./ProjectList";
import Spinner from "../../shared/Spinner";
import { connect } from "react-redux";
import { getProjects } from "../../../redux/thunk/projects/list/ProjectListThink";
import { setProjectsFilters } from "../../../redux/actions/projects/list/ProjectListActions";

const ProjectListContainer = (props) => {
  useEffect(() => {
    props.getProjects({}, {});
  }, []);

  const handlePageSizeChange = (e) => {
    props.setProjectsFilters({
      ...props.filters,
      page_size: Number(e.target.value),
    });
    props.getProjects();
  };
  const handlePageNumberChange = (pageNumber) => {
    props.setProjectsFilters({
      ...props.filters,
      page_number: pageNumber,
    });
    props.getProjects();
  };
  const handleOrderingChange = (expr) => {
    props.setProjectsFilters({
      ...props.filters,
      order_by: expr !== "all" ? expr : null,
    });
    props.getProjects();
  };
  const handleSearchChange = (e) => {
    if (e.key === "Enter") {
      props.setProjectsFilters({
        ...props.filters,
        search_field: e.target.value,
      });
      props.getProjects();
    }
  };

  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <ProjectList
      handlePageSizeChange={handlePageSizeChange}
      handlePageNumberChange={handlePageNumberChange}
      handleOrderingChange={handleOrderingChange}
      handleSearchChange={handleSearchChange}
      {...props}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.projectsPage.projects,
    isLoading: state.projectsPage.isLoading,
    filters: state.projectsPage.filters,
    pageSize: state.projectsPage.pageSize,
    pageNumber: state.projectsPage.pageNumber,
  };
};

export default connect(mapStateToProps, {
  getProjects,
  setProjectsFilters,
})(ProjectListContainer);
