import React from "react";

const News = (props) => {
  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">Новости</h3>
      </div>
      <div className="row">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Новая версия CRM</h4>
            <div>
              <small>Новая версия системы</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
