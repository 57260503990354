import api from "../../../../utils/api/api_beta";
import { setAlertToast } from "../../../actions/root/RootPageActions";
import {
  setProjects,
  setProjectsIsLoading,
} from "../../../actions/projects/list/ProjectListActions";

export const getProjects = (filters, orderBy) => {
  return async (dispatch, getState) => {
    dispatch(setProjectsIsLoading(true));
    try {
      const state = getState().projectsPage;
      const response = await api.get("api/projects", {
        params: state.filters,
      });
      dispatch(setProjects(response.data));
      dispatch(setProjectsIsLoading(false));
    } catch (e) {
      dispatch(setProjectsIsLoading(false));
      e?.response?.data?.errors.map((err) =>
        dispatch(setAlertToast(err.title, err.message, "danger", null, true))
      );
    }
  };
};
