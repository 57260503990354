import { setAlertToast } from "../actions/root/RootPageActions";

export const handleErrors = (e) => {
  return (dispatch) => {
    if (e.response.data.errors) {
      e.response.data.errors.map((err) =>
        dispatch(setAlertToast(err.title, err.message, "danger", null, true))
      );
    } else {
      dispatch(
        setAlertToast(
          "Ошибка выполнения",
          "Неизвестная ошибка",
          "danger",
          null,
          true
        )
      );
    }
  };
};
