import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";

const UserCard = ({ userId, user, isRead }) => {
  return (
    <div className="d-flex mt-3 py-2 border-bottom card-body">
      <img
        className="img-sm rounded-circle me-3"
        src={user.photo} //{`data:image/jpeg;base64,${user.photo}`}
        alt="profile"
      />
      <div className="wrapper me-2">
        <div className="font-weight-light">
          <NavLink
            to={
              userId === user.id ? "/profile" : `/employee/${user.id}/profile`
            }
          >
            {user.full_name}
          </NavLink>
        </div>
        <small className="text-muted">
          {user.connected ? "Сейчас на сайте" : "Офлайн"},{" "}
          {isRead ? (
            <>
              <span className="me-2">ознакомлен с документом</span>
              <i className="mdi mdi-check-all me-2 text-primary" />
            </>
          ) : (
            <>
              <span className="me-2">не ознакомлен с документом</span>
              <i className="mdi mdi-alert-outline me-2 text-primary" />
            </>
          )}
        </small>
      </div>
    </div>
  );
};
const IntroductionFileModal = (props) => {
  const [numPages, setNumPages] = useState(null);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);
  return (
    <Modal
      show={props.show}
      centered={true}
      size="lg"
      scrollable={true}
      onHide={props.handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Просмотр файла</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Document
          file={`data:application/pdf;base64,${props.file}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              renderTextLayer={false}
            />
          ))}
        </Document>
      </Modal.Body>
    </Modal>
  );
};
const IntroductionDetail = (props) => {
  const [showModal, setShowModal] = useState(false);
  const participants = props?.detail?.participants?.map((p) => (
    <UserCard key={p.id} user={p.participant} isRead={p.is_read} />
  ));
  return (
    <>
      <IntroductionFileModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        file={props.detail.file}
      />
      <div className="row page-title-header">
        <div className="page-header">
          <b>Документ</b>
        </div>
        <div className="col-md-12">
          <div className="page-header-toolbar">
            {props?.detail?.participants?.some(
              (p) => p.participant.id === props.userId && !p.is_read
            ) ? (
              <div className="toolbar-item me-2 p-0">
                <button
                  className="btn btn-sm btn-primary text-white"
                  onClick={() =>
                    props.setIntroductionRead(
                      props.detail.id,
                      props.userId,
                      true
                    )
                  }
                >
                  Документ прочитан
                </button>
              </div>
            ) : null}

            {props.detail.owner === props.userId ? (
              <>
                <div className="toolbar-item me-2 p-0">
                  <NavLink
                    to={`/introductions/${props.detail.id}/edit`}
                    className="btn btn-sm btn-primary text-white"
                  >
                    Редактировать
                  </NavLink>
                </div>
                <div className="toolbar-item me-2 p-0">
                  <button
                    className="btn btn-sm btn-primary text-white"
                    onClick={props.handleFileDownload}
                    disabled={props.fileOperationInProcess}
                  >
                    {props.fileOperationInProcess ? (
                      <div
                        className="spinner-border spinner-border-sm text-white"
                        role="status"
                      />
                    ) : (
                      "Список ознакомленных сотрудников"
                    )}
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <div className="card mt-2">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Заголовок</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">{props.detail.title}</div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Описание</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">{props.detail.description}</div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-4">
                  <div className="card-text text-muted">
                    <small>Документ</small>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="card-text">
                    <button
                      className=" btn btn-sm btn-primary text-white"
                      onClick={() => setShowModal(true)}
                    >
                      Просмотр документа
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <div className="card mt-2">
            <div className="card-body">
              <h6 className="mb-3">Участники опроса</h6>
              <div className="overflow-auto row">{participants}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default IntroductionDetail;
