export const actions = {
  SET_PROJECTS: "SET_PROJECTS",
  SET_PROJECTS_IS_LOADING: "SET_PROJECTS_IS_LOADING",
  SET_PROJECTS_FILTERS: "SET_PROJECTS_FILTERS",
};

export const setProjects = (data) => {
  return {
    type: actions.SET_PROJECTS,
    data: data,
  };
};
export const setProjectsIsLoading = (isLoading) => ({
  type: actions.SET_PROJECTS_IS_LOADING,
  isLoading: isLoading,
});
export const setProjectsFilters = (data) => ({
  type: actions.SET_PROJECTS_FILTERS,
  data: data,
});
