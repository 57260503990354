import { actions } from "../../../actions/introductions/detail/IntroductionDetailActions";
const initialState = {
  detail: {},
  isLoading: false,
  fileOperationInProcess: false
};
export const introductionDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_INTRODUCTION_DETAIL:
      return {
        ...state,
        detail: action.data,
      };
    case actions.SET_INTRODUCTION_DETAIL_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_INTRODUCTION_READ:
      return {
        ...state,
        detail: {
          ...state.detail,
          participants: state.detail.participants.map((p) =>
            p.participant.id === action.id
              ? { ...p, is_read: action.isRead }
              : p
          ),
        },
      };
      case actions.SET_INTRODUCTION_FILE_OPERATION_IN_PROCESS:
        return {
          ...state,
          fileOperationInProcess: action.inProcess
        }
    default:
      return state;
  }
};
