export const actions = {
  SET_VACATIONS: "SET_VACATIONS",
  SET_VACATION: "SET_VACATION",
  SET_VACATIONS_HAS_ERRORS: "SET_VACATIONS_HAS_ERRORS",
  SET_VACATIONS_IS_LOADING: "SET_VACATIONS_IS_LOADING",
  SET_VACATION_SAVE_IN_PROCESS: "SET_VACATION_SAVE_IN_PROCESS",
};

export const setVacations = (data, days) => ({
  type: actions.SET_VACATIONS,
  data: data,
  days: days,
});
export const setVacation = (data) => ({
  type: actions.SET_VACATION,
  data: data,
});
export const setVacationsIsLoading = (isLoading) => ({
  type: actions.SET_VACATIONS_IS_LOADING,
  isLoading: isLoading,
});
export const setVacationsHasErrors = (hasErrors) => ({
  type: actions.SET_VACATIONS_HAS_ERRORS,
  hasErrors: hasErrors,
});
export const setVacationSaveInProcess = (inProcess) => ({
  type: actions.SET_VACATION_SAVE_IN_PROCESS,
  inProcess: inProcess,
});
