import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const TreeNode = ({ node }) => {
  const { related, name, id, current_status_repr } = node;
  const [showChildren, setShowChildren] = useState(false);
  const handleNodeClick = () => {
    setShowChildren(!showChildren);
  };
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <i
          onClick={handleNodeClick}
          className={`me-2 text-primary mdi ${
            showChildren ? "mdi-minus" : "mdi-plus"
          }`}
        />
        <i className="text-primary mdi mdi-account-multiple me-2" />
        <NavLink to={`/tasks/${id}/detail`}>
          {name}, {current_status_repr.toLowerCase()}
        </NavLink>
      </div>
      <ul style={{ paddingLeft: "10px", borderLeft: "1px solid black" }}>
        {showChildren && <Tree treeData={related} />}
      </ul>
    </>
  );
};
const Tree = ({ treeData }) => {
  if (!treeData) {
    return;
  }
  return (
    <ul>
      {treeData.map((node) => (
        <TreeNode node={node} key={node.id} />
      ))}
    </ul>
  );
};

const TaskRelatedTab = (props) => {
  const navigate = useNavigate();
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Связанные задачи</h4>
        <div className="mt-3">
          {props.executorId === props.userId ||
          props.initiatorId === props.userId ? (
            !["aborted", "finished"].includes(props.currentStatus) ? (
              <button
                type="button"
                className="btn btn-primary btn-sm text-white mb-3"
                onClick={() => {
                  navigate(`/tasks/create?parent_id=${props.taskId}`);
                }}
              >
                <i className="mdi mdi-plus me-2" />
                Добавить
              </button>
            ) : null
          ) : null}
          {props.related.length > 0 ? (
            <Tree treeData={props.related} />
          ) : (
            <div className="px-2 mb-3">
              <i className="mdi mdi-alert-outline me-2 text-primary" />
              <span>Связанные задачи отсутствуют</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaskRelatedTab;
