import React, { useEffect } from "react";
import Vacation from "./Vacation";
import Spinner from "../../shared/Spinner";
import Error from "../../shared/Error";
import { connect } from "react-redux";
import { getVacations } from "../../../redux/thunk/VacationThunk";

const VacationContainer = (props) => {
  useEffect(() => {
    props.getVacations();
  }, []);
  if (props.isLoading) {
    return <Spinner />;
  }
  if (props.hasErrors) {
    return <Error />;
  }
  return (
    <Vacation
      vacations={props.vacations}
      vacationDaysRemain={props.vacationDaysRemain}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    vacations: state.vacationPage.vacations,
    vacationDaysRemain: state.vacationPage.vacationDaysRemain,
    vacation: state.vacationPage.vacation,
    isLoading: state.vacationPage.isLoading,
    hasErrors: state.vacationPage.hasErrors,
  };
};

export default connect(mapStateToProps, {
  getVacations,
})(VacationContainer);
