import React, { useEffect } from "react";
import IntroductionManage from "./IntroductionManage";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getIntroductionInitial,
  getParticipants,
  saveIntroduction,
} from "../../../redux/thunk/introductions/manage/IntroductionManageThunk";
import {setIntroductionInitial} from "../../../redux/actions/introductions/manage/IntroductionManageActions"
import { useForm } from "react-hook-form";
import Spinner from "../../shared/Spinner";

const IntroductionManageContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      id: null,
      title: "",
      description: "",
      file: null,
      participants: null,
      participants_id: null,
    },
  });
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (!params.id) {
      formData.append("file", data.file[0]);
    }
    data.participants.map((p) => formData.append("participants_id", p.value));
    props
      .saveIntroduction(formData, data.id)
      .then((res) => {
        navigate(`/introductions/${res.data.id}/detail`)
      });
  };

  useEffect(() => {
    props.getParticipants();
  }, []);

  useEffect(() => {
    if (params.id) {
      props.getIntroductionInitial(params.id);
    } else{
      props.setIntroductionInitial({})
    }
  }, [params.id]);

  useEffect(() => {
    if (props.initial && Object.keys(props.initial).length > 0 && params.id) {
      reset(props.initial);
    } else {
      reset({
        id: null,
        title: "",
        description: "",
        file: null,
        participants: null,
        participants_id: null,
      });
    }
  }, [props.initial]);

  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <IntroductionManage
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      control={control}
      onSubmit={onSubmit}
      participants={props.participants}
      introductionId={props.initial.id}
      filename={props.initial.filename}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    initial: state.introductionsManagePage.initial,
    participants: state.introductionsManagePage.participants,
    isLoading: state.introductionsManagePage.initialIsLoading,
    saveInProcess: state.introductionsManagePage.introductionSaveInProcess,
  };
};
export default connect(mapStateToProps, {
  getIntroductionInitial,
  getParticipants,
  saveIntroduction,
  setIntroductionInitial,
})(IntroductionManageContainer);
